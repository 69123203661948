import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import forcePreserveAspectRatio from '../utils/forcePreserveAspectRatio';
import { MARGIN_BIG, VIEWPORT } from '../settings/Global';
import _LazyResponsiveImage from './LazyResponsiveImage';
import replaceSvgColors from '../utils/replaceSvgColors';
import replaceSvgTitle from '../utils/replaceSvgTitle';
import { useCurrentColorScheme } from './ColorSchemeContext';
import { TEST_ID } from '../settings/E2e';

const BitmapContainer = styled.div<{
    showAsHero: boolean;
}>`
    height: 100%;
    max-height: 100%;

    @media screen and (min-width: ${VIEWPORT.TABLET_LARGE}px) and (max-height: 840px) {
        max-height: ${({ showAsHero }): string =>
            showAsHero ? '70%' : '100%'};
    }

    white-space: nowrap;
    :after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: bottom;
        width: 0;
    }
`;

const LazyResponsiveImage = styled(_LazyResponsiveImage)`
    display: inline-block;
    vertical-align: bottom;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: left bottom;
`;

const Container = styled.div<{
    $showAsHero: boolean;
}>`
    display: grid;
    grid-template-columns: 9fr 3fr;
    margin-top: ${({ $showAsHero }): string => ($showAsHero ? '0' : '30vh')};
    height: ${({ $showAsHero }): string => ($showAsHero ? '100vh' : '100%')};

    ${({ $showAsHero }): FlattenSimpleInterpolation | null =>
        $showAsHero
            ? css`
                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      height: 100svh;
                  }
              `
            : null};

    @media print {
        margin-top: 0;
        height: 100vh;
    }

    align-items: flex-end;
    padding-bottom: ${MARGIN_BIG}px;
    margin-bottom: var(--spacing9);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: 100%;
        margin-bottom: var(--spacing8);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-bottom: var(--spacing7);
    }

    svg {
        fill: var(--foregroundColor);
        width: 100%;
        height: auto;
        overflow: visible;
    }

    @media screen and (min-width: ${VIEWPORT.TABLET_LARGE}px) and (max-height: 840px) {
        svg {
            max-height: ${({ $showAsHero }): string =>
                $showAsHero ? '70%' : '100%'};
        }
    }
`;

interface Props {
    svg?: string | null;
    bitmap?: string | null;
    title: string;
    showAsHero?: boolean;
}

function FontHero({
    svg,
    bitmap,
    title,
    showAsHero = true,
}: Props): React.ReactElement | null {
    const colorScheme = useCurrentColorScheme();

    const cleanTitle = React.useCallback(
        (el: HTMLElement | null): void => {
            replaceSvgTitle(el, title);
        },
        [title],
    );

    if (!bitmap && !svg) {
        return null;
    }

    return (
        <Container
            data-cy={TEST_ID.FONT_FAMILY_HERO}
            $showAsHero={showAsHero}
            ref={svg ? cleanTitle : undefined}
            dangerouslySetInnerHTML={
                svg
                    ? {
                          __html: replaceSvgColors(
                              forcePreserveAspectRatio(svg),
                              colorScheme,
                          ),
                      }
                    : undefined
            }
        >
            {bitmap ? (
                <BitmapContainer showAsHero={showAsHero}>
                    <LazyResponsiveImage
                        sources={[{ src: bitmap }]}
                        src={bitmap}
                        preload
                    />
                </BitmapContainer>
            ) : null}
        </Container>
    );
}

export default React.memo(FontHero);
