import React from 'react';
import { v4 as uuid } from 'uuid';
import useOnClickAway from '../hooks/useOnClickAway';
import useOnEscapeKeyPress from '../hooks/useOnEscapeKeyPress';

interface ButtonAttributes {
    role: 'button';
    ['aria-controls']: string;
    ['aria-expanded']: boolean;
    onClick: () => void;
    ref(element: HTMLElement | null): void;
}

interface PanelAttributes {
    id: string;
    hidden: boolean;
    ref(element: HTMLElement | null): void;
    className?: string | undefined;
}

export interface DisclosureParams {
    expanded?: boolean;
    buttonAttributes: ButtonAttributes;
    panelAttributes: PanelAttributes;
    toggleExpanded: () => void;
}

interface DisclosureOptions {
    preExpanded?: boolean;
    enableClickAwayToCollapse?: boolean;
    enableEscapeKeyPressToCollapse?: boolean;
    onExpand?: () => void;
}

interface DisclosureProps extends DisclosureOptions {
    children(params: DisclosureParams): React.ReactElement;
}

export const useDisclosure = ({
    preExpanded,
    enableClickAwayToCollapse,
    enableEscapeKeyPressToCollapse,
    onExpand,
}: DisclosureOptions): DisclosureParams => {
    const [expanded, setExpanded] = React.useState(
        Boolean(preExpanded) as boolean,
    );

    const id = React.useMemo((): string => uuid(), []);

    const panelRef: React.MutableRefObject<HTMLElement | undefined> =
        React.useRef<HTMLElement>();
    const buttonRef: React.MutableRefObject<HTMLElement | undefined> =
        React.useRef<HTMLElement>();

    const setButtonRef = React.useCallback(
        (element: HTMLElement | null): void => {
            if (element) {
                buttonRef.current = element;
            }
        },
        [buttonRef],
    );

    const setPanelRef = React.useCallback(
        (element: HTMLElement | null): void => {
            if (element) {
                panelRef.current = element;
            }
        },
        [panelRef],
    );

    const toggleExpanded = React.useCallback((): void => {
        setExpanded((state: boolean): boolean => {
            if (onExpand && !state) {
                onExpand();
            }
            return !state;
        });
    }, []);

    const setToCollapsed = React.useCallback(
        (): void => setExpanded((): boolean => false),
        [],
    );

    useOnClickAway(
        [panelRef, buttonRef],
        enableClickAwayToCollapse ? setToCollapsed : undefined,
    );

    useOnEscapeKeyPress(
        enableEscapeKeyPressToCollapse ? setToCollapsed : undefined,
    );

    const buttonAttributes: ButtonAttributes = {
        onClick: toggleExpanded,
        ['aria-controls']: id,
        ['aria-expanded']: expanded,
        role: 'button',
        ref: setButtonRef,
    };

    const panelAttributes: PanelAttributes = {
        id,
        hidden: !expanded,
        ref: setPanelRef,
        // we use a CSS approach, see Global.tsx, because of x-browser issues with setting the state and the preview modal
        className: 'open-on-print',
    };

    return { expanded, panelAttributes, buttonAttributes, toggleExpanded };
};

const Disclosure = ({
    children,
    ...options
}: DisclosureProps): React.ReactElement => {
    const params = useDisclosure(options);

    return children(params);
};

export default Disclosure;
