import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../components/Paragraph';
import InlineUnderlinedLink from '../components/InlineUnderlinedLink';
import { HeadingExtraLarge } from '../components/Heading';
import { VIEWPORT } from '../settings/Global';
import LicenceSection, { LicenceContent } from '../components/LicenceSection';
import LicenceSectionLinks from '../components/LicenceSectionLinks';
import { createPrivacyPolicyUrl } from '../utils/urlHelper';
import { useEula, useStaticFontMetricOffsets } from '../components/PageContext';
import formatDjangoDateString from '../utils/formatDjangoDateString';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing6);
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-row-gap: var(--spacing3);
`;

const Inner = styled.div`
    grid-column: 3 / span 5;

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const PrivacyPolicyWrapper = styled.div`
    grid-column: 10 / span 3;

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const LightParagraph = styled(Paragraph)`
    color: var(--foregroundColorMix6);
`;

const LightLink = styled(InlineUnderlinedLink)`
    color: inherit;
`;

const HeaderWrap = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const Heading = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing6);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-bottom: var(--spacing5);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding-bottom: var(--spacing3);
    }
`;

function LicencePage(): React.ReactElement {
    const eula = useEula();
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <>
            <Container>
                <Row>
                    <HeaderWrap>
                        <Heading $metricOffsets={staticFontMetrics} as="h1">
                            {eula.title}
                        </Heading>
                        <LightParagraph>
                            Last updated {formatDjangoDateString(eula.date)}
                        </LightParagraph>
                    </HeaderWrap>

                    <PrivacyPolicyWrapper>
                        <LightParagraph>
                            Please refer to our{' '}
                            <LightLink to={createPrivacyPolicyUrl()}>
                                privacy policy
                            </LightLink>
                            , which covers our licence agreements, for more
                            details on how we treat your information.
                        </LightParagraph>
                    </PrivacyPolicyWrapper>
                </Row>
                <Row>
                    <Inner>
                        <LicenceSectionLinks />
                    </Inner>
                </Row>
                <Row>
                    <Inner>
                        <LicenceContent
                            dangerouslySetInnerHTML={{
                                __html: eula.introHtml.join(''),
                            }}
                        />
                        {eula.sections.map(
                            (section): React.ReactElement => (
                                <LicenceSection
                                    key={`licenceSection${section.id}`}
                                    section={section}
                                />
                            ),
                        )}
                    </Inner>
                </Row>
            </Container>
        </>
    );
}

export default React.memo(LicencePage);
