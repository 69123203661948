import React from 'react';
import type { EditorState } from 'draft-js';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import useTypeEditorUsedStyles from '../hooks/useTypeEditorUsedStyles';
import { useEditorState, useFontStyleState } from './TypeEditorContext';
import { useFontFamily } from './PageContext';
import { Select, SelectItem } from './Select';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import { TEST_ID } from '../settings/E2e';
import updateEditorInlineStyles from '../utils/type-editor/updateEditorInlineStyles';
import { TypeEditorActiveStatus } from './TypeEditorToolbar';
import { VIEWPORT } from '../settings/Global';

const Wrap = styled.span<{ $activeStatus?: TypeEditorActiveStatus }>`
    --lozengeMinWidth: 180px;
    --lozengeWidth: calc(15vw - var(--gridMarginGap));

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        --lozengeWidth: calc(40vw - var(--gridMarginGap));
        --lozengeMinWidth: auto;
    }

    ${({ $activeStatus }): FlattenSimpleInterpolation | null =>
        $activeStatus === 'inactive'
            ? css`
                  --lozengeColor: var(--foregroundColorMix6);
                  --lozengeHoverColor: var(--foregroundColorMix4);
                  --lozengeBackgroundColor: transparent;
                  --lozengeBackgroundHoverColor: transparent;
              `
            : null}
`;

const TypeEditorStylesInput = React.forwardRef(
    (
        {
            activeStatus,
        }: {
            activeStatus: TypeEditorActiveStatus;
        },
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const [editorState, setEditorState] = useEditorState();
        const [fontStyle, setFontStyle] = useFontStyleState();
        const { fontStyles } = useFontFamily();
        const usedStyles = useTypeEditorUsedStyles();

        const onChange = React.useCallback(
            (value: string): void => {
                setEditorState((state: EditorState): EditorState => {
                    const newEditorState = updateEditorInlineStyles({
                        editorState: state,
                        stylesToApply: fontStyles
                            .filter((fontStyle) => fontStyle.id === value)
                            .map((fontStyle) =>
                                getCssFontFamilyNameFromId(fontStyle.id),
                            ),
                        stylesToRemove: fontStyles
                            .filter((fontStyle) => fontStyle.id !== value)
                            .map((fontStyle) =>
                                getCssFontFamilyNameFromId(fontStyle.id),
                            ),
                    });

                    // If we've selected all text (or none, in which case the selection will be expanded to all)
                    // then we want to update the `useFontStyleState` context...
                    const currentSelection = state.getSelection();
                    if (
                        currentSelection.isCollapsed() ||
                        (currentSelection.getStartOffset() === 0 &&
                            currentSelection.getEndOffset() ===
                                state.getCurrentContent().getPlainText('')
                                    .length)
                    ) {
                        setFontStyle(value);
                    }

                    return newEditorState;
                });
            },
            [fontStyles, setEditorState, setFontStyle],
        );

        const selectedValue = React.useMemo(() => {
            const currentInlineStyle = editorState.getCurrentInlineStyle();
            return (
                fontStyles.find(
                    (style) =>
                        currentInlineStyle &&
                        currentInlineStyle.has(
                            getCssFontFamilyNameFromId(style.id),
                        ),
                )?.id || fontStyle
            );
        }, [editorState, fontStyle, fontStyles]);

        return (
            <Wrap $activeStatus={activeStatus}>
                <Select
                    ref={forwardedRef}
                    e2eId={TEST_ID.TYPE_EDITOR_STYLE_SELECT}
                    onValueChange={onChange}
                    placeholder={usedStyles.join(', ') || 'Styles'}
                    hideIcon={activeStatus === 'inactive'}
                    value={selectedValue}
                    contentMinWidth="180px"
                    contentWidth="15vw"
                >
                    {fontStyles.map(
                        (fontStyle): React.ReactElement => (
                            <SelectItem key={fontStyle.id} value={fontStyle.id}>
                                {fontStyle.name}
                            </SelectItem>
                        ),
                    )}
                </Select>
            </Wrap>
        );
    },
);
TypeEditorStylesInput.displayName = 'TypeEditorStylesInput';

export default TypeEditorStylesInput;
