import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { LINE_HEIGHT } from '../settings/Global';
import type { AllMetricOffsets } from '../utils/extractFontAssetMetricOffsets';

/*
 * Note that these headings have been assigned 'h1' -> 'h6' by default, but
 * it's encouraged that you implement the correct semantics with the "as" prop.
 *
 * Eg.
 * <HeadingSuperLarge as="h2" />
 */

type LineHeightKey = Extract<
    keyof typeof LINE_HEIGHT,
    'HEADING_1' | 'HEADING_2'
>;

const headingBase = css<{
    $lineHeight?: LineHeightKey;
    $metricOffsets?: AllMetricOffsets;
}>`
    font-family: var(--fontFamilySoehne);
    font-weight: bold;
    line-height: ${({ $lineHeight }): number =>
        LINE_HEIGHT[$lineHeight || 'HEADING_1']};

    ${({ $metricOffsets, $lineHeight }): FlattenSimpleInterpolation | null =>
        $metricOffsets
            ? css`
                  margin-top: ${-$metricOffsets.SOEHNE_HALBFETT[
                      $lineHeight || 'HEADING_1'
                  ].ascent}em;
                  margin-bottom: ${$metricOffsets.SOEHNE_HALBFETT[
                      $lineHeight || 'HEADING_1'
                  ].descent}em;
              `
            : null}
`;

export const HeadingSuperLarge = styled.h1`
    ${headingBase};
    font-size: var(--fontSizeFixed8);
    letter-spacing: -0.02em;
`;

export const HeadingExtraLarge = styled.h2`
    ${headingBase};
    font-size: var(--fontSizeFixed7);
`;

export const HeadingLarge = styled.h3`
    ${headingBase};
    font-size: var(--fontSizeFixed6);
`;

export const HeadingMedium = styled.h4`
    ${headingBase};
    font-size: var(--fontSizeFixed5);
`;

export const HeadingSmall = styled.h5.attrs<{
    $lineHeight?: LineHeightKey;
}>(({ ...props }) => ({
    $lineHeight: 'HEADING_2',
    ...props,
}))`
    ${headingBase};
    font-size: var(--fontSizeFixed4);
`;
