import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import type {
    CountryChoices,
    CartGetStripeClientSecretMutation,
    CartGetStripeClientSecretMutationVariables,
    NewCartMutation,
    CartSetCheckoutDetailsMutation,
    CartSetCheckoutDetailsMutationVariables,
    CartSetBillingCountryMutationMutation,
    CartSetBillingCountryMutationMutationVariables,
} from '../gql/api-public';
import { CMS_SSR_LicenceTypeId } from '../gql/api-ssr';
import Fieldset from './Fieldset';
import FieldSelect from './FieldSelect';
import { FieldRadio, FieldCheckbox } from './FieldRadioCheckbox';
import FieldText from './FieldText';
import FieldInputGroup from './FieldInputGroup';
import {
    createCheckoutSuccessUrl,
    createInvoiceCheckoutUrl,
} from '../utils/urlHelper';
import CheckoutFormLicenceAgreementFieldInputGroup from './CheckoutFormLicenceAgreementFieldInputGroup';
import useCartQuery, { CartTier } from '../hooks/useCartQuery';
import useCountrySelectOptions from '../hooks/useCountrySelectOptions';
import {
    cartGetStripeClientSecret,
    cartQuery as cartQueryGql,
    cartSetBillingCountryMutation,
    cartSetCheckoutDetailsMutation,
    newCartMutation,
} from '../utils/runtimeQueries';
import { TEST_SITE_CHECKOUT_DISABLED_MESSAGE } from '../settings/Global';
import useCartLicenceTypes from '../hooks/useCartLicenceTypes';
import { HeadingSmall } from './Heading';
import submitStripe from '../utils/submitStripe';
import {
    Inner,
    Section,
    SectionContent,
    SectionLabel,
    SectionLabelInner,
    SubSection,
    Fields,
    CheckboxFields,
    RadioFields,
    RadioLabel,
    SubmitButton,
} from './CheckoutFormElements';
import CheckoutFormCreditCardSection from './CheckoutFormCreditCardSection';
import {
    MAX_DEFAULT,
    MAX_DEFAULT_ERROR,
    MAX_SMALL,
    MAX_SMALL_ERROR,
} from './CheckoutFormSettings';
import ProcessingOverlay from './ProcessingOverlay';
import PaymentError from '../utils/PaymentError';
import CartSummaryMobile from './CartSummaryMobile';
import { sentryException } from '../utils/sentry';
import notNull from '../utils/notNull';
import useConfig from '../hooks/useConfig';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import isVowel from '../utils/isVowel';
import notUndefined from '../utils/notUndefined';
import useIsCartEmpty from '../hooks/useIsCartEmpty';
import capitalize from '../utils/capitalize';
import { useErrorOverlayState } from './GlobalRuntimeState';
import useUserQuery from '../hooks/useUserQuery';
import useLocationInvoiceNumber from '../hooks/useLocationInvoiceNumber';
import { useIsInvoicePage } from './PageContext';
import useTaxIdLabel from '../hooks/useTaxIdLabel';

enum LICENSED_FOR_OPTION {
    SOMEONE_ELSE = 'SOMEONE_ELSE',
    MYSELF = 'MYSELF',
}

export interface CheckoutFormData {
    userEmail: string;
    billingName: string;
    billingCompanyName: string;
    billingAddress1: string;
    billingAddress2: string;
    billingCity: string;
    billingState: string;
    billingPostcode: string;
    billingCountry: string;
    billingVatNumber: string;
    licensedFor: LICENSED_FOR_OPTION;
    licensedToCompanyName: string;
    licensedToAddress1: string;
    licensedToAddress2: string;
    licensedToCity: string;
    licensedToState: string;
    licensedToPostcode: string;
    licensedToCountry: string | undefined;
    licensedToVatNumber: string;
    licensedDomain: string;
    licensedApp: string;
    licenceAgreement: boolean;
    emailOptIn: boolean;
    cardName: string;

    /*
     * Extra fields for particular licence tiers
     */
    extraTierFields: {
        licenceTypeId: string;
        licenceSubject: string;
    }[];

    /*
     * We don't actually capture values for these fields. They are only defined
     * so that we can present validation errors messages with them (using
     * `setError`).
     */
    payment: typeof undefined;
    cardNumber: typeof undefined;
    cardExpiry: typeof undefined;
    cardCvc: typeof undefined;
}

export enum FormSubmissionState {
    Idle,
    Submitting,
    Submitted,
}

function CheckoutForm(): React.ReactElement | null {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const userQuery = useUserQuery();
    const user = userQuery?.data?.user;
    const config = useConfig();
    const licenceTypes = useCartLicenceTypes();
    const invoiceNumber = useLocationInvoiceNumber();
    const isInvoiceCheckout = useIsInvoicePage();
    const isUserTestingSite = useIsUserTestingSite();
    const cartIsEmpty = useIsCartEmpty(cart);
    const [doCartSetBillingCountry] = useMutation<
        CartSetBillingCountryMutationMutation,
        CartSetBillingCountryMutationMutationVariables
    >(cartSetBillingCountryMutation);
    const [doCartSetCheckoutDetails] = useMutation<
        CartSetCheckoutDetailsMutation,
        CartSetCheckoutDetailsMutationVariables
    >(cartSetCheckoutDetailsMutation);
    const [doCartGetStripeClientSecret] = useMutation<
        CartGetStripeClientSecretMutation,
        CartGetStripeClientSecretMutationVariables
    >(cartGetStripeClientSecret);
    const [doNewCart] = useMutation<NewCartMutation>(newCartMutation);

    const stripe = useStripe();
    const elements = useElements();
    const [formSubmissionState, setFormSubmissionState] =
        React.useState<FormSubmissionState>(FormSubmissionState.Idle);

    // Some tiers will require a licence subject to be entered. Retrieve them
    // in the right order (e.g. desktop, web, app, etc.), as laid out by the
    // licence types from useCartLicenceTypes.
    const cartTiersWithLicenceSubjectField: CartTier[] = React.useMemo(() => {
        if (!cart?.licenceTiers) {
            return [];
        }
        return licenceTypes
            .map((licenceType) => {
                return cart.licenceTiers.find(
                    (cartTier) =>
                        (cartTier.tier.hasLicenceSubjectField ||
                            cartTier.tier.hasContractDetailsField) &&
                        cartTier.tier.licenceType.id === licenceType.id,
                );
            })
            .filter(notUndefined);
    }, [licenceTypes, cart?.licenceTiers]);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        control,
        formState: { errors },
    } = useForm<CheckoutFormData>({
        defaultValues: cart
            ? {
                  billingName:
                      (cart.billingName ? cart.billingName : user?.fullName) ||
                      '',
                  userEmail:
                      (cart.userEmail ? cart.userEmail : user?.email) || '',
                  billingCompanyName:
                      (cart.billingCompanyName
                          ? cart.billingCompanyName
                          : user?.billingCompanyName) || '',
                  billingAddress1:
                      (cart.billingAddress1
                          ? cart.billingAddress1
                          : user?.billingAddress1) || '',
                  billingAddress2:
                      (cart.billingAddress2
                          ? cart.billingAddress2
                          : user?.billingAddress2) || '',
                  billingCity:
                      (cart.billingCity
                          ? cart.billingCity
                          : user?.billingCity) || '',
                  billingState:
                      (cart.billingState
                          ? cart.billingState
                          : user?.billingState) || '',
                  billingPostcode:
                      (cart.billingPostcode
                          ? cart.billingPostcode
                          : user?.billingPostcode) || '',
                  billingCountry:
                      (cart.billingCountry
                          ? cart.billingCountry
                          : user?.billingCountry) || undefined,
                  billingVatNumber:
                      (cart.billingVatNumber
                          ? cart.billingVatNumber
                          : user?.billingVatNumber) || '',
                  licensedFor: cart.licensedForSomeoneElse
                      ? LICENSED_FOR_OPTION.SOMEONE_ELSE
                      : LICENSED_FOR_OPTION.MYSELF,
                  licensedToCompanyName: cart.licensedToCompanyName || '',
                  licensedToAddress1: cart.licensedToAddress1 || '',
                  licensedToAddress2: cart.licensedToAddress2 || '',
                  licensedToCity: cart.licensedToCity || '',
                  licensedToState: cart.licensedToState || '',
                  licensedToPostcode: cart.licensedToPostcode || '',
                  licensedToCountry: cart.licensedToCountry || undefined,
                  licensedToVatNumber: cart.licensedToVatNumber || '',
                  licenceAgreement: false,
                  emailOptIn: cart.emailOptIn,
                  cardName: '',
                  extraTierFields: cartTiersWithLicenceSubjectField.map(
                      (cartTier) => {
                          return {
                              licenceTypeId: cartTier.tier.licenceType.id,
                              licenceSubject: cartTier.licenceSubject || '',
                          };
                      },
                  ),
                  payment: undefined,
                  cardNumber: undefined,
                  cardExpiry: undefined,
                  cardCvc: undefined,
              }
            : undefined,
    });

    const [, setShowErrorOverlay] = useErrorOverlayState();
    const countryOptions = useCountrySelectOptions();
    const watchLicensedForSomeoneElse = useWatch({
        control,
        name: 'licensedFor',
    });
    const watchBillingCountry = useWatch({
        control,
        name: 'billingCountry',
    }) as CountryChoices;
    const watchLicensedToCountry = useWatch({
        control,
        name: 'licensedToCountry',
    }) as CountryChoices;
    const taxIdLabel = useTaxIdLabel(watchBillingCountry);
    const taxIdLabelLicensedTo = useTaxIdLabel(watchLicensedToCountry);

    // We're watching billing country changes as they _change the currency_ of the cart!
    React.useEffect((): void => {
        if (
            watchBillingCountry &&
            watchBillingCountry !== cart?.billingCountry
        ) {
            doCartSetBillingCountry({
                variables: {
                    input: {
                        billingCountry: watchBillingCountry,
                        invoiceNumber: isInvoiceCheckout
                            ? invoiceNumber
                            : undefined,
                    },
                },
            });
        }
    }, [watchBillingCountry, cart?.billingCountry, doCartSetBillingCountry]);

    if (!cart) {
        return null;
    }

    const onSubmit = async (formData: CheckoutFormData): Promise<void> => {
        if (formSubmissionState !== FormSubmissionState.Idle) return;

        setFormSubmissionState(FormSubmissionState.Submitting);

        // On the user testing site, checkout is possibly disabled...
        if (isUserTestingSite && !config.userTestingCheckoutAllowed) {
            throw new PaymentError(TEST_SITE_CHECKOUT_DISABLED_MESSAGE);
        }

        const licensedForSomeoneElse =
            formData.licensedFor === LICENSED_FOR_OPTION.SOMEONE_ELSE;

        try {
            const input = {
                ...formData,
                // These fields are part of formData but shouldn't be in the
                // input payload.
                extraTierFields: undefined,
                licenceAgreement: undefined,
                licensedFor: undefined,
                cardName: undefined,
                payment: undefined,
                cardNumber: undefined,
                cardExpiry: undefined,
                cardCvc: undefined,

                // These fields are strings for the purposes of the form, but
                // need to be cast to booleans for the purposes of the input
                // payload.
                licensedForSomeoneElse,
                // We reset the country to `undefined`, just in case someone
                // switched to `licensedForSomeoneElse` and back, at which
                // point the country would be an empty string (incorrect).
                licensedToCountry: licensedForSomeoneElse
                    ? formData.licensedToCountry
                    : undefined,

                invoiceNumber: isInvoiceCheckout ? invoiceNumber : undefined,
            };

            // Only send extra tier fields that are still in cart (someone may have removed one on the checkout page)
            const extraTierFields =
                formData.extraTierFields.filter((extraTierField) =>
                    cartTiersWithLicenceSubjectField.some(
                        (cartTier) =>
                            cartTier.tier.licenceType.id ===
                            extraTierField.licenceTypeId,
                    ),
                ) || [];

            // Validate the users' details with the backend
            const setCheckoutDetailsResult = await doCartSetCheckoutDetails({
                variables: {
                    input,
                    extraTierFields,
                    invoiceNumber: input.invoiceNumber,
                },
                optimisticResponse: {
                    cartSetExtraTierFields: null,
                    cartSetCheckoutDetails: {
                        __typename: 'CartSetCheckoutDetailsMutationPayload',
                        cart: {
                            ...cart,
                            licenceTiers: cart.licenceTiers.map((cartTier) => {
                                return {
                                    ...cartTier,
                                    licenceSubject:
                                        extraTierFields.find(
                                            (extraTierField) =>
                                                extraTierField.licenceTypeId ===
                                                cartTier.tier.licenceType.id,
                                        )?.licenceSubject || null,
                                };
                            }),
                        },
                        errors: [],
                    },
                },
            });

            const checkoutDetailsErrors =
                setCheckoutDetailsResult.data?.cartSetCheckoutDetails?.errors?.filter(
                    notNull,
                ) || [];

            const extraTierFieldsErrors =
                setCheckoutDetailsResult.data?.cartSetExtraTierFields?.errors?.filter(
                    notNull,
                ) || [];

            if (
                checkoutDetailsErrors.length > 0 ||
                extraTierFieldsErrors.length > 0
            ) {
                checkoutDetailsErrors.forEach((error): void => {
                    setError(error.field as keyof CheckoutFormData, {
                        type: 'generic',
                        message: error.messages.pop(),
                    });
                });
                extraTierFieldsErrors.forEach((error): void => {
                    setError(error.error.field as keyof CheckoutFormData, {
                        type: 'generic',
                        message: error.error.messages.pop(),
                    });
                });
                setFormSubmissionState(FormSubmissionState.Idle);
                return;
            }

            // Refresh the Stripe client secret, always before submission of final payment.
            const clientSecretResult = await doCartGetStripeClientSecret({
                variables: {
                    invoiceNumber: isInvoiceCheckout
                        ? invoiceNumber
                        : undefined,
                },
            });

            // The invoice has been paid already.
            if (
                isInvoiceCheckout &&
                clientSecretResult.data?.cartGetStripeClientSecret
                    ?.invoiceIsPaid
            ) {
                // Redirect to invoice page, it'll show that the invoice was paid...
                await navigate(createInvoiceCheckoutUrl(invoiceNumber), {
                    replace: true,
                });
                return;
            }

            // The invoice/quote cart can't be retrieved...
            if (
                isInvoiceCheckout &&
                !clientSecretResult.data?.cartGetStripeClientSecret?.cart
            ) {
                throw Error(`Cart not found (invoiceNumber: ${invoiceNumber}`);
            }

            // If there were no errors from API, proceed to Stripe submission
            const paymentIntentId = await submitStripe({
                stripe,
                elements,
                clientSecret:
                    clientSecretResult.data?.cartGetStripeClientSecret
                        ?.clientSecret || '',
                // Adding billing details helps with fraud prevention
                billingDetails: {
                    name: formData.cardName,
                    email: formData.userEmail,
                    address: {
                        line1: formData.billingAddress1 || undefined,
                        line2: formData.billingAddress2 || undefined,
                        city: formData.billingCity || undefined,
                        country: formData.billingCountry || undefined,
                        postal_code: formData.billingPostcode || undefined,
                        state: formData.billingState || undefined,
                    },
                },
            });

            // Reset the cart to a new, blank one
            if (!isInvoiceCheckout) {
                await doNewCart({
                    /*
                     * This is a crucial step. Carts have unique "id" properties, so we
                     * need to force this query to totally re-fetch in order to force cache
                     * re-evaluation for this query!
                     */
                    refetchQueries: [{ query: cartQueryGql }],
                    awaitRefetchQueries: true,
                });
            }

            setFormSubmissionState(FormSubmissionState.Submitted);

            await navigate(createCheckoutSuccessUrl(), {
                replace: true,
                state: {
                    paymentIntentId,
                },
            });
        } catch (e) {
            setFormSubmissionState(FormSubmissionState.Idle);
            if (e instanceof PaymentError) {
                setError('payment', {
                    type: 'generic',
                    message: e.message,
                });
            } else {
                sentryException(e);
                setShowErrorOverlay({ isShown: true });
            }
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            // To prevent native form validation
            noValidate
        >
            <ProcessingOverlay
                shown={formSubmissionState !== FormSubmissionState.Idle}
                title={
                    <>
                        Please wait
                        <br />
                        Processing payment…
                    </>
                }
                description="Please do not refresh or navigate away from this page during processing."
            />
            <Inner>
                <Section>
                    <SectionLabel>
                        <SectionLabelInner>
                            <HeadingSmall>Information</HeadingSmall>
                        </SectionLabelInner>
                    </SectionLabel>
                    <SectionContent>
                        <SubSection>
                            <Fieldset legend="Your details">
                                <Fields>
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="Full name"
                                                {...register('billingName', {
                                                    required:
                                                        'Enter a full name',
                                                    maxLength: {
                                                        value: MAX_DEFAULT,
                                                        message:
                                                            MAX_DEFAULT_ERROR,
                                                    },
                                                    disabled: cartIsEmpty,
                                                })}
                                                isError={!!errors.billingName}
                                            />
                                        }
                                        error={errors.billingName}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="Email address"
                                                {...register('userEmail', {
                                                    required:
                                                        'Enter an email address',
                                                    maxLength: {
                                                        value: MAX_DEFAULT,
                                                        message:
                                                            MAX_DEFAULT_ERROR,
                                                    },
                                                    disabled: cartIsEmpty,
                                                })}
                                                isError={!!errors.userEmail}
                                            />
                                        }
                                        error={errors.userEmail}
                                    />
                                </Fields>
                            </Fieldset>
                        </SubSection>

                        <SubSection>
                            <Fieldset legend="Billing address">
                                <Fields>
                                    <FieldInputGroup
                                        data-span="double"
                                        input={
                                            <FieldText
                                                label="Company name"
                                                {...register(
                                                    'billingCompanyName',
                                                    {
                                                        required:
                                                            'Enter a company name',
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.billingCompanyName
                                                }
                                            />
                                        }
                                        error={errors.billingCompanyName}
                                    />
                                    <FieldInputGroup
                                        data-span="double"
                                        input={
                                            <FieldText
                                                label="Street"
                                                {...register(
                                                    'billingAddress1',
                                                    {
                                                        required:
                                                            'Enter a street address',
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.billingAddress1
                                                }
                                            />
                                        }
                                        error={errors.billingAddress1}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="Suburb (optional)"
                                                {...register(
                                                    'billingAddress2',
                                                    {
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.billingAddress2
                                                }
                                            />
                                        }
                                        error={errors.billingAddress2}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="City"
                                                {...register('billingCity', {
                                                    required: 'Enter a city',
                                                    maxLength: {
                                                        value: MAX_DEFAULT,
                                                        message:
                                                            MAX_DEFAULT_ERROR,
                                                    },
                                                    disabled: cartIsEmpty,
                                                })}
                                                isError={!!errors.billingCity}
                                            />
                                        }
                                        error={errors.billingCity}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="State (optional)"
                                                {...register('billingState', {
                                                    maxLength: {
                                                        value: MAX_DEFAULT,
                                                        message:
                                                            MAX_DEFAULT_ERROR,
                                                    },
                                                    disabled: cartIsEmpty,
                                                })}
                                            />
                                        }
                                        error={errors.billingState}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="Post code"
                                                {...register(
                                                    'billingPostcode',
                                                    {
                                                        required:
                                                            'Enter a post code',
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.billingPostcode
                                                }
                                            />
                                        }
                                        error={errors.billingPostcode}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldSelect
                                                label="Country"
                                                {...register('billingCountry', {
                                                    required: 'Enter a country',
                                                    maxLength: 2,
                                                    disabled: cartIsEmpty,
                                                })}
                                                isError={
                                                    !!errors.billingCountry
                                                }
                                            >
                                                {countryOptions}
                                            </FieldSelect>
                                        }
                                        error={errors.billingCountry}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label={`${taxIdLabel} (optional)`}
                                                {...register(
                                                    'billingVatNumber',
                                                    {
                                                        maxLength: {
                                                            value: MAX_SMALL,
                                                            message:
                                                                MAX_SMALL_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.billingVatNumber
                                                }
                                            />
                                        }
                                        error={errors.billingVatNumber}
                                    />
                                </Fields>
                            </Fieldset>

                            <FieldInputGroup
                                input={
                                    <RadioFields
                                        role="radiogroup"
                                        aria-labelledby="#licensedForLabel"
                                    >
                                        <RadioLabel id="licensedForLabel">
                                            Fonts are for:
                                        </RadioLabel>
                                        <FieldRadio
                                            label="Myself"
                                            value={LICENSED_FOR_OPTION.MYSELF}
                                            {...register('licensedFor', {
                                                required: true,
                                                disabled: cartIsEmpty,
                                            })}
                                            isError={!!errors.licensedFor}
                                        />
                                        <FieldRadio
                                            label="Someone else"
                                            value={
                                                LICENSED_FOR_OPTION.SOMEONE_ELSE
                                            }
                                            {...register('licensedFor', {
                                                required: true,
                                                disabled: cartIsEmpty,
                                            })}
                                            isError={!!errors.licensedFor}
                                        />
                                    </RadioFields>
                                }
                            />

                            {watchLicensedForSomeoneElse ===
                                LICENSED_FOR_OPTION.SOMEONE_ELSE && (
                                <Fields>
                                    <FieldInputGroup
                                        data-span="double"
                                        input={
                                            <FieldText
                                                label="Company name"
                                                {...register(
                                                    'licensedToCompanyName',
                                                    {
                                                        required:
                                                            'Enter a company name',
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToCompanyName
                                                }
                                            />
                                        }
                                        error={errors.licensedToCompanyName}
                                    />
                                    <FieldInputGroup
                                        data-span="double"
                                        input={
                                            <FieldText
                                                label="Street"
                                                {...register(
                                                    'licensedToAddress1',
                                                    {
                                                        required:
                                                            'Enter a street address',
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToAddress1
                                                }
                                            />
                                        }
                                        error={errors.licensedToAddress1}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="Suburb (optional)"
                                                {...register(
                                                    'licensedToAddress2',
                                                    {
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToAddress2
                                                }
                                            />
                                        }
                                        error={errors.licensedToAddress2}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="City"
                                                {...register('licensedToCity', {
                                                    required: 'Enter a city',
                                                    maxLength: {
                                                        value: MAX_DEFAULT,
                                                        message:
                                                            MAX_DEFAULT_ERROR,
                                                    },
                                                    disabled: cartIsEmpty,
                                                })}
                                                isError={
                                                    !!errors.licensedToCity
                                                }
                                            />
                                        }
                                        error={errors.licensedToCity}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="State (optional)"
                                                {...register(
                                                    'licensedToState',
                                                    {
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToState
                                                }
                                            />
                                        }
                                        error={errors.licensedToState}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label="Post code"
                                                {...register(
                                                    'licensedToPostcode',
                                                    {
                                                        required:
                                                            'Enter a post code',
                                                        maxLength: {
                                                            value: MAX_DEFAULT,
                                                            message:
                                                                MAX_DEFAULT_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToPostcode
                                                }
                                            />
                                        }
                                        error={errors.licensedToPostcode}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldSelect
                                                label="Country"
                                                {...register(
                                                    'licensedToCountry',
                                                    {
                                                        required:
                                                            'Enter a country',
                                                        maxLength: 2,
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToCountry
                                                }
                                            >
                                                {countryOptions}
                                            </FieldSelect>
                                        }
                                        error={errors.licensedToCountry}
                                    />
                                    <FieldInputGroup
                                        input={
                                            <FieldText
                                                label={`${taxIdLabelLicensedTo} (optional)`}
                                                {...register(
                                                    'licensedToVatNumber',
                                                    {
                                                        maxLength: {
                                                            value: MAX_SMALL,
                                                            message:
                                                                MAX_SMALL_ERROR,
                                                        },
                                                        disabled: cartIsEmpty,
                                                    },
                                                )}
                                                isError={
                                                    !!errors.licensedToVatNumber
                                                }
                                            />
                                        }
                                        error={errors.licensedToVatNumber}
                                    />
                                </Fields>
                            )}
                            {cartTiersWithLicenceSubjectField.map(
                                (cartTier, index) => {
                                    const licenceType = licenceTypes.find(
                                        (licenceType) =>
                                            licenceType.id ===
                                            cartTier.tier.licenceType.id,
                                    );
                                    if (!licenceType) {
                                        throw new Error(
                                            `Couldn't retrieve licence type from useFieldArray fields!`,
                                        );
                                    }
                                    const label =
                                        cartTier.tier.licenceType
                                            .licenceSubjectLabel || 'subject';
                                    const licenceName = licenceType.name;

                                    return cartTier.tier
                                        .hasLicenceSubjectField ? (
                                        <Fieldset
                                            key={`extraTierFields${index}`}
                                            legend={`${capitalize(
                                                label,
                                            )} for ${licenceName} Font Licence`}
                                        >
                                            <>
                                                {
                                                    errors.extraTierFields?.[
                                                        index
                                                    ]?.licenceTypeId
                                                }
                                                <input
                                                    type="hidden"
                                                    readOnly
                                                    value={
                                                        cartTier.tier
                                                            .licenceType.id
                                                    }
                                                    {...register(
                                                        `extraTierFields.${index}.licenceTypeId`,
                                                        {
                                                            required: true,
                                                        },
                                                    )}
                                                />
                                                <FieldInputGroup
                                                    data-span="double"
                                                    input={
                                                        <FieldText
                                                            label={
                                                                cartTier.tier
                                                                    .licenceType
                                                                    .id ===
                                                                CMS_SSR_LicenceTypeId.WEB
                                                                    ? 'example.com'
                                                                    : cartTier
                                                                          .tier
                                                                          .licenceType
                                                                          .id ===
                                                                      CMS_SSR_LicenceTypeId.APP
                                                                    ? 'Example App'
                                                                    : cartTier
                                                                          .tier
                                                                          .licenceType
                                                                          .id ===
                                                                      CMS_SSR_LicenceTypeId.OEM
                                                                    ? 'Example Product'
                                                                    : undefined
                                                            }
                                                            {...register(
                                                                `extraTierFields.${index}.licenceSubject`,
                                                                {
                                                                    required: `Enter a${
                                                                        isVowel(
                                                                            label[0],
                                                                        )
                                                                            ? 'n'
                                                                            : ''
                                                                    } ${label}`,
                                                                    maxLength: {
                                                                        value: MAX_DEFAULT,
                                                                        message:
                                                                            MAX_DEFAULT_ERROR,
                                                                    },
                                                                    disabled:
                                                                        cartIsEmpty,
                                                                },
                                                            )}
                                                            isError={
                                                                !!errors
                                                                    .extraTierFields?.[
                                                                    index
                                                                ]
                                                                    ?.licenceSubject
                                                            }
                                                        />
                                                    }
                                                    error={
                                                        errors
                                                            .extraTierFields?.[
                                                            index
                                                        ]?.licenceSubject
                                                    }
                                                />
                                            </>
                                        </Fieldset>
                                    ) : cartTier.tier
                                          .hasContractDetailsField ? (
                                        <FieldInputGroup
                                            key={`lt${cartTier.id}`}
                                            input={
                                                <RadioFields>
                                                    <RadioLabel>
                                                        {`${cartTier.tier.licenceType.contractDetailsLabel}:`}
                                                    </RadioLabel>
                                                    <div>
                                                        {cartTier.contractDetails ||
                                                            'Signature pending.'}
                                                    </div>
                                                </RadioFields>
                                            }
                                        />
                                    ) : null;
                                },
                            )}
                            <CheckboxFields>
                                <CheckoutFormLicenceAgreementFieldInputGroup
                                    error={errors.licenceAgreement}
                                    register={register}
                                />
                                <FieldInputGroup
                                    input={
                                        <FieldCheckbox
                                            label="Subscribe to emails about new font releases"
                                            {...register('emailOptIn', {
                                                disabled: cartIsEmpty,
                                            })}
                                            isError={!!errors.emailOptIn}
                                        />
                                    }
                                    error={errors.emailOptIn}
                                />
                            </CheckboxFields>
                        </SubSection>
                    </SectionContent>
                </Section>
                <CheckoutFormCreditCardSection
                    isSubmitting={
                        formSubmissionState === FormSubmissionState.Submitting
                    }
                    disabled={cartIsEmpty}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    register={register}
                />
                <CartSummaryMobile
                    buttonElement={
                        <SubmitButton
                            isSubmitting={
                                formSubmissionState ===
                                FormSubmissionState.Submitting
                            }
                        />
                    }
                    isBuyPage={false}
                />
            </Inner>
        </form>
    );
}

export default React.memo(CheckoutForm);
