import React from 'react';
import styled from 'styled-components';
import SVGIcon from './SVGIcon';
import { useLineHeightState } from './TypeEditorContext';
import InputSlider from './InputSlider';
import { Div, LOZENGE_HORIZONTAL_PADDING } from './Lozenge';
import { VIEWPORT } from '../settings/Global';

const Container = styled(Div)`
    --lozengeMinWidth: 160px;
    --lozengeWidth: calc(15vw - var(--gridMarginGap));
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${LOZENGE_HORIZONTAL_PADDING}px;
    font-feature-settings: 'tnum';
`;

const Value = styled.div`
    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const TypeEditorLineHeightInput = React.forwardRef(
    (
        props: Record<string, never>,
        forwardedRef: React.Ref<HTMLSpanElement>,
    ): React.ReactElement => {
        const [lineHeight, setLineHeight] = useLineHeightState();

        return (
            <Container title="Line height">
                <SVGIcon type="LINE_HEIGHT" />
                <InputSlider
                    ref={forwardedRef}
                    value={[lineHeight]}
                    min={0.8}
                    max={3}
                    step={0.1}
                    onValueChange={(val): void =>
                        val.length ? setLineHeight(val[0]) : undefined
                    }
                />
                <Value>{lineHeight.toFixed(1)}</Value>
            </Container>
        );
    },
);
TypeEditorLineHeightInput.displayName = 'TypeEditorLineHeightInput';

export default TypeEditorLineHeightInput;
