import React from 'react';

import soehneHalbfettKursiv from '../assets/fonts/Soehne/Sohne-HalbfettKursiv.woff2';
import soehneHalbfett from '../assets/fonts/Soehne/Sohne-Halbfett.woff2';
import soehneBuchKursiv from '../assets/fonts/Soehne/Sohne-BuchKursiv.woff2';
import soehneBuch from '../assets/fonts/Soehne/Sohne-Buch.woff2';

import soehneMonoBuch from '../assets/fonts/Soehne/SohneMono-Buch.woff2';
import soehneMonoHalbfett from '../assets/fonts/Soehne/SohneMono-Halbfett.woff2';

import soehneIkon from '../assets/fonts/Soehne/SohneIkon-Buch.woff2';

// import signifierBlack from '../assets/fonts/Signifier/signifier-web-black.woff2';
// import signifierLight from '../assets/fonts/Signifier/signifier-web-light.woff2';
// import signifierLightItalic from '../assets/fonts/Signifier/signifier-web-light-italic.woff2';
// import signifierBold from '../assets/fonts/Signifier/signifier-web-bold.woff2';
// import signifierBoldItalic from '../assets/fonts/Signifier/signifier-web-bold-italic.woff2';

import martinaPlantijnRegular from '../assets/fonts/MartinaPlantijn/martina-plantijn-web-regular.woff2';
import martinaPlantijnItalic from '../assets/fonts/MartinaPlantijn/martina-plantijn-web-italic.woff2';
import martinaPlantijnBold from '../assets/fonts/MartinaPlantijn/martina-plantijn-web-bold.woff2';
import martinaPlantijnBoldItalic from '../assets/fonts/MartinaPlantijn/martina-plantijn-web-bold-italic.woff2';

// import theFutureLight from '../assets/fonts/TheFuture/the-future-web-light.woff2';

import calibre2Regular from '../assets/fonts/Calibre2/calibre2-regular.woff2';

import { FONT_FAMILY } from '../settings/Global';
import FontFace, { Font } from './FontFace';

const globalFonts: Font[] = [
    {
        fontFamily: FONT_FAMILY.SOEHNE,
        woff2: soehneBuch,
    },
    {
        fontFamily: FONT_FAMILY.SOEHNE,
        fontStyle: 'italic',
        woff2: soehneBuchKursiv,
    },
    {
        fontFamily: FONT_FAMILY.SOEHNE,
        fontWeight: 'bold',
        woff2: soehneHalbfett,
    },
    {
        fontFamily: FONT_FAMILY.SOEHNE,
        fontWeight: 'bold',
        fontStyle: 'italic',
        woff2: soehneHalbfettKursiv,
    },
    {
        fontFamily: FONT_FAMILY.SOEHNE_MONO,
        woff2: soehneMonoBuch,
    },
    {
        fontFamily: FONT_FAMILY.SOEHNE_MONO,
        fontWeight: 'bold',
        woff2: soehneMonoHalbfett,
    },
    {
        fontFamily: FONT_FAMILY.SOEHNE_IKON,
        woff2: soehneIkon,
    },
    {
        fontFamily: FONT_FAMILY.MARTINA_PLANTIJN,
        woff2: martinaPlantijnRegular,
    },
    {
        fontFamily: FONT_FAMILY.MARTINA_PLANTIJN,
        fontStyle: 'italic',
        woff2: martinaPlantijnItalic,
    },
    {
        fontFamily: FONT_FAMILY.MARTINA_PLANTIJN,
        fontWeight: 'bold',
        woff2: martinaPlantijnBold,
    },
    {
        fontFamily: FONT_FAMILY.MARTINA_PLANTIJN,
        fontWeight: 'bold',
        fontStyle: 'italic',
        woff2: martinaPlantijnBoldItalic,
    },
    {
        fontFamily: FONT_FAMILY.CALIBRE2,
        fontWeight: 'normal',
        woff2: calibre2Regular,
    },
];

function GlobalFonts(): React.ReactElement {
    return (
        <>
            {/*
            Preload the main font of the site.
            See https://www.zachleat.com/web/preload/
            for some light reading on this subject.
            */}
            <link
                rel="preload"
                href={soehneBuch}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <FontFace fonts={globalFonts} />
        </>
    );
}

export default React.memo(GlobalFonts);
