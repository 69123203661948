import React from 'react';
import { Link } from './Button';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { createCheckoutUrl } from '../utils/urlHelper';
import CartSummarySubtotals from './CartSummarySubtotals';
import CartSummaryInventory from './CartSummaryInventory';
import { hideScrollbars } from '../utils/stylesMixins';
import subscribeScrollRequestAnimationFrame from '../utils/subscribeScrollRequestAnimationFrame';
import { useCurrentColorScheme } from './ColorSchemeContext';
import { TEST_ID } from '../settings/E2e';
import useIsCartEmpty from '../hooks/useIsCartEmpty';
import clamp from '../utils/clamp';
import { useGlobalState } from './GlobalRuntimeState';
import useCartQuery from '../hooks/useCartQuery';
import CartShareSection from './CartShareSection';
import { CART_GRADIENT_HEIGHT, VIEWPORT } from '../settings/Global';
import useIsDefaultColorScheme from '../hooks/useIsDefaultColorScheme';
import { checkoutButtonStyles } from './CheckoutFormElements';
import CartSummaryEmpty from './CartSummaryEmpty';

const Container = styled.div`
    color: var(--foregroundColor);
    display: grid;
    height: var(--radix-popper-inside-available-height);
    /* Fill the vertical space left behind by the controls */
    grid-template-rows: 1fr auto;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        min-width: auto;
        max-width: unset;
        width: calc(
            100vw - var(--cartPaddingLeft) - var(--cartPaddingRight) -
                var(--gridMarginGap) * 2
        );
    }
`;

const Inventory = styled.div`
    --gradientHeight: ${CART_GRADIENT_HEIGHT}px;

    /*
     * Shifts the scrollbar out to the right, so that the 'remove' buttons will
     * be visible.
     */
    margin-right: calc(var(--cartPaddingRight) * -1);
    margin-top: calc(var(--gradientHeight) * -1);

    /*
     * Anything that extended outside the font bounding box on the left would be
     * cut off, unless we give it some space.
     */
    margin-left: calc(var(--cartPaddingLeft) * -1);

    position: relative;
    overflow-y: auto;
`;

const InventoryInner = styled.div`
    padding: var(--gradientHeight) var(--cartPaddingRight) var(--gradientHeight)
        var(--cartPaddingLeft);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;

    ${hideScrollbars};
`;

const Gradient = styled.div<{
    opacity: number;
    isBottom?: boolean;
}>`
    opacity: ${({ opacity }): string => opacity.toString()};
    display: block;
    position: absolute;
    ${({ isBottom }): FlattenSimpleInterpolation | undefined =>
        isBottom
            ? css`
                  bottom: 0;
              `
            : undefined};
    width: 100%;
    height: var(--gradientHeight);
    background: linear-gradient(
        ${({ isBottom }): string => (isBottom ? '180deg' : '0deg')},
        var(--overlayGradientMin) 0%,
        var(--overlayGradientMax) 100%
    );
    pointer-events: none;
`;

const Controls = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    --lozengeMaxWidth: auto;
`;

const CheckoutButton = styled(Link)`
    ${checkoutButtonStyles};
`;

function CartSummary({
    hideCheckoutButton,
    isBuyPage,
}: {
    hideCheckoutButton: boolean | undefined;
    isBuyPage: boolean | undefined;
}): React.ReactElement | null {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const [viewportHeight] = useGlobalState('viewportHeight');
    const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
    const summaryContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [topGradientOpacity, setTopGradientOpacity] = React.useState(0);
    const [bottomGradientOpacity, setBottomGradientOpacity] = React.useState(0);
    const currentColorScheme = useCurrentColorScheme();
    const cartIsEmpty = useIsCartEmpty(cart);
    const [summaryHeight, setSummaryHeight] = React.useState(0);
    const isDefaultTheme = useIsDefaultColorScheme();

    // Keep an eye on the summary height, to re-render the gradients if required
    React.useEffect((): (() => void) => {
        const resizeObserver = new ResizeObserver((entries): void =>
            entries.forEach(
                (entry): void =>
                    void requestAnimationFrame((): void =>
                        setSummaryHeight(entry.contentRect.height),
                    ),
            ),
        );

        if (summaryContainerRef.current) {
            resizeObserver.observe(summaryContainerRef.current);
        }
        return (): void => resizeObserver.disconnect();
    }, [setSummaryHeight]);

    React.useEffect((): void | (() => void) => {
        const onScroll = (): void => {
            if (
                !scrollContainerRef.current ||
                !summaryContainerRef.current ||
                !viewportHeight
            ) {
                return;
            }
            // Set opacity of gradients according to how close our content is to it.
            const topValue =
                (scrollContainerRef.current.scrollTop - CART_GRADIENT_HEIGHT) /
                    CART_GRADIENT_HEIGHT +
                1;
            setTopGradientOpacity(clamp(topValue, 0, 1));
            const bottomValue =
                -(
                    scrollContainerRef.current?.offsetHeight -
                    scrollContainerRef.current.scrollHeight
                ) / CART_GRADIENT_HEIGHT;
            setBottomGradientOpacity(clamp(bottomValue - topValue, 0, 1));
        };
        const unsubscribe = subscribeScrollRequestAnimationFrame(
            onScroll,
            scrollContainerRef.current,
        );

        return unsubscribe;
    }, [
        viewportHeight,
        cart?.items.length,
        currentColorScheme,
        summaryContainerRef.current?.offsetHeight,
        scrollContainerRef.current?.scrollHeight,
        summaryHeight,
    ]);

    return (
        <Container data-cy={TEST_ID.CART_WRAP}>
            <Inventory>
                <InventoryInner ref={scrollContainerRef}>
                    {cartIsEmpty && !isBuyPage ? (
                        <CartSummaryEmpty />
                    ) : (
                        <CartSummaryInventory
                            containerRef={summaryContainerRef}
                        />
                    )}
                </InventoryInner>
                {cartIsEmpty ? null : (
                    <>
                        <Gradient opacity={topGradientOpacity} />
                        <Gradient
                            opacity={bottomGradientOpacity}
                            isBottom={true}
                        />
                    </>
                )}
            </Inventory>
            <Controls>
                <CartSummarySubtotals />
                <CartShareSection />
                {!hideCheckoutButton && !cartIsEmpty && (
                    <CheckoutButton
                        data-cy={TEST_ID.CART_CHECKOUT_BUTTON}
                        to={createCheckoutUrl()}
                        $isDefaultTheme={isDefaultTheme}
                    >
                        Checkout
                    </CheckoutButton>
                )}
            </Controls>
        </Container>
    );
}

export default React.memo(CartSummary);
