import React from 'react';
import styled from 'styled-components';
import { ZINDEX } from '../settings/Global';
import { useCurrentColorScheme } from './ColorSchemeContext';
import getColorThemeFromBaseColors from '../utils/getColorThemeFromBaseColors';
import debounce from 'lodash/debounce';
import FieldText from './FieldText';
import Button from './Button';
import { useGlobalState } from './GlobalRuntimeState';
import isValidHexColour, { isThreeDigitHex } from '../utils/isValidHexColor';
import convert3DigitHexColorTo6Digits from '../utils/convert3DigitHexColorTo6Digits';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const AdminPanel = styled.div`
    position: fixed;
    bottom: 0;
    right: var(--gridMarginGap);
    padding: var(--spacing3);
    z-index: ${ZINDEX.ADMIN_PANEL};
    background-color: var(--foregroundColorMix7);
    display: grid;
    row-gap: var(--spacing2);
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: var(--spacing2);
    align-items: center;
`;

function SingleColourPicker({
    colorKey,
}: {
    colorKey: 'foregroundColor' | 'backgroundColor' | 'highlightColor';
}): React.ReactElement {
    const [adminColorScheme, setAdminColorScheme] =
        useGlobalState('adminColorScheme');
    const colorScheme = useCurrentColorScheme();
    const activeColorScheme = adminColorScheme || colorScheme;
    const [textInputValue, setTextInputValue] = React.useState<string>(
        activeColorScheme[colorKey],
    );

    const myActiveColor = activeColorScheme[colorKey];

    React.useEffect(() => {
        if (
            isThreeDigitHex(textInputValue) &&
            convert3DigitHexColorTo6Digits(textInputValue) === myActiveColor
        ) {
            return;
        }
        setTextInputValue(myActiveColor);
    }, [myActiveColor]);

    const debouncedColorChange = React.useMemo(
        () =>
            debounce(
                (props: {
                    foregroundColor: string;
                    backgroundColor: string;
                    highlightColor: string;
                }) => {
                    setAdminColorScheme(getColorThemeFromBaseColors(props));
                },
                250,
            ),
        [setAdminColorScheme],
    );

    const onChangeOrBlur = React.useCallback(
        (
            e:
                | React.ChangeEvent<HTMLInputElement>
                | React.FocusEvent<HTMLInputElement>,
        ): void => {
            let value = e.target.value;
            setTextInputValue(value);
            if (!isValidHexColour(value)) {
                return;
            }
            if (isThreeDigitHex(value)) {
                // Reformat hex colour with six digits
                value = convert3DigitHexColorTo6Digits(value);
            }
            return debouncedColorChange({
                ...activeColorScheme,
                [colorKey]: value,
            });
        },
        [activeColorScheme, colorKey, debouncedColorChange],
    );

    return (
        <Row>
            <input
                type="color"
                value={myActiveColor}
                onClick={(e): void => {
                    e.stopPropagation();
                    // e.preventDefault();
                }}
                onChange={onChangeOrBlur}
            />
            <FieldText
                value={textInputValue}
                onClick={(e): void => {
                    e.stopPropagation();
                    // e.preventDefault();
                }}
                onChange={onChangeOrBlur}
            />
        </Row>
    );
}

function AdminColorPicker(): React.ReactElement | null {
    const [, setAdminColorScheme] = useGlobalState('adminColorScheme');
    const [isAdminColorPickerShown, setIsAdminColorPickerShown] =
        useGlobalState('isAdminColorPickerShown');

    if (!isAdminColorPickerShown) {
        return null;
    }

    return (
        <AdminPanel>
            <SingleColourPicker colorKey={'backgroundColor'} />
            <SingleColourPicker colorKey={'foregroundColor'} />
            <SingleColourPicker colorKey={'highlightColor'} />
            <Row>
                <Button
                    onClickCapture={(e): void => {
                        e.preventDefault();
                        e.stopPropagation();
                        setAdminColorScheme(undefined);
                    }}
                    title="Reset"
                    secondary
                    type="reset"
                >
                    Reset
                </Button>
                <Button
                    onClickCapture={(e): void => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsAdminColorPickerShown(false);
                    }}
                    secondary
                    type="submit"
                >
                    Hide
                </Button>
            </Row>
        </AdminPanel>
    );
}

export default React.memo(AdminColorPicker);
