import React from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/client';
import _Button from './Button';
import Fieldset from './Fieldset';
import FieldTextarea from './FieldTextarea';
import FieldInputGroup from './FieldInputGroup';
import { Fields } from './CheckoutFormElements';
import type { Order } from '../hooks/useUserOrdersQuery';
import { upgradesAccordion } from '../data/faqAccordions';

import { orderUpgradeRequestMutation } from '../utils/runtimeQueries';
import type {
    OrderUpgradeRequestMutationMutation,
    OrderUpgradeRequestMutationMutationVariables,
} from '../gql/api-public';
import { VIEWPORT } from '../settings/Global';
import {
    GridContainer,
    GridSubContainer,
    GridMainColumn,
} from './AccountOrderElements';
import { sentryException } from '../utils/sentry';
import notNull from '../utils/notNull';
import { useErrorOverlayState } from './GlobalRuntimeState';
import FieldText from './FieldText';
import Paragraph from './Paragraph';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import { createFaqUrl } from '../utils/urlHelper';

const FaqParagraph = styled(Paragraph)`
    margin-top: var(--spacing3);

    grid-column: 5 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 4;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const FieldsetContainer = styled(GridMainColumn)`
    grid-column: 5 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 4;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const buttonWrapperStyles = css`
    margin-top: var(--spacing4);
    grid-column-end: span 3;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-top: var(--gridColumnGap);
        grid-column-end: span 2;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column-end: -1;
    }
`;

const UpgradeButtonWrapper = styled(GridMainColumn)`
    ${buttonWrapperStyles};
`;

const CancelButtonWrapper = styled(GridMainColumn)`
    ${buttonWrapperStyles};
    grid-column-start: 8;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column-start: 5;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column-start: 1;
    }
`;

const Button = styled(_Button)`
    width: 100%;
`;

interface FormValues {
    details: string;
    licenceWebDomainName: string;
    licenceAppName: string;
}

export default function AccountOrderHistoryForm({
    order,
}: {
    order: Order;
}): React.ReactElement {
    const [, setErrorOverlayState] = useErrorOverlayState();
    const [isFormShown, setIsFormShown] = React.useState<boolean>(false);
    const { handleSubmit, register, setError, formState } = useForm<FormValues>(
        {
            defaultValues: {},
        },
    );
    const [doRequestUpgrade] = useMutation<
        OrderUpgradeRequestMutationMutation,
        OrderUpgradeRequestMutationMutationVariables
    >(orderUpgradeRequestMutation);

    const label =
        'Let us know what you would like to upgrade. ' +
        'You can upgrade your font licence from a few font styles to a family or collection, ' +
        "or increase the number of 'computers' (desktop) / 'page views' (web) / 'MAUs' or 'downloads' (app).";

    const onSubmit = async ({
        details,
        licenceWebDomainName,
        licenceAppName,
    }: FormValues): Promise<void> => {
        try {
            const result = await doRequestUpgrade({
                variables: {
                    input: {
                        order: order.id,
                        details,
                        licenceWebDomainName,
                        licenceAppName,
                    },
                },
            });

            // handle API errors
            const errors =
                result?.data?.orderUpgradeRequest?.errors?.filter(notNull) ||
                [];

            if (errors.length > 0) {
                errors.forEach((currentError): void => {
                    setError(currentError.field as keyof FormValues, {
                        type: 'generic',
                        message: currentError.messages.shift(),
                    });
                });
                return;
            }

            setIsFormShown(false);
        } catch (e) {
            sentryException(e);
            setErrorOverlayState({ isShown: true });
        }
    };

    return (
        <>
            {isFormShown ? (
                <GridSubContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <GridContainer>
                            <FieldsetContainer>
                                <Fieldset legend="Order upgrade">
                                    <Fields>
                                        <FieldInputGroup
                                            data-span="double"
                                            input={
                                                <FieldTextarea
                                                    label={label}
                                                    isError={
                                                        !!formState.errors
                                                            .details
                                                    }
                                                    {...register('details', {
                                                        required:
                                                            'Message is required',
                                                        maxLength: 1000,
                                                    })}
                                                />
                                            }
                                            error={formState.errors.details}
                                        />
                                        <FieldInputGroup
                                            data-span="double"
                                            input={
                                                <FieldText
                                                    label="Domain name for web licensing (optional)"
                                                    isError={
                                                        !!formState.errors
                                                            .licenceWebDomainName
                                                    }
                                                    {...register(
                                                        'licenceWebDomainName',
                                                        {
                                                            maxLength: 120,
                                                        },
                                                    )}
                                                />
                                            }
                                            error={
                                                formState.errors
                                                    .licenceWebDomainName
                                            }
                                        />
                                        <FieldInputGroup
                                            data-span="double"
                                            input={
                                                <FieldText
                                                    label="App name for app licensing (optional)"
                                                    isError={
                                                        !!formState.errors
                                                            .licenceAppName
                                                    }
                                                    {...register(
                                                        'licenceAppName',
                                                        {
                                                            maxLength: 120,
                                                        },
                                                    )}
                                                />
                                            }
                                            error={
                                                formState.errors.licenceAppName
                                            }
                                        />
                                    </Fields>
                                </Fieldset>
                            </FieldsetContainer>
                            <UpgradeButtonWrapper>
                                <Button
                                    type="submit"
                                    disabled={
                                        formState.isSubmitting || undefined
                                    }
                                >
                                    {formState.isSubmitting
                                        ? 'Sending…'
                                        : 'Send upgrade request'}
                                </Button>
                            </UpgradeButtonWrapper>
                            <CancelButtonWrapper>
                                <Button
                                    disabled={
                                        formState.isSubmitting || undefined
                                    }
                                    type="button"
                                    onClick={(): void => setIsFormShown(false)}
                                    secondary
                                >
                                    Cancel
                                </Button>
                            </CancelButtonWrapper>
                            <FaqParagraph>
                                Refer to our{' '}
                                <InlineUnderlinedLink
                                    to={`${createFaqUrl()}#${
                                        upgradesAccordion.id
                                    }`}
                                >
                                    FAQs
                                </InlineUnderlinedLink>{' '}
                                for more information.
                            </FaqParagraph>
                        </GridContainer>
                    </form>
                </GridSubContainer>
            ) : (
                <GridContainer>
                    <UpgradeButtonWrapper>
                        <Button
                            secondary
                            onClick={(): void => setIsFormShown(true)}
                        >
                            Upgrade order
                        </Button>
                    </UpgradeButtonWrapper>
                </GridContainer>
            )}
        </>
    );
}
