import React from 'react';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import {
    hyperlinkLighterStyle,
    blogAndInUseWrapStyles,
} from './BlogPostStyles';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import { DEFAULT_VIDEO_ASPECT_RATIO, VIEWPORT } from '../settings/Global';
import { sentryMessage } from '../utils/sentry';
import { createEmbedVideoUrl, createStaticAssetUrl } from '../utils/urlHelper';
import LazyImage from './LazyImage';
import processWagtailRichText from '../utils/processWagtailRichText';
import { CMS_SSR_SingleImageLayoutChoice } from '../gql/api-ssr';
import useConfig from '../hooks/useConfig';
import type {
    BlogImageSectionBlock as ImageSectionBlock,
    BlogImageBlock as ImageBlock,
    BlogSimpleVideoCaptionBlock as SimpleVideoCaptionBlock,
} from '../union-types/blogPost';
import type {
    InUseSimpleImageSectionBlock as SimpleImageSectionBlock,
    InUseSimpleImageBlock as SimpleImageBlock,
    InUseSimpleVideoBlock as SimpleVideoBlock,
} from '../union-types/inUsePost';
import notNull from '../utils/notNull';
import { hyphensAuto } from '../utils/stylesMixins';
import { useGlobalState } from './GlobalRuntimeState';

const ASCII_LOWERCASE_LETTER_OFFSET = 97;

enum BackgroundColor {
    TRANSPARENT,
    GREY,
}

enum Layout {
    Inline,
    Medium,
    FullWidth,
}

enum Spacing {
    None,
    Normal,
    ColumnGap,
    ColumnGapHalf,
}

const Container = styled.div<{
    backgroundColor: BackgroundColor | string;
}>`
    ${({ backgroundColor }): FlattenSimpleInterpolation | undefined => {
        switch (backgroundColor) {
            case BackgroundColor.TRANSPARENT:
                return;
            case BackgroundColor.GREY:
                return css`
                    background-color: var(--foregroundColorMix8);
                `;
            default:
                // Specific hex colour value, validated in CMS
                return css`
                    background-color: ${backgroundColor};
                `;
        }
    }};

    --imageCaptionColor: ${({ backgroundColor }): string =>
        backgroundColor
            ? 'var(--foregroundColorMix5)'
            : 'var(--foregroundColorMix3)'};
`;

const ImagesContainer = styled.div<{
    $mode: Layout;
    $paddingTop: Spacing;
    $paddingBottom: Spacing;
    $numberOfImages: number;
    $noVerticalMargins: boolean | null;
}>`
    ${blogAndInUseWrapStyles};

    padding-top: ${({ $paddingTop }): string => {
        switch ($paddingTop) {
            case Spacing.None:
                return '0';
            case Spacing.ColumnGapHalf:
                return 'calc(var(--gridColumnGap) / 2)';
            case Spacing.ColumnGap:
                return 'var(--gridColumnGap)';
            case Spacing.Normal:
            default:
                return 'var(--streamFieldSpacing)';
        }
    }};

    padding-bottom: ${({ $paddingBottom }): string => {
        switch ($paddingBottom) {
            case Spacing.None:
                return '0';
            case Spacing.ColumnGapHalf:
                return 'calc(var(--gridColumnGap) / 2)';
            case Spacing.ColumnGap:
                return 'var(--gridColumnGap)';
            case Spacing.Normal:
            default:
                return 'var(--streamFieldSpacing)';
        }
    }};

    ${({ $mode, $numberOfImages }): FlattenSimpleInterpolation => {
        switch ($mode) {
            case Layout.Inline: {
                return css`
                    grid-template-areas: '. a a a a a a a a . . .';
                `;
            }
            case Layout.Medium: {
                return css`
                    grid-template-areas: '. a a a a a a a a a a .';
                `;
            }
            case Layout.FullWidth: {
                switch ($numberOfImages) {
                    case 2: {
                        return css`
                            grid-template-areas: 'a a a a a a b b b b b b';
                        `;
                    }
                    case 3: {
                        return css`
                            grid-template-areas: 'a a a a b b b b c c c c';
                        `;
                    }
                    case 4: {
                        return css`
                            grid-template-areas: 'a a a b b b c c c d d d';
                        `;
                    }
                }
                return css`
                    grid-template-areas: 'a a a a a a a a a a a a';
                `;
            }
        }
        return css`
            grid-template-areas: 'a a a a a a a a a a a a';
        `;
    }};

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        ${({ $mode }): FlattenSimpleInterpolation | undefined => {
            if ($mode === Layout.Medium) {
                return css`
                    grid-template-areas: '. a a a a a a a a a . .';
                `;
            }
        }}
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: ${({ $mode, $numberOfImages }): string => {
            switch ($mode) {
                case Layout.Medium: {
                    return `'a a a a a a'`;
                }
                case Layout.FullWidth: {
                    switch ($numberOfImages) {
                        case 2: {
                            return `
                                'a a a a a a'
                                'b b b b b b'`;
                        }
                        case 3: {
                            return `
                                'a a a a a a'
                                'b b b b b b'
                                'c c c c c c'`;
                        }
                        case 4: {
                            return `
                                'a a a a a a'
                                'b b b b b b'
                                'c c c c c c'
                                'd d d d d d'`;
                        }
                    }
                    return `'a a a a a a'`;
                }
            }
            return `'a a a a a a'`;
        }};

        grid-row-gap: ${({ $noVerticalMargins }): string =>
            $noVerticalMargins ? '0' : 'var(--gridColumnGap)'};
    }
`;

const ImageColumn = styled.div<{ gridArea: string }>`
    grid-area: ${({ gridArea }): string => gridArea};
`;

const ImageHeadingContainer = styled.div`
    ${blogAndInUseWrapStyles};

    padding-top: var(--streamFieldSpacing);
    padding-bottom: var(--lineHeightBlogBodyPx);

    grid-template-areas: '. . a a a a a a . . . .';

    @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
        grid-template-areas: '. . a a a a a . . . . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-areas: '. a a a a a a a . . . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const ImageHeading = styled.h3<{
    headingColor: string | null;
}>`
    grid-area: a;
    font-weight: bold;
    font-size: 26px;
    color: ${({ headingColor }): string =>
        headingColor ? headingColor : 'var(--secondaryColorRef)'};
    margin: 0;
    padding: 0;

    ${hyphensAuto};
`;

const ImageCaption = styled.figcaption<{
    captionColor: string | null | undefined;
}>`
    margin-top: var(--spacing1);
    margin-bottom: var(--spacing0);
    font-size: var(--fontSizeFixed1);
    color: ${({ captionColor }): string =>
        captionColor ? captionColor : 'var(--imageCaptionColor)'};

    ${hyphensAuto};

    b {
        font-weight: bold;
    }
    i {
        font-style: italic;
    }
    a {
        ${hyperlinkLighterStyle}
    }
`;

const ImageSvg = styled(LazyImage)`
    display: block;
    position: relative;
    max-width: 100%;
`;

function SingleImage({
    image,
    svg,
    index,
    caption,
    captionColor,
    lazyLoad,
}: {
    image: {
        width: number;
        height: number;
        id: string | null;
        title: string;
        file: string;
        placeholderColor: string | null;
        hasAlpha: boolean | null;
    } | null;
    svg: {
        file: string;
    } | null;
    index: number;
    caption: string | null;
    captionColor: string | null;
    lazyLoad?: boolean;
}): React.ReactElement | null {
    const config = useConfig();
    return (
        <ImageColumn
            gridArea={String.fromCharCode(
                ASCII_LOWERCASE_LETTER_OFFSET + index,
            )}
        >
            {image ? (
                <ImageWithPlaceholder
                    src={image.file}
                    aspectRatio={image.width / image.height}
                    transparent
                    lazyLoad={lazyLoad}
                    placeholderColor={
                        !image.hasAlpha ? image.placeholderColor : undefined
                    }
                    fetchPriority={!lazyLoad ? 'high' : 'low'}
                />
            ) : (
                svg && (
                    <ImageSvg
                        src={createStaticAssetUrl(svg.file)}
                        loading={lazyLoad ? 'lazy' : undefined}
                        fetchPriority={!lazyLoad ? 'high' : 'low'}
                    />
                )
            )}
            {caption && (
                <ImageCaption
                    captionColor={captionColor}
                    dangerouslySetInnerHTML={{
                        __html: processWagtailRichText(
                            caption,
                            config.customOpentypeHtmlTagNames,
                        ),
                    }}
                />
            )}
        </ImageColumn>
    );
}

const VideoWrapper = styled.div<{ videoAspectRatioPaddingTop: number }>`
    position: relative;
    width: 100%;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        padding-top: ${({ videoAspectRatioPaddingTop }): string =>
            `${videoAspectRatioPaddingTop}%`};
    }
`;

const VideoIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
`;

function SingleVideo({
    index,
    limitVideoWidth,
    video,
    aspectRatio,
    caption,
    captionColor,
    lazyLoad,
}: {
    index: number;
    limitVideoWidth: boolean;
    video: string;
    aspectRatio: string | null;
    caption: string | null;
    captionColor: string | null;
    lazyLoad?: boolean;
}): React.ReactElement | null {
    const config = useConfig();
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [viewportHeight] = useGlobalState('viewportHeight');
    const forceShowVideoFullWidth = React.useMemo(
        () =>
            Boolean(
                viewportWidth &&
                    viewportHeight &&
                    viewportWidth <= viewportHeight,
            ),
        [viewportHeight, viewportWidth],
    );

    const [width, height] = (aspectRatio || DEFAULT_VIDEO_ASPECT_RATIO)
        .split(':')
        .map(Number);
    // Adapt to the video aspect ratio.
    // If we're not on mobile, and it's a square or portrait video
    // we constrain to a 16:9 frame to prevent the video showing too big.
    const videoAspectRatioPaddingTop =
        forceShowVideoFullWidth ||
        width > height ||
        (width <= height && !limitVideoWidth)
            ? (100 / width) * height
            : (100 / 16) * 9;

    return (
        <ImageColumn
            gridArea={String.fromCharCode(
                ASCII_LOWERCASE_LETTER_OFFSET + index,
            )}
        >
            <VideoWrapper
                videoAspectRatioPaddingTop={videoAspectRatioPaddingTop}
            >
                <VideoIframe
                    title="Video"
                    src={createEmbedVideoUrl(video, {
                        background: true,
                    })}
                    loading={lazyLoad ? 'lazy' : undefined}
                />
            </VideoWrapper>
            {caption && (
                <ImageCaption
                    captionColor={captionColor}
                    dangerouslySetInnerHTML={{
                        __html: processWagtailRichText(
                            caption,
                            config.customOpentypeHtmlTagNames,
                        ),
                    }}
                />
            )}
        </ImageColumn>
    );
}

export function StreamFieldImageSection({
    block,
    precedingImageSection,
    succeedingImageSection,
    lazyLoad,
}: {
    block: ImageSectionBlock | SimpleImageSectionBlock;
    precedingImageSection:
        | ImageSectionBlock
        | SimpleImageSectionBlock
        | undefined;
    succeedingImageSection:
        | ImageSectionBlock
        | SimpleImageSectionBlock
        | undefined;
    lazyLoad?: boolean;
}): React.ReactElement | null {
    const {
        content,
        noVerticalMargins,
        colorBackground,
        showBackground,
        singleImageLayout,
    } = block;

    const isSimpleImageSection =
        block.__typename === 'SimpleImageSectionBlock' ||
        block.__typename === 'CMS_SSR_SimpleImageSectionBlock';

    const blockHasHeading = Boolean(
        (block.__typename === 'ImageSectionBlock' ||
            block.__typename === 'CMS_SSR_ImageSectionBlock') &&
            block.heading,
    );
    const succeedingImageSectionHasHeading = Boolean(
        succeedingImageSection &&
            (succeedingImageSection.__typename === 'ImageSectionBlock' ||
                succeedingImageSection.__typename ===
                    'CMS_SSR_ImageSectionBlock') &&
            succeedingImageSection.heading,
    );

    const images: (
        | ImageBlock
        | SimpleImageBlock
        | SimpleVideoBlock
        | SimpleVideoCaptionBlock
    )[] =
        isSimpleImageSection && content
            ? (content as (SimpleImageBlock | SimpleVideoBlock)[]).filter(
                  notNull,
              )
            : content
            ? (content as (ImageBlock | SimpleVideoCaptionBlock)[]).filter(
                  notNull,
              )
            : [];

    const precedingImageSectionIsSimilar =
        precedingImageSection &&
        precedingImageSection.showBackground === showBackground &&
        precedingImageSection.colorBackground === colorBackground;

    const succeedingImageSectionIsSimilar =
        succeedingImageSection &&
        succeedingImageSection.showBackground === showBackground &&
        succeedingImageSection.colorBackground === colorBackground;

    const sectionPaddingTop =
        (!precedingImageSection && !showBackground) ||
        (precedingImageSection && noVerticalMargins) ||
        blockHasHeading
            ? Spacing.None
            : precedingImageSectionIsSimilar
            ? Spacing.ColumnGapHalf
            : isSimpleImageSection
            ? Spacing.ColumnGap
            : Spacing.Normal;

    const sectionPaddingBottom =
        (!succeedingImageSection && !showBackground) ||
        (succeedingImageSection &&
            noVerticalMargins &&
            succeedingImageSection.noVerticalMargins) ||
        (succeedingImageSectionIsSimilar && succeedingImageSectionHasHeading)
            ? Spacing.None
            : succeedingImageSectionIsSimilar
            ? Spacing.ColumnGapHalf
            : isSimpleImageSection
            ? Spacing.ColumnGap
            : Spacing.Normal;

    const mode =
        images.length > 1
            ? Layout.FullWidth
            : singleImageLayout === CMS_SSR_SingleImageLayoutChoice.inline
            ? Layout.Inline
            : singleImageLayout === CMS_SSR_SingleImageLayoutChoice.medium
            ? Layout.Medium
            : Layout.FullWidth;

    const backgroundColor =
        colorBackground && showBackground
            ? colorBackground
            : showBackground
            ? BackgroundColor.GREY
            : BackgroundColor.TRANSPARENT;

    const captionColor =
        block.__typename === 'ImageSectionBlock' ||
        block.__typename === 'CMS_SSR_ImageSectionBlock'
            ? block.colorText
            : null;

    return (
        <Container backgroundColor={backgroundColor}>
            {(block.__typename === 'ImageSectionBlock' ||
                block.__typename === 'CMS_SSR_ImageSectionBlock') &&
                block.heading && (
                    <ImageHeadingContainer>
                        <ImageHeading headingColor={block.colorText}>
                            {block.heading}
                        </ImageHeading>
                    </ImageHeadingContainer>
                )}
            <ImagesContainer
                $mode={mode}
                $paddingTop={sectionPaddingTop}
                $paddingBottom={sectionPaddingBottom}
                $numberOfImages={images.length}
                $noVerticalMargins={noVerticalMargins}
            >
                {images.map(
                    (
                        imageBlock,
                        index,
                        blockArray,
                    ): React.ReactElement | null => {
                        if (
                            imageBlock.__typename === 'ImageBlock' ||
                            imageBlock.__typename === 'CMS_SSR_ImageBlock' ||
                            imageBlock.__typename === 'SimpleImageBlock' ||
                            imageBlock.__typename === 'CMS_SSR_SimpleImageBlock'
                        ) {
                            if (!imageBlock.image && !imageBlock.svg) {
                                sentryMessage(
                                    `No image or svg for img (${JSON.stringify(
                                        imageBlock,
                                    )}).`,
                                );
                                return null;
                            }

                            const caption =
                                imageBlock.__typename === 'ImageBlock' ||
                                imageBlock.__typename === 'CMS_SSR_ImageBlock'
                                    ? imageBlock.caption
                                    : null;

                            return (
                                <SingleImage
                                    key={`sfi-${index}`}
                                    index={index}
                                    svg={imageBlock.svg}
                                    image={imageBlock.image}
                                    caption={caption}
                                    captionColor={captionColor}
                                    lazyLoad={lazyLoad}
                                />
                            );
                        } else if (
                            imageBlock.__typename === 'SimpleVideoBlock' ||
                            imageBlock.__typename ===
                                'CMS_SSR_SimpleVideoBlock' ||
                            imageBlock.__typename ===
                                'SimpleVideoCaptionBlock' ||
                            imageBlock.__typename ===
                                'CMS_SSR_SimpleVideoCaptionBlock'
                        ) {
                            if (!imageBlock.video) {
                                sentryMessage(
                                    `No video for (${JSON.stringify(
                                        imageBlock,
                                    )}).`,
                                );
                                return null;
                            }

                            const caption =
                                imageBlock.__typename ===
                                    'SimpleVideoCaptionBlock' ||
                                imageBlock.__typename ===
                                    'CMS_SSR_SimpleVideoCaptionBlock'
                                    ? imageBlock.caption
                                    : null;

                            return (
                                <SingleVideo
                                    key={`sfv-${index}`}
                                    index={index}
                                    limitVideoWidth={
                                        blockArray.length === 1 &&
                                        mode === Layout.FullWidth
                                    }
                                    video={imageBlock.video}
                                    aspectRatio={imageBlock.videoAspectRatio}
                                    caption={caption}
                                    captionColor={captionColor}
                                    lazyLoad={lazyLoad}
                                />
                            );
                        }
                        return null;
                    },
                )}
            </ImagesContainer>
        </Container>
    );
}
