import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { useCustomFont, useStaticFontMetricOffsets } from './PageContext';
import type { CustomFont } from './PageContext';
import { HeadingSmall } from './Heading';
import { VIEWPORT } from '../settings/Global';
import _LazyResponsiveImage from './LazyResponsiveImage';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

type CustomFontImage = CustomFont['images'][number];

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing5);
`;

const Title = styled.div``;

const Inner = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gridColumnGap);
    grid-auto-flow: row dense;
`;

const Item = styled.div<{ $fullWidth: boolean }>`
    ${({ $fullWidth }): FlattenSimpleInterpolation =>
        $fullWidth
            ? css`
                  grid-column: span 2;
              `
            : css`
                  grid-column: span 1;
                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      grid-column: span 2;
                  }
              `};
`;

const LazyResponsiveImage = styled(_LazyResponsiveImage)`
    display: block;
    width: 100%;
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
`;

function sortImagesFn(a: CustomFontImage, b: CustomFontImage): number {
    return (
        // Sort by sort order (ascending)
        a.sortOrder - b.sortOrder
    );
}

function CustomFontsPostImages(): React.ReactElement {
    const { name, slug, images } = useCustomFont();
    const fontMetricOffsets = useStaticFontMetricOffsets();

    const title = React.useMemo(() => {
        return ['hokotohu', 'pure-pakati'].includes(slug!)
            ? `Process`
            : `${name} in use`;
    }, [slug]);

    return (
        <Container>
            <Title>
                <HeadingSmall $metricOffsets={fontMetricOffsets}>
                    {title}
                </HeadingSmall>
            </Title>
            <Inner>
                {images.sort(sortImagesFn).map(
                    (image): React.ReactElement => (
                        <Item key={image.id} $fullWidth={image.feature}>
                            <LazyResponsiveImage
                                sources={[{ src: image.image }]}
                                src={image.image}
                            />
                        </Item>
                    ),
                )}
            </Inner>
        </Container>
    );
}

export default CustomFontsPostImages;
