import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import FontHero from '../components/FontHero';
import { VIEWPORT } from '../settings/Global';
import type { FontFamily, FontFamilyGroup } from './PageContext';
import { hideFromPrint } from '../utils/stylesMixins';
import Campaign from './Campaign';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

export const Container = styled.div`
    display: block;
    max-width: 100%;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) and (orientation: portrait) {
        width: 100%;
        height: auto;
    }
`;
export const FontHeroContainer = styled.div`
    position: relative;
    margin: 0 var(--gridMarginGap);
`;

const CampaignContainer = styled.div<{ $svgHeadingOverlay?: boolean }>`
    margin-top: var(--navbarHeight);
    margin-left: var(--gridMarginGap);
    margin-right: var(--gridMarginGap);
    overflow: hidden;
    width: calc(100% - (var(--gridMarginGap) * 2));
    --viewportHeight: 100vh;
    /* stylelint-disable-next-line declaration-block-no-duplicate-custom-properties */
    --viewportHeight: 100svh;
    height: calc(
        var(--viewportHeight) - var(--navbarHeight) - var(--gridMarginGap)
    );
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    pointer-events: none;
    ${hideFromPrint};

    ${({ $svgHeadingOverlay }): FlattenSimpleInterpolation | null =>
        $svgHeadingOverlay
            ? css`
                  position: absolute;
                  top: 0;
                  left: 0;
              `
            : null};
`;

function FontHeroWithWrapper({
    svgHeading,
    svgTitle,
    bitmapHeading,
    campaign,
}: {
    bitmapHeading?: string | null;
    svgHeading: string | null | undefined;
    svgTitle: string | undefined;
    campaign?: FontFamilyGroup['campaign'] | FontFamily['campaign'];
}): React.ReactElement {
    return (
        <Container>
            {campaign && (
                <CampaignContainer
                    $svgHeadingOverlay={campaign?.svgHeadingOverlay}
                >
                    <Campaign
                        campaign={campaign}
                        adjustForBrowserChrome
                        withinPageMargins
                    />
                </CampaignContainer>
            )}
            {Boolean(svgHeading || bitmapHeading) && (
                <FontHeroContainer>
                    <FontHero
                        bitmap={bitmapHeading}
                        svg={svgHeading}
                        title={svgTitle || ''}
                        showAsHero={!campaign || campaign.svgHeadingOverlay}
                    />
                </FontHeroContainer>
            )}
        </Container>
    );
}

export default React.memo(FontHeroWithWrapper);
