import { GTAG_AFFILIATION, GTAG_KLIM_BRAND } from '../settings/Global';
import type { Cart } from '../hooks/useCartQuery';
import getLicenceQuantity from './getLicenceQuantity';
import isBrowser from './isBrowser';
import type {
    CurrencyChoices,
    OrderFromPaymentIntentQueryQuery,
} from '../gql/api-public';
import type { CMS_SSR_CurrencyChoices } from '../gql/api-ssr';

type Order = NonNullable<
    OrderFromPaymentIntentQueryQuery['orderFromPaymentIntent']
>;

/**
 * Returns an array of Gtag.Item instances (with loose object typing, because the types are out of date...)
 */
function getGtagItems(cart: Cart): Record<string, unknown>[] {
    return cart.items.map((item): Record<string, unknown> => {
        // Use a font ID if available, otherwise use the LineItemType
        const sku = item.font?.fontId ? item.font.fontId : item.type;
        const quantity =
            (item.fontLicenceType?.id &&
                getLicenceQuantity(item.fontLicenceType.id, cart)) ||
            1;
        // TODO: Apply discounts etc.
        return {
            id: sku,
            name: item.fontDescription || item.type,
            brand: GTAG_KLIM_BRAND,
            category: item.font?.fontProductType
                ? item.font.fontProductType
                : item.type,
            variant: item.fontLicenceType ? item.fontLicenceType : undefined,
            quantity: quantity,
            price: item.price ? item.price / quantity : 0,
        };
    });
}

export function trackEvent(
    eventName: Gtag.EventNames | string,
    eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
): void {
    if (!isBrowser()) {
        return;
    }
    if (
        typeof window.gtag === 'undefined' &&
        process.env.NODE_ENV !== 'production'
    ) {
        // Log
        console.log('gtag event', eventName, eventParams);
        return;
    }

    // Track
    window.gtag('event', eventName, eventParams);
}

export function trackClick(contentType: string, contentId: string): void {
    trackEvent('select_content', {
        content_id: contentId,
        content_type: contentType,
    });
}

export function trackPurchase(
    order: Order,
    currency: CurrencyChoices | CMS_SSR_CurrencyChoices,
): void {
    trackEvent('purchase', {
        transaction_id: order.orderNumber,
        affiliation: GTAG_AFFILIATION,
        value: order.cart.amountTotalUsd,
        currency: currency,
        tax: order.cart.amountTaxUsd,
        shipping: 0,
        items: getGtagItems(order.cart),
    });
}
