import React from 'react';
import { css, createGlobalStyle } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import getSafeCssStringValue from '../utils/getSafeCssStringValue';

export interface Font {
    fontFamily: string;
    fontStyle?: 'normal' | 'italic';
    fontWeight?: number | 'normal' | 'bold';
    woff2: string;
}

/* WOFF2: Chrome 26+, Opera 23+, Firefox 39+ */
export const getSrc = ({ woff2 }: { woff2: string }): string =>
    `url('${woff2}') format('woff2')`;

const getFontFace = (
    { fontFamily, fontStyle = 'normal', fontWeight = 'normal', woff2 }: Font,
    preventFallbackFont?: boolean,
): FlattenSimpleInterpolation => css`
    @font-face {
        font-family: ${getSafeCssStringValue(
            getCssFontFamilyNameFromId(fontFamily),
        )};
        font-style: ${String(fontStyle)};
        font-weight: ${fontWeight ? String(fontWeight) : 'normal'};
        src: ${getSrc({ woff2 })};
        font-display: ${preventFallbackFont ? 'block' : 'swap'};
    }
`;

interface FontFaceProps {
    fonts: Font[];
    preventFallbackFont?: boolean;
}

const getFontFaceStack = (props: FontFaceProps): FlattenSimpleInterpolation[] =>
    props.fonts.map(
        (font: Font): FlattenSimpleInterpolation =>
            getFontFace(font, props.preventFallbackFont),
    );

const FontFace = createGlobalStyle<FontFaceProps>`${(
    props,
): FlattenSimpleInterpolation => getFontFaceStack(props)}`;

export default React.memo(FontFace);
