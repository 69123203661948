import React from 'react';
import { HeadingSmall } from './Heading';
import styled from 'styled-components';
import Disclosure, { DisclosureParams } from './Disclosure';
import { default as _Icon, IconType } from './Icon';

const Icon = styled(_Icon)<{ expanded: boolean }>`
    font-size: var(--fontSizeFixed4);
    transform: rotate(
        ${(props: { expanded: boolean }): string =>
            props.expanded ? '45deg' : '0deg'}
    );
`;

export const Accordion = styled.div`
    display: grid;
    grid-template-columns: 100%;
    --rowBorder: 1px solid var(--borderColor);

    &:last-child {
        border-bottom: 1px solid var(--borderColor);
    }
`;

const Heading = styled(HeadingSmall)`
    font-size: var(--fontSizeFixed2);
    -webkit-tap-highlight-color: transparent;
`;

export const Button = styled.div<{ compact?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: var(--rowBorder);
    text-align: left;
    color: var(--foregroundColor);
    line-height: var(--lineHeightHeading2);
    padding: ${({ compact }): string =>
            compact ? 'var(--spacing2)' : 'var(--spacing3)'}
        0;
    margin: 0;
`;

const Panel = styled.div<{ compact?: boolean }>`
    margin: var(--spacing1) 0;
    padding-bottom: ${({ compact }): string =>
        compact ? 'var(--spacing2)' : 'var(--spacing5)'};
`;

export function AccordionItem({
    heading,
    headingLevel,
    panel,
    icon,
    compact,
    hidePanelUnlessExpanded,
}: {
    heading?: React.ReactNode;
    headingLevel: number;
    panel: React.ReactNode;
    icon?: React.ReactNode;
    compact?: boolean;
    hidePanelUnlessExpanded?: boolean;
}): React.ReactElement {
    return (
        <Disclosure>
            {({
                expanded,
                buttonAttributes,
                panelAttributes,
            }: DisclosureParams): React.ReactElement => (
                <>
                    <Heading role="heading" aria-level={headingLevel}>
                        <Button
                            {...buttonAttributes}
                            as={panel ? 'button' : 'span'}
                            compact={compact}
                        >
                            <span>{heading}</span>
                            {icon || (
                                <Icon
                                    type={IconType.PLUS}
                                    expanded={Boolean(expanded)}
                                />
                            )}
                        </Button>
                    </Heading>

                    <Panel {...panelAttributes} compact={compact}>
                        {!hidePanelUnlessExpanded || expanded ? panel : null}
                    </Panel>
                </>
            )}
        </Disclosure>
    );
}
