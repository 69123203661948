import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import { LineItemType } from '../gql/api-public';
import { CMS_SSR_LicenceTypeId } from '../gql/api-ssr';
import type { Cart, Item } from '../hooks/useUserOrdersQuery';
import getGroupedLineItems from '../utils/getGroupedLineItems';
import AccountCartLineItem from './AccountCartLineItem';
import {
    GridContainer,
    GridLeftColumn,
    GridMainColumn,
} from './AccountOrderElements';
import useActiveLicenceTypes, {
    LicenceType,
} from '../hooks/useActiveLicenceTypes';

const Container = styled.div`
    grid-column: 1 / -1;
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-bottom: var(--spacing2);
    }
`;

const LineItemContainer = styled(GridContainer)`
    color: var(--foregroundColorMix4);
    margin: var(--spacing6) 0 var(--spacing5) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin: var(--spacing4) 0 var(--spacing5) 0;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin: var(--spacing2) 0 0 0;
    }
`;

const LicenceNameContainer = styled(GridLeftColumn)<{
    allowGridRowToGrow?: boolean;
}>`
    height: ${({ allowGridRowToGrow }): string =>
        allowGridRowToGrow ? 'auto' : '0'};
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: none;
    }
`;

const LicenceNameContainerMobile = styled(GridLeftColumn)`
    display: none;
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: block;
        margin: var(--spacing5) 0 var(--spacing2) 0;
    }
`;

const AccountCartLineItemContainer = styled(GridMainColumn)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const LicenceDetailTitle = styled(GridLeftColumn)`
    margin: var(--spacing1) 0;
    color: var(--foregroundColorMix4);
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column-end: span 3;
    }
`;

const LicenceDetailValue = styled(GridMainColumn)`
    margin: var(--spacing1) 0;
    color: var(--foregroundColor);
    word-wrap: break-word;
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 4 / -1;
    }
`;

function formatDomain(domain: string | null): string {
    if (!domain) {
        return '';
    }
    const domainName = domain.replace(/^https?:\/\//, '');
    return domainName.endsWith('/') ? domainName.slice(0, -1) : domainName;
}

function getLicenceTierName(
    cart: Cart,
    licenceType?: LicenceType,
): string | undefined {
    if (!licenceType) {
        return;
    }
    // UDLA doesn't have tiers, really.
    if (licenceType.id === CMS_SSR_LicenceTypeId.UDLA) {
        return;
    }
    const cartTier = cart.licenceTiers.find(
        (cartTier) => cartTier.tier.licenceType.id === licenceType.id,
    );
    // We don't want a tier name for unlimited licensing here, either
    if (!cartTier || cartTier.tier.isUnlimited) {
        return;
    }
    return cartTier.tier.title;
}

function createLicenceName(
    heading: string,
    licenceTypeName?: string,
    mobile?: boolean,
): React.ReactElement | null {
    return (
        <>
            {!licenceTypeName ? <br /> : null}
            {heading}
            {licenceTypeName ? (
                <>
                    {mobile ? ': ' : <br />} {licenceTypeName}
                </>
            ) : undefined}
        </>
    );
}

const LineItemGroup = React.memo(
    ({
        cart,
        licenceType,
        lineItemType,
    }: {
        cart: Cart;
        licenceType?: LicenceType;
        lineItemType?: LineItemType;
        licenceQuantitySelector?: React.ReactNode;
    }): React.ReactElement | null => {
        const groupedLineItems = getGroupedLineItems({
            items: cart.items,
            licenceType,
            lineItemType,
        });
        if (!groupedLineItems || !groupedLineItems.items.length) {
            return null;
        }
        const { items, name: heading } = groupedLineItems;

        // If there's a licence type then only grab the tier related to it, otherwise grab all
        const cartTiers = cart.licenceTiers.filter((cartTier) =>
            licenceType
                ? cartTier.tier.licenceType.id === licenceType.id
                : true,
        );

        return (
            <LineItemContainer>
                {cart.hasSimpleLicensing ? null : (
                    <>
                        <LicenceNameContainer
                            allowGridRowToGrow={items.length === 1}
                        >
                            {createLicenceName(
                                heading,
                                getLicenceTierName(cart, licenceType),
                                false,
                            )}
                        </LicenceNameContainer>
                        <LicenceNameContainerMobile>
                            {createLicenceName(
                                heading,
                                getLicenceTierName(cart, licenceType),
                                true,
                            )}
                        </LicenceNameContainerMobile>
                    </>
                )}
                {items.map(
                    (item: Item, index: number): React.ReactElement => (
                        <AccountCartLineItemContainer key={item.id}>
                            <AccountCartLineItem
                                item={item}
                                isFirstInList={index === 0}
                            />
                        </AccountCartLineItemContainer>
                    ),
                )}
                {cartTiers.map((cartTier) => {
                    return cartTier.licenceSubject ? (
                        <React.Fragment key={`subj${cartTier.tier.id}`}>
                            <LicenceDetailTitle>
                                {cartTier.tier.licenceType.licenceSubjectLabel}
                            </LicenceDetailTitle>
                            <LicenceDetailValue>
                                {cartTier.tier.licenceType.id ===
                                CMS_SSR_LicenceTypeId.WEB
                                    ? formatDomain(cartTier.licenceSubject)
                                    : cartTier.licenceSubject}
                            </LicenceDetailValue>
                        </React.Fragment>
                    ) : cartTier.tier.hasContractDetailsField ? (
                        <React.Fragment key={`contr${cartTier.tier.id}`}>
                            <LicenceDetailTitle>
                                {cartTier.tier.licenceType.contractDetailsLabel}
                            </LicenceDetailTitle>
                            <LicenceDetailValue>
                                {cartTier.contractDetails ||
                                    'Signature pending.'}
                            </LicenceDetailValue>
                        </React.Fragment>
                    ) : null;
                })}
            </LineItemContainer>
        );
    },
);
LineItemGroup.displayName = 'LineItemGroup';

interface AccountCartInventoryProps {
    cart: Cart;
}

function AccountCartInventory({
    cart,
}: AccountCartInventoryProps): React.ReactElement {
    const activeLicenceTypes = useActiveLicenceTypes();
    return (
        <Container>
            {activeLicenceTypes.map((licenceType) => {
                return (
                    <LineItemGroup
                        key={`lig-${licenceType.id}`}
                        cart={cart}
                        licenceType={licenceType}
                    />
                );
            })}

            <LineItemGroup cart={cart} lineItemType={LineItemType.CUSTOM} />
            <LineItemGroup cart={cart} lineItemType={LineItemType.GOODS} />
            <LineItemGroup cart={cart} lineItemType={LineItemType.OTHER} />
        </Container>
    );
}

export default React.memo(AccountCartInventory);
