import React from 'react';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { HeadingSmall } from './Heading';
import { GLYPH_INSPECTOR_BREAKPOINT, VIEWPORT } from '../settings/Global';

interface ContainerProps {
    hideOnTablet?: boolean;
    applyBaselineAlignment?: boolean;
}

const Container = styled.div<ContainerProps>`
    display: grid;

    ${({ applyBaselineAlignment }): FlattenSimpleInterpolation | null => {
        /*
        Massive performance issues in Firefox and Safari for Specimen sections
        with a lot of text content... So only apply baseline alignment to sections
        very sparingly, where needed.
         */
        return applyBaselineAlignment
            ? css`
                  align-items: baseline;
              `
            : null;
    }};
    width: 100%;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-row-gap: var(--spacing5);
    padding-top: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing5);

        ${({ hideOnTablet }): FlattenSimpleInterpolation | null => {
            return hideOnTablet
                ? css`
                      display: none;
                  `
                : null;
        }}
    }

    /* we are stacking rows on top, no gap needed */
    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        grid-row-gap: 0;
    }
`;

const Title = styled(HeadingSmall)<{
    stacked: boolean | undefined;
}>`
    ${({ stacked }): FlattenSimpleInterpolation =>
        stacked
            ? css`
                  grid-column: 1 / span var(--gridColumnCount);
              `
            : css`
                  grid-column: 1 / span 2;
              `};

    margin-top: calc(-1 * var(--scrollOffset));
    padding-top: var(--scrollOffset);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span var(--gridColumnCount);
        margin-bottom: var(--spacing4);
    }
`;

const Content = styled.div<{
    stacked: boolean | undefined;
}>`
    position: relative;
    ${({ stacked }): FlattenSimpleInterpolation =>
        stacked
            ? css`
                  grid-column: 1 / span var(--gridColumnCount);
              `
            : css`
                  grid-column: 3 / span 10;
              `};
    min-width: 0; /* needed for Firefox to stop overflow */

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span var(--gridColumnCount);
    }

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        margin-top: 0;
    }
`;

function FontSectionTitled({
    title,
    content,
    stacked,
    styleOverride,
    ...rest
}: React.HTMLAttributes<HTMLDivElement> &
    ContainerProps & {
        title: string;
        content: React.ReactNode;
        stacked?: boolean;
        styleOverride?: FlattenSimpleInterpolation;
    }): React.ReactElement {
    return (
        <Container {...rest}>
            {title && <Title stacked={stacked}>{title}</Title>}
            <Content css={styleOverride} stacked={stacked}>
                {content}
            </Content>
        </Container>
    );
}

export default React.memo(FontSectionTitled);
