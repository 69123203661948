import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '../components/Primitives';
import useCustomFontsStubs, {
    CustomFontsStub,
} from '../hooks/useCustomFontsStubs';
import FontsMenuSvgLink from '../components/FontsMenuSvgLink';
import { createCustomFontUrl, createCollectionUrl } from '../utils/urlHelper';
import useAllFonts, { FontFamilyGroup } from '../hooks/useAllFonts';
import notPreRelease from '../utils/notPreRelease';
import printStylesCount from '../utils/printStylesCount';
import sortByName from '../utils/sortByName';
import { MARGIN_SMALL } from '../settings/Global';
import { Container } from './FontsMenuPage';
import {
    Container as Row,
    useFontListItemColors,
    FullLink,
    Inner,
    SvgColumn,
    FamilyColumn,
    BuyColumn,
} from '../components/FontFamilyGroupsListItem';
import {
    darkColorScheme,
    lightColorScheme,
    SimpleColorScheme,
} from '../components/ColorSchemeContext';
import printAmpersandSeries from '../utils/printAmpersandSeries';

export { Head } from '../components/Head';

const MenuContainer = styled(Container)`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: ${MARGIN_SMALL}px;
`;

const textColumnStyles = css`
    /*
    Adding bespoke negative margin here so that the font sits right on the baseline (compared to the SVG).
    This is basically removes the line height from below the text.
    */
    margin-bottom: -4px;
`;

const ClientColumn = styled(FamilyColumn)`
    ${textColumnStyles};
`;

const StylesColumn = styled(BuyColumn)`
    ${textColumnStyles};
`;

function CustomFontListItem({
    customFont,
    colorScheme,
}: {
    customFont: CustomFontsStub | FontFamilyGroup;
    colorScheme: SimpleColorScheme;
}): React.ReactElement | null {
    const { expandedColorScheme, mixColor0_5, backgroundColor } =
        useFontListItemColors({ colorScheme });
    const { url, clientName, stylesCount } = React.useMemo(() => {
        const url =
            customFont.__typename === 'CMS_SSR_CustomFontInterface'
                ? createCustomFontUrl(customFont.slug || '')
                : createCollectionUrl(customFont.slug || '');

        const clientName =
            customFont.__typename === 'CMS_SSR_CustomFontInterface' &&
            customFont.creditClient ? (
                printAmpersandSeries(customFont.creditClient)
            ) : customFont.__typename === 'CMS_SSR_FontFamilyGroupInterface' ? (
                <Link to={createCollectionUrl(customFont.slug)}>
                    {customFont.name}
                </Link>
            ) : null;

        const stylesCount = printStylesCount(
            customFont.__typename === 'CMS_SSR_CustomFontInterface'
                ? customFont.styles.length
                : customFont.fontFamilies
                      .filter(notPreRelease)
                      .reduce(
                          (carry, fontFamily): number =>
                              carry + fontFamily.fontStyleCount,
                          0,
                      ),
        );

        return { url, clientName, stylesCount };
    }, [customFont]);

    if (!customFont.svgNavigation) {
        return null;
    }

    return (
        <Row
            $colorScheme={expandedColorScheme}
            $backgroundColor={backgroundColor}
            $mixColor0_5={mixColor0_5}
        >
            <FullLink to={url} />
            <Inner>
                <SvgColumn>
                    <FontsMenuSvgLink
                        svg={customFont.svgNavigation}
                        title={customFont.name}
                        to={url}
                        isCustomFont
                    />
                </SvgColumn>
                <ClientColumn>{clientName}</ClientColumn>
                <StylesColumn>{stylesCount}</StylesColumn>
            </Inner>
        </Row>
    );
}

function CustomFontsMenuPage(): React.ReactElement {
    const previousColorScheme =
        React.useRef<SimpleColorScheme>(lightColorScheme);
    const customFontsStubs = useCustomFontsStubs().filter(notPreRelease);
    const fontFamilyGroupsStubs = useAllFonts().filter(
        (fontFamilyGroup): boolean =>
            fontFamilyGroup.showInCustomFontMenu &&
            fontFamilyGroup.isCollection,
    );

    const stubs = [...customFontsStubs, ...fontFamilyGroupsStubs]
        .sort(sortByName)
        .filter((stub) => stub.svgNavigation);

    return (
        <MenuContainer>
            {stubs.map((stub): React.ReactElement | null => {
                if (!stub.svgNavigation) {
                    return null;
                }
                /* Alternate default colour schemes, so we never get two black or white rows following each other */
                const backgroundColor =
                    stub.__typename === 'CMS_SSR_CustomFontInterface'
                        ? stub.backgroundColor
                        : stub.colorSchemes.length
                        ? stub.colorSchemes[0].backgroundColor
                        : darkColorScheme.backgroundColor;
                const fontColorScheme: SimpleColorScheme | undefined =
                    backgroundColor !==
                    previousColorScheme.current.backgroundColor
                        ? stub.__typename === 'CMS_SSR_FontFamilyGroupInterface'
                            ? stub.colorSchemes[0]
                            : {
                                  foregroundColor: stub.foregroundColor,
                                  backgroundColor: stub.backgroundColor,
                                  highlightColor: stub.highlightColor,
                              }
                        : undefined;
                const defaultColorScheme: SimpleColorScheme =
                    previousColorScheme.current.backgroundColor ===
                    lightColorScheme.backgroundColor
                        ? darkColorScheme
                        : lightColorScheme;
                previousColorScheme.current =
                    fontColorScheme || defaultColorScheme;
                return (
                    <CustomFontListItem
                        key={stub.id}
                        customFont={stub}
                        colorScheme={previousColorScheme.current}
                    />
                );
            })}
        </MenuContainer>
    );
}

export default React.memo(CustomFontsMenuPage);
