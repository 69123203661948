import React from 'react';
import styled from 'styled-components';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import Button from './Button';
import FieldText from './FieldText';
import Fieldset from './Fieldset';
import { FieldCheckbox } from './FieldRadioCheckbox';
import FieldInputGroup from './FieldInputGroup';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import { createEulaUrl } from '../utils/urlHelper';
import {
    GA_EVENT_NAME_DOWNLOAD_TEST_FONTS,
    VIEWPORT,
} from '../settings/Global';
import type {
    TestFontsDownloadMutationMutation,
    TestFontsDownloadMutationMutationVariables,
} from '../gql/api-public';
import { testFontsDownloadMutation } from '../utils/runtimeQueries';
import { sentryException } from '../utils/sentry';
import notNull from '../utils/notNull';
import { trackEvent } from '../utils/googleEvents';
import { LicenceTypeId } from '../gql/api-public';
import { useErrorOverlayState } from './GlobalRuntimeState';
import { TEST_ID } from '../settings/E2e';

const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing4);
    margin-top: var(--spacing6);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-row-gap: var(--spacing6);
    }
`;

const Fields = styled.div`
    display: grid;
    grid-template-columns: calc(50% - (var(--gridColumnGap) / 2));
    grid-column-gap: var(--gridColumnGap);
    grid-row-gap: var(--spacing3);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: 100%;
    }
`;

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: var(--gridColumnGap);

    ${Button} {
        grid-column: 1 / span 2;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 3;
        }

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-column: 1 / span 6;
        }
    }
`;

interface FormValues {
    email?: string;
    tncsChecked: boolean;
    emailOptIn: boolean;
}

function TestFontsForm({
    setFormIsSubmitted,
    setDownloadUrl,
}: {
    setFormIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    setDownloadUrl: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const [doTestFontDownload] = useMutation<
        TestFontsDownloadMutationMutation,
        TestFontsDownloadMutationMutationVariables
    >(testFontsDownloadMutation);
    const {
        handleSubmit,
        reset,
        register,
        formState,
        setError,
        control,
        trigger,
    } = useForm<FormValues>({
        defaultValues: {
            tncsChecked: false,
            emailOptIn: true,
        },
    });
    const [, setErrorOverlayState] = useErrorOverlayState();
    const watchEmailOptIn = useWatch({
        control,
        name: 'emailOptIn',
    });

    React.useEffect(() => {
        if (!watchEmailOptIn) {
            trigger('email');
        }
    }, [watchEmailOptIn]);

    const onSubmit = async (formData: FormValues): Promise<void> => {
        try {
            const result = await doTestFontDownload({
                variables: {
                    input: {
                        email: formData.email,
                        emailOptIn: formData.emailOptIn,
                    },
                },
            });

            const errors =
                result?.data?.testFontsDownload?.errors?.filter(notNull) || [];

            if (errors.length > 0) {
                errors.forEach((error): void => {
                    setError(error.field as keyof FormValues, {
                        type: 'generic',
                        message: error.messages.pop(),
                    });
                });
                return;
            } else if (result?.data?.testFontsDownload?.downloadUrl) {
                trackEvent(GA_EVENT_NAME_DOWNLOAD_TEST_FONTS);
                setDownloadUrl(result.data.testFontsDownload.downloadUrl);
                window.location.href =
                    result.data.testFontsDownload.downloadUrl;
                setFormIsSubmitted(true);
                window.scrollTo({ top: 0 });
            } else {
                throw new Error(
                    `Problem generating test fonts download for ${JSON.stringify(
                        formData,
                    )}.`,
                );
            }
        } catch (e) {
            sentryException(e);
            setErrorOverlayState({ isShown: true });
        }

        reset();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            data-cy={TEST_ID.TEST_FONTS_FORM}
        >
            <Inner>
                <Fieldset legend="Your details">
                    <Fields>
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Email address"
                                    {...register('email', {
                                        required: watchEmailOptIn
                                            ? 'Email address is required in order to subscribe'
                                            : undefined,
                                        disabled: watchEmailOptIn === false,
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Email address must not exceed 255 characters',
                                        },
                                    })}
                                    isError={!!formState.errors.email}
                                />
                            }
                            error={formState.errors.email}
                        />
                        <FieldInputGroup
                            input={
                                <FieldCheckbox
                                    label="Subscribe to emails about new font releases"
                                    {...register('emailOptIn', {
                                        required: false,
                                    })}
                                    isError={!!formState.errors.emailOptIn}
                                />
                            }
                            error={formState.errors.emailOptIn}
                        />
                        <FieldInputGroup
                            input={
                                <FieldCheckbox
                                    label={
                                        <>
                                            I agree to the{' '}
                                            <InlineUnderlinedLink
                                                to={createEulaUrl(
                                                    LicenceTypeId.TEST,
                                                )}
                                            >
                                                Test Font Licence
                                            </InlineUnderlinedLink>
                                        </>
                                    }
                                    {...register('tncsChecked', {
                                        required: `You need to agree to the Test Font Licence`,
                                    })}
                                    isError={!!formState.errors.tncsChecked}
                                />
                            }
                            error={formState.errors.tncsChecked}
                        />
                    </Fields>
                </Fieldset>

                <ButtonContainer>
                    <Button
                        type="submit"
                        disabled={formState.isSubmitting || undefined}
                    >
                        {formState.isSubmitting
                            ? 'Downloading…'
                            : 'Download test fonts'}
                    </Button>
                </ButtonContainer>
            </Inner>
        </form>
    );
}

export default React.memo(TestFontsForm);
