import { gql as publicGql } from '@apollo/client';

export const cartFragment = publicGql`
    fragment CartFragment on CartInterface {
        id
        licenceTiers {
            id
            licenceSubject
            contractDetails
            tier {
                id
                title
                titleAbbrev
                amount
                multiplier
                isActive
                isUnlimited
                hasContractDetailsField
                hasLicenceSubjectField
                licenceType {
                    id
                    name
                    licenceSubjectLabel
                    contractDetailsLabel
                }
                tieredLicence {
                    id
                }
            }
        }
        items {
            id
            description
            fontDescription
            price
            unitPrice
            type
            upgradedOrderNumbers
            fontLicenceType {
                id
            }
            font {
                fontId
                fontProductType
                renderFontStyleId
                fontStyleIdsByFamilyId {
                    fontFamilyId
                    fontStyleIds
                }
                fontSizeMultiplier
                typographicRanking
            }
        }
        amountSubtotal
        amountSubtotalForFontsWithLicenceMultiplier
        amountSubtotalConverted
        amountTotal
        amountTotalUsd
        amountTax
        amountTaxUsd
        discounts {
            id
            type
            amount
            percentage
            description
        }
        exchangeRate
        taxRate
        currency
        emailOptIn
        userEmail
        billingName
        billingCompanyName
        billingAddress1
        billingAddress2
        billingCity
        billingState
        billingPostcode
        billingCountry
        billingVatNumber
        billingPurchaseOrder
        legacyBillingAddress
        licensedForSomeoneElse
        licensedToCompanyName
        licensedToAddress1
        licensedToAddress2
        licensedToCity
        licensedToState
        licensedToPostcode
        licensedToCountry
        licensedToVatNumber
        legacyLicensedTo
        isInvoice
        invoiceId
        invoiceNumber
        invoiceExtraDescription
        hasDownloadables
        usesNativeCurrency
        usesCurrencyConversion
        nativeCurrency
        hasSimpleLicensing
    }
`;

export const orderFragment = publicGql`
    fragment OrderFragment on OrderInterface {
        id
        orderNumber
        date
        cart {
            ...CartFragment
        }
        downloadUrl
        invoiceUrl
        activeUpgradeRequest {
            status
            details
        }
    }
    ${cartFragment}
`;

export const userFragment = publicGql`
    fragment UserFragment on UserInterface {
        id
        email
        fullName
        isAdmin
        canRevert
        emailOptIn
        billingCompanyName
        billingAddress1
        billingAddress2
        billingCity
        billingState
        billingPostcode
        billingCountry
        billingVatNumber
    }
`;

export const newCartMutation = publicGql`
    mutation NewCartMutation {
        newCart {
            ok
        }
    }
`;

export const orderUpgradeRequestMutation = publicGql`
    mutation OrderUpgradeRequestMutation(
        $input: OrderUpgradeRequestMutationInput!
    ) {
        orderUpgradeRequest(input: $input) {
            order {
                ...OrderFragment
            }
            errors {
                field
                messages
            }
        }
    }
    ${orderFragment}
`;

export const cartSetBillingCountryMutation = publicGql`
    mutation CartSetBillingCountryMutation(
        $input: CartSetBillingCountryMutationInput!
    ) {
        cartSetBillingCountry(input: $input) {
            cart {
                ...CartFragment
            }
        }
    }
    ${cartFragment}
`;

export const accountUserDetailsMutation = publicGql`
    mutation AccountUserDetailsMutation(
        $input: AccountUserDetailsMutationInput!
    ) {
        accountUserDetails(input: $input) {
            user {
                ...UserFragment
            }
            errors {
                field
                messages
            }
        }
    }
    ${userFragment}
`;

export const logoutMutation = publicGql`
    mutation LogoutMutation {
        logout {
            ok
        }
    }
`;

export const revertLoginMutation = publicGql`
    mutation RevertLoginMutation {
        revertLogin {
            ok
            next
        }
    }
`;

export const loginWithCodeMutation = publicGql`
    mutation LoginWithCodeMutation($input: LoginWithCodeMutationInput!) {
        loginWithCode(input: $input) {
            user {
                ...UserFragment
            }
            errors {
                field
                messages
            }
        }
    }
    ${userFragment}
`;

export const requestLoginCodeMutation = publicGql`
    mutation RequestLoginCodeMutation(
        $input: RequestMagicLoginLinkMutationInput!
    ) {
        requestLoginCode(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`;

export const shareCartMutation = publicGql`
    mutation ShareCartMutation {
        shareCart {
            sharedCartId
        }
    }
`;

export const applySharedCartMutation = publicGql`
    mutation ApplySharedCartMutation($sharedCartId: ID!) {
        applySharedCart(sharedCartId: $sharedCartId) {
            cart {
                ...CartFragment
            }
        }
    }
    ${cartFragment}
`;

/*
 * DON'T ADD `user.orders`. See 'userOrdersQuery' definition for explanation.
 */
export const userQuery = publicGql`
    query UserQuery {
        user {
            ...UserFragment
        }
    }
    ${userFragment}
`;

/*
 * This was deliberately put into a separate query from 'UserQuery' because for
 * users with a lot of orders it can take a really long time (tens-of-seconds)
 * to resolve. Separating these queries means they can be loaded independently
 * of one another, so that user data can be rendered without having to wait for
 * full order history.
 */
export const userOrdersQuery = publicGql`
    query UserOrdersQuery($offset: Int!, $limit: Int!) {
        user {
            id
            orders(offset: $offset, limit: $limit) {
                ...OrderFragment
            }
        }
    }
    ${orderFragment}
`;

export const cartUpdateMutation = publicGql`
    mutation CartUpdateMutation(
        $fontProducts: [FontProductInput!]!
        $licenceTiers: [LicenceTierInput!]!
    ) {
        cartUpdate(fontProducts: $fontProducts, licenceTiers: $licenceTiers) {
            cart {
                ...CartFragment
            }
        }
    }
    ${cartFragment}
`;

export const cartQuery = publicGql`
    query CartQuery($invoiceNumber: String) {
        cart(invoiceNumber: $invoiceNumber) {
            ...CartFragment
        }
    }
    ${cartFragment}
`;

export const isInvoicePaidQuery = publicGql`
    query IsInvoicePaidQuery($invoiceNumber: String) {
        isInvoicePaid(invoiceNumber: $invoiceNumber)
    }
`;

export const invoiceNumberFromIdQuery = publicGql`
    query InvoiceNumberFromIdQuery($invoiceId: String!) {
        invoiceNumberFromId(invoiceId: $invoiceId)
    }
`;

export const publicConfigQuery = publicGql`
    query PublicConfigQuery {
        config {
            apiIsUp
        }
    }
`;

export const previewSpecimenSetQuery = publicGql`
    query PreviewSpecimenSetQuery($fontFamily: ID!, $specimenConfig: ID!) {
        previewSpecimenSet(
            fontFamily: $fontFamily
            specimenConfig: $specimenConfig
        ) {
            bigSpecimenSet {
                fontSize
                lineHeight
                letterSpacing
                specimensPerFontStyle
                fontStyles {
                    id
                    name
                    metricTtfUnitsPerEmHead
                    metricTtfCapHeightOs2
                    metricTtfAscentCalc
                    metricTtfAscentHhea
                    metricTtfDescentCalc
                    metricTtfDescentHhea
                    metricTtfLineGapHhea
                    words {
                        content
                        contentAscii
                        opentypeFeatures
                    }
                }
            }
            mediumSpecimenSet {
                fontSize
                lineHeight
                letterSpacing
                fixedOpentypeFeatures
                randomOpentypeFeatures
                randomOpentypeLikelihood
                specimensPerFontStyle
                sentences
                fontStyles {
                    name
                    romanStyleId
                    italicStyleId
                    boldStyleId
                    metricTtfUnitsPerEmHead
                    metricTtfCapHeightOs2
                    metricTtfAscentCalc
                    metricTtfAscentHhea
                    metricTtfDescentCalc
                    metricTtfDescentHhea
                    metricTtfLineGapHhea
                }
            }
            smallSpecimenSet {
                fontSize
                lineHeight
                letterSpacing
                columns
                fixedOpentypeFeatures
                randomOpentypeFeatures
                randomOpentypeLikelihood
                wordsInSmallCaps
                lineCount
                columnsSecondary
                fontSizeSecondary
                lineCountSecondary
                sentences
                fontStyles {
                    pixelSize
                    name
                    romanStyleId
                    italicStyleId
                    boldStyleId
                    metricTtfUnitsPerEmHead
                    metricTtfCapHeightOs2
                    metricTtfAscentCalc
                    metricTtfAscentHhea
                    metricTtfDescentCalc
                    metricTtfDescentHhea
                    metricTtfLineGapHhea
                }
            }
        }
    }
`;

export const newsletterSignupMutation = publicGql`
    mutation NewsletterSignupMutation($input: NewsletterSignupMutationInput!) {
        newsletterSignup(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`;

export const testFontsDownloadMutation = publicGql`
    mutation TestFontsDownloadMutation(
        $input: TestFontsDownloadMutationInput!
    ) {
        testFontsDownload(input: $input) {
            errors {
                field
                messages
            }
            downloadUrl
        }
    }
`;

export const cartGetStripeClientSecret = publicGql`
    mutation CartGetStripeClientSecret($invoiceNumber: String) {
        cartGetStripeClientSecret(invoiceNumber: $invoiceNumber) {
            invoiceIsPaid
            clientSecret
            cart {
                ...CartFragment
            }
        }
    }
    ${cartFragment}
`;

export const cartSetCheckoutDetailsMutation = publicGql`
    mutation CartSetCheckoutDetails(
        $extraTierFields: [TierExtraFieldInput!]!
        $input: CartSetCheckoutDetailsMutationInput!
        $invoiceNumber: String
    ) {
        cartSetExtraTierFields(
            extraTierFields: $extraTierFields
            invoiceNumber: $invoiceNumber
        ) {
            errors {
                licenceTypeId
                error {
                    field
                    messages
                }
            }
        }
        cartSetCheckoutDetails(input: $input) {
            cart {
                ...CartFragment
            }
            errors {
                field
                messages
            }
        }
    }
    ${cartFragment}
`;

export const orderFromPaymentIntentQuery = publicGql`
    query OrderFromPaymentIntentQuery($paymentIntentId: ID!) {
        orderFromPaymentIntent(paymentIntentId: $paymentIntentId) {
            status
            orderNumber
            downloadUrl
            account {
                user {
                    ...UserFragment
                }
            }
            cart {
                ...CartFragment
            }
        }
    }
    ${userFragment}
    ${cartFragment}
`;
