import React from 'react';
import styled from 'styled-components';
import ImageFullBleed, { ImageMeta } from './ImageFullBleed';
import FragmentLink from './FragmentLink';
import { hideFromPrint } from '../utils/stylesMixins';
import type { BlogHeroImageBlock } from '../union-types/blogPost';
import usePreviewOrPageQueryBlogPost from '../hooks/usePreviewOrPageQueryBlogPost';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const HeroContainer = styled.div`
    margin: var(--navbarHeight) var(--gridMarginGap) var(--gridMarginGap)
        var(--gridMarginGap);
    overflow: hidden;
    width: calc(100% - (var(--gridMarginGap) * 2));
    --viewportHeight: 100vh;
    /* stylelint-disable-next-line declaration-block-no-duplicate-custom-properties */
    --viewportHeight: 100svh;
    height: calc(
        var(--viewportHeight) - var(--navbarHeight) - var(--gridMarginGap)
    );
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    pointer-events: none;

    ${hideFromPrint};
`;

const SkipToIntro = styled(FragmentLink)`
    position: absolute;
    top: 0;
    left: 0;

    margin-top: 50vh;
    max-width: 100%;
    width: 100vw;
    height: 50vh;

    color: transparent;
    font-size: 1px;
`;

export function BlogPostHero({
    heroImage,
}: {
    heroImage: BlogHeroImageBlock;
}): React.ReactElement {
    const blogPost = usePreviewOrPageQueryBlogPost();

    const createImageObject = (image: {
        file: string;
        width?: number;
        height?: number;
    }): ImageMeta => ({
        src: image.file,
        width: image.width,
        height: image.height,
    });
    const landscapeImage = createImageObject(
        heroImage && heroImage.landscapeSvg
            ? heroImage.landscapeSvg
            : heroImage && heroImage.landscapeImage
            ? heroImage.landscapeImage
            : blogPost.blurbImage,
    );

    const portraitImage = createImageObject(
        heroImage && heroImage.portraitSvg
            ? heroImage.portraitSvg
            : heroImage && heroImage.portraitImage
            ? heroImage.portraitImage
            : blogPost.blurbImage,
    );

    return (
        <HeroContainer>
            <ImageFullBleed
                landscapeImage={landscapeImage}
                portraitImage={portraitImage}
                preload
                fetchPriority="high"
                withinPageMargins
            />
            <SkipToIntro href="#intro">Skip to content</SkipToIntro>
        </HeroContainer>
    );
}
