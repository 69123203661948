import React from 'react';
import styled from 'styled-components';
import { Link } from '../components/Button';
import {
    BUY_PAGE_FONTS_ID,
    BUY_PAGE_LICENCES_ID,
    VIEWPORT,
} from '../settings/Global';
import { HeadingExtraLarge, HeadingLarge } from '../components/Heading';
import LicenceSelector from '../components/LicenceSelector';
import FontSelector from '../components/FontSelector';
import InlineUnderlinedLink from '../components/InlineUnderlinedLink';
import { useFontFamilyGroup } from '../components/PageContext';
import useCartQuery from '../hooks/useCartQuery';
import {
    createCheckoutUrl,
    createContactUrl,
    createFaqUrl,
} from '../utils/urlHelper';
import useMaintenanceModeOverlay from '../hooks/useMaintenanceModeOverlay';
import CartSummaryMobile from '../components/CartSummaryMobile';
import useIsDefaultColorScheme from '../hooks/useIsDefaultColorScheme';
import { checkoutButtonStyles } from '../components/CheckoutFormElements';

export { Head } from '../components/Head';

const CheckoutLink = styled(Link)`
    ${checkoutButtonStyles};

    @media not all and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-bottom: 0;
    }
`;

const Inner = styled.div`
    grid-column: 2 / span 7;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const NumberedHeading = styled(HeadingLarge)`
    scroll-margin-top: var(--navbarHeightCollapsed);
    font-weight: normal;
`;

const Subtitle = styled.p`
    color: var(--foregroundColorMix3);
    margin-top: var(--spacing3);
`;

const LoadingMessage = styled.p`
    color: var(--foregroundColorMix3);
`;

const GreyInlineUnderlinedLink = styled(InlineUnderlinedLink)`
    --color: var(--foregroundColorMix3);
`;

function BuyPage(): React.ReactElement {
    const fontFamilyGroup = useFontFamilyGroup();
    const { name } = fontFamilyGroup;
    const cartQuery = useCartQuery();
    const isDefaultTheme = useIsDefaultColorScheme();
    const cart = cartQuery?.data?.cart;
    useMaintenanceModeOverlay();

    return (
        <Container>
            <Inner>
                <span>
                    <HeadingExtraLarge>{`Buy ${name}`}</HeadingExtraLarge>
                    <Subtitle>
                        Pay once, use forever. No monthly subscriptions.
                        <br />
                        Package discounts apply as you add more fonts or licence
                        types to your cart.
                        <br />
                        Refer to our{' '}
                        <GreyInlineUnderlinedLink to={createFaqUrl()}>
                            FAQs
                        </GreyInlineUnderlinedLink>{' '}
                        for more information, or{' '}
                        <GreyInlineUnderlinedLink to={createContactUrl()}>
                            contact us
                        </GreyInlineUnderlinedLink>{' '}
                        to upgrade your order or for any other licensing needs.
                    </Subtitle>
                </span>
                {cart ? (
                    <>
                        <NumberedHeading id={BUY_PAGE_LICENCES_ID}>
                            1. Choose licences
                        </NumberedHeading>
                        <LicenceSelector />
                        <NumberedHeading id={BUY_PAGE_FONTS_ID}>
                            2. Choose fonts
                        </NumberedHeading>
                        <FontSelector />
                        <CartSummaryMobile
                            buttonElement={
                                <CheckoutLink
                                    $isDefaultTheme={isDefaultTheme}
                                    to={createCheckoutUrl()}
                                >
                                    Checkout
                                </CheckoutLink>
                            }
                            isBuyPage
                        />
                    </>
                ) : (
                    <>
                        <NumberedHeading>1. Choose licences</NumberedHeading>
                        <LoadingMessage>Loading, please wait…</LoadingMessage>
                    </>
                )}
            </Inner>
        </Container>
    );
}

export default React.memo(BuyPage);
