import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import InlineUnderlinedInternalLink, {
    A as InlineUnderlinedLink,
} from './InlineUnderlinedLink';
import { HeadingMedium } from './Heading';
import { Paragraph } from './Paragraph';
import { ParagraphExtraLarge } from './Paragraph';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import {
    createSupportEmailUrl,
    createSalesEmailUrl,
    // createMediaEmailUrl,
    createCommercialEmailUrl,
    createFontFamilyUrl,
    createCollectionUrl,
    createAwardsAndAccoladesUrl,
    createTwitterUrl,
} from '../utils/urlHelper';

// NOTE: These images are currently manually-uploaded to S3 from the src/assets/images folder
const aboutProfileAndrew = 'manual/profile/andrew_johnson.jpg';
const aboutProfileBen = 'manual/profile/ben_kiel.jpg';
// const aboutProfileCarina = 'manual/profile/carina_gallegos_2.jpg';
const aboutProfileDave = 'manual/profile/dave_foster.jpg';
const aboutProfileDIA = 'manual/profile/dia.jpg';
const aboutProfileHera = 'manual/profile/hera_lindsay_bird.jpg';
const aboutProfileJason = 'manual/profile/jason_yuan.jpg';
// const aboutProfileJess = 'manual/profile/jess_sowersby.jpg';
const aboutProfileKris = 'manual/profile/kris_sowersby.jpg';
const aboutProfileLyle = 'manual/profile/lyle_chetty.jpg';
// const aboutProfileMonika = 'manual/profile/monika_bartels.jpg';
const aboutProfileNoe = 'manual/profile/noe_blanco.jpg';
const aboutProfilePanos = 'manual/profile/panos_haratzopoulos.jpg';
const aboutProfilePeter = 'manual/profile/peter_dekkers_2.jpg';
const aboutProfileSonja = 'manual/profile/sonja_schroeder.jpg';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-row-gap: var(--spacing3);
        margin-bottom: var(--spacing2);
    }
`;

const InnerWrapper = styled.div<{ isLargeGapContainer?: boolean }>`
    display: grid;
    grid-gap: var(--gridColumnGap);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: ${({ isLargeGapContainer }): string =>
        isLargeGapContainer ? 'var(--spacing5)' : 'var(--spacing3)'};

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-auto-columns: 100%;
        grid-row-gap: var(--spacing3);
        margin-bottom: var(--spacing2);
    }
`;

const LargeContainer = styled.div`
    padding: var(--spacing8) 0 0 0;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    line-height: var(--lineHeightBody1);
    grid-column-gap: var(--gridColumnGap);
    padding: 0 0 var(--spacing8) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding: 0 0 var(--spacing6) 0;
        grid-row-gap: var(--spacing5);
    }
`;

const Column = styled.div`
    &:nth-child(even) {
        grid-column: 8 / span 4;
        margin-bottom: var(--spacing8);
    }

    &:nth-child(odd) {
        grid-column: 3 / span 4;
        margin-bottom: var(--spacing8);
    }

    &:first-child {
        grid-column: 1 / span 2;
    }

    &:nth-child(2) {
        grid-column: 3 / span 4;
        margin-bottom: var(--spacing8);
    }

    &:last-child,
    &:nth-last-child(2) {
        margin-bottom: 0;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(odd) {
            grid-column: 1 / span 3;
        }

        &:nth-child(even) {
            grid-column: span 3;
        }

        &:first-child {
            grid-column: 1 / span 6;
            margin-bottom: var(--spacing3);
        }
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        &:nth-child(odd),
        &:nth-child(even) {
            grid-column: 1 / span var(--gridColumnCount);
            margin-bottom: 0;
        }
    }
`;

const ImageWrapper = styled.div<{
    isLargeColumn?: boolean;
}>`
    grid-column: ${({ isLargeColumn }): string =>
        isLargeColumn ? '1 / 4' : '1 / 3'};

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / 5;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: ${({ isLargeColumn }): string =>
            isLargeColumn ? '1 / 5' : '1 / 3'};
    }
`;

const ContentWrapper = styled.div`
    display: grid;
    grid-row-gap: var(--spacing3);
    grid-column: 1 / 5;
`;

const Heading = styled(HeadingMedium)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed5);
    }
`;

const SubHeading = styled(ParagraphExtraLarge)`
    font-size: var(--fontSizeFixed5);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed5);
        line-height: var(--lineHeightHeading2);
    }
`;

const Spacer = styled.div`
    border-bottom: 1px solid var(--borderColor);
    margin: 0 0 var(--spacing8) 0;
`;

const PersonalBrandText = styled(Paragraph)`
    margin-bottom: 0;
    word-spacing: var(--fontSizeFixed4);
    font-size: var(--fontSizeFixed2);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing2);
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Inner = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto;
`;

interface AboutContentBlockProps {
    heading?: string;
    title?: string;
    paragraph?: React.ReactNode;
    src?: string;
    personalBrandText?: React.ReactNode;
    isLargeGapContainer?: boolean;
    isLargeColumn?: boolean;
    aspectRatio: number;
}

function AboutContentBlock({
    heading,
    title,
    paragraph,
    src,
    personalBrandText,
    isLargeColumn,
    aspectRatio,
}: AboutContentBlockProps): React.ReactElement {
    return (
        <>
            {src && (
                <ImageWrapper isLargeColumn={isLargeColumn}>
                    <ImageWithPlaceholder
                        src={src}
                        aspectRatio={aspectRatio}
                        lazyLoad
                        fetchPriority="low"
                    />
                </ImageWrapper>
            )}
            <Inner />
            <ContentWrapper>
                <Inner>
                    <Heading>{heading}</Heading>
                    <SubHeading>{title}</SubHeading>
                </Inner>
                <Paragraph>{paragraph}</Paragraph>
                <PersonalBrandText>{personalBrandText}</PersonalBrandText>
            </ContentWrapper>
        </>
    );
}

function AboutContentImageBlockOne(): React.ReactElement {
    const people = [
        {
            src: aboutProfileKris,
            heading: 'Kris Sowersby',
            title: 'Director & lead type designer',
            paragraph: (
                <>
                    Since releasing his first retail typeface,{' '}
                    <InlineUnderlinedInternalLink
                        to={createFontFamilyUrl('feijoa')}
                    >
                        Feijoa
                    </InlineUnderlinedInternalLink>
                    , in 2007, Kris has received numerous{' '}
                    <InlineUnderlinedLink href={createAwardsAndAccoladesUrl()}>
                        awards and accolades
                    </InlineUnderlinedLink>
                    , including: a Certificate of Excellence from the New York
                    Type Directors Club for his second typeface,{' '}
                    <InlineUnderlinedInternalLink
                        to={createFontFamilyUrl('national')}
                    >
                        National
                    </InlineUnderlinedInternalLink>
                    , in 2007; being named an{' '}
                    <InlineUnderlinedLink href="https://www.oneclub.org/awards/youngguns/8/-bio/yg8-489-kris-sowersby">
                        ADC Young Gun
                    </InlineUnderlinedLink>{' '}
                    in 2010; being accepted as a member of the prestigious{' '}
                    <InlineUnderlinedLink href="http://a-g-i.org/">
                        Alliance Graphique Internationale
                    </InlineUnderlinedLink>{' '}
                    (the second New Zealander to do so) in 2013; and receiving
                    the{' '}
                    <InlineUnderlinedLink href="https://bestawards.co.nz/about#kris-sowersby-pdinz">
                        John Britten Black Pin
                    </InlineUnderlinedLink>{' '}
                    in 2015, the highest award given by the Designers Institute
                    of New Zealand. In 2019, Kris was named an Art Laureate by{' '}
                    <InlineUnderlinedLink href="https://www.thearts.co.nz">
                        The Arts Foundation
                    </InlineUnderlinedLink>{' '}
                    for his continuing contribution to New Zealand Art and
                    Design. Kris has presented at venues around the world and
                    continues to seek opportunities where he can share his
                    typographic knowledge and interest.
                </>
            ),
            personalBrandText: (
                <InlineUnderlinedLink href={createTwitterUrl()}>
                    @klimtypefoundry
                </InlineUnderlinedLink>
            ),
        },
        // {
        //     src: aboutProfileJess,
        //     heading: 'Jess Sowersby',
        //     title: 'Studio manager, marketing & media',
        //     paragraph: (
        //         <>
        //             Jess runs Klim’s business and studio in partnership with
        //             Kris. She manages Klim’s marketing, sponsorship and media.
        //             She also oversees Klim’s customer support and admin staff.
        //             Before Klim, Jess worked in marketing, communications and
        //             account management across the public, private and
        //             not-for-profit sectors. Outside of fonts, her design
        //             interest lies in sustainable and socially conscious design.
        //             She enjoys finding ways that Klim can contribute to the
        //             local and international design community.
        //         </>
        //     ),
        //     personalBrandText: (
        //         <InlineUnderlinedLink href={createMediaEmailUrl(true)}>
        //             {createMediaEmailUrl()}
        //         </InlineUnderlinedLink>
        //     ),
        // },
        {
            src: aboutProfileNoe,
            heading: 'Noe Blanco',
            title: 'Type designer & font engineer',
            paragraph: (
                <>
                    Noe Blanco is a Typeface Designer and Font Engineer for
                    Klim, based in Barcelona, Spain. She enjoys drawing and
                    producing fonts with a special focus on the process where
                    drawing and design meet technology. After graduating at BAU
                    with a BA in Graphic Design and an MA in Advanced Typography
                    at Eina in Barcelona, Noe went on to complete an MA in Type
                    and Media at the Royal Academy of Arts in The Hague. Noe has
                    worked for Underware and, since 2013, she’s worked
                    independently, collaborating with numerous foundries such as
                    Tobias Frere-Jones, Blackletra, Suitcase and Foster Type.
                </>
            ),
            personalBrandText: (
                <>
                    <InlineUnderlinedLink href="https://twitter.com/blancotype">
                        @blancotype
                    </InlineUnderlinedLink>{' '}
                    <InlineUnderlinedLink href="http://www.noeblanco.com/">
                        noeblanco.com
                    </InlineUnderlinedLink>
                </>
            ),
        },
        {
            src: aboutProfileDave,
            heading: 'Dave Foster',
            title: 'Type designer',
            paragraph: (
                <>
                    Based in Sydney, Australia, Dave works part-time for Klim as
                    a Typeface Designer. Dave graduated with a Bachelor of
                    Communication Design from Swinburne University in 2005. He
                    worked as a graphic designer for six years before gaining a
                    Masters in Typeface Design from the Royal Academy of Art at
                    The Hague in 2012. His graduating typeface, Blanco, won gold
                    at The Morisawa Type Design Competition the same year.
                    Returning to Australia, he established Foster Type, which
                    designs retail and custom type, as well as helping other
                    foundries produce their releases. In addition to Klim he has
                    worked for Commercial Type, Frere-Jones Type, House
                    Industries and Letterror.
                </>
            ),
            personalBrandText: (
                <>
                    <InlineUnderlinedLink href="https://twitter.com/fostertype">
                        @fostertype
                    </InlineUnderlinedLink>{' '}
                    <InlineUnderlinedLink href="https://www.fostertype.com">
                        fostertype.com
                    </InlineUnderlinedLink>
                </>
            ),
        },
        {
            src: aboutProfilePeter,
            heading: 'Peter Dekkers',
            title: 'Lead developer',
            paragraph: (
                <>
                    Peter manages Klim’s technical strategy. As a full stack
                    software engineer he looks after Klim’s digital presence and
                    provides tools and processes to streamline typeface
                    production, sales and business administration. Originally
                    from Belgium he has worked with Klim since 2017 and
                    officially joined the foundry in 2020.
                </>
            ),
        },
        {
            src: aboutProfileSonja,
            heading: 'Sonja Schröder',
            title: 'Sales, customer support and administration',
            paragraph: (
                <>
                    Sonja provides sales support to Klim’s corporate customers,
                    as well as general customer support for all font licensing
                    and merchandise inquiries. She is also the main admin
                    support for the Klim team. Before working for Klim, her
                    professional background was in Film and TV production, HR
                    and admin support in roles in Germany, UK and Australia.
                    Sonja is originally from Bamberg, Germany, but now lives in
                    Wellington, close to Klim HQ.
                </>
            ),
            personalBrandText: (
                <>
                    <InlineUnderlinedLink href={createSalesEmailUrl(true)}>
                        {createSalesEmailUrl()}
                    </InlineUnderlinedLink>{' '}
                    <InlineUnderlinedLink href={createSupportEmailUrl(true)}>
                        {createSupportEmailUrl()}
                    </InlineUnderlinedLink>
                </>
            ),
        },
        {
            src: aboutProfileLyle,
            heading: 'Lyle Chetty',
            title: 'Contract adviser',
            paragraph: (
                <>
                    Lyle assists Klim with corporate licensing and legal
                    contracts. He studied law at Victoria University of
                    Wellington and has a background in contract negotiation and
                    risk management.
                </>
            ),
            personalBrandText: (
                <InlineUnderlinedLink href={createCommercialEmailUrl(true)}>
                    {createCommercialEmailUrl()}
                </InlineUnderlinedLink>
            ),
        },
    ];

    return (
        <>
            <LargeContainer>
                <Row>
                    <Column>People</Column>
                    {people.map(
                        (person): React.ReactElement => (
                            <Column key={person.heading}>
                                <InnerWrapper isLargeGapContainer>
                                    <AboutContentBlock
                                        isLargeColumn
                                        src={person.src}
                                        heading={person.heading}
                                        title={person.title}
                                        paragraph={person.paragraph}
                                        personalBrandText={
                                            person.personalBrandText
                                        }
                                        aspectRatio={12 / 18}
                                    />
                                </InnerWrapper>
                            </Column>
                        ),
                    )}
                </Row>
            </LargeContainer>
        </>
    );
}

function AboutContentImageBlockTwo(): React.ReactElement {
    const contributors = [
        {
            isLargeGapContainer: true,
            personalBrandText: (
                <InlineUnderlinedLink href="https://www.benkiel.com">
                    benkiel.com
                </InlineUnderlinedLink>
            ),
            src: aboutProfileBen,
            heading: 'Ben Kiel',
            title: '',
            paragraph: (
                <>
                    Ben Kiel runs Typefounding in Saint Louis, Missouri. He
                    solves interesting technical problems relating to fonts and
                    typeface design when he isn’t running the retail type
                    foundry XYZ Type with Jesse Ragan.
                </>
            ),
        },
        {
            personalBrandText: (
                <InlineUnderlinedLink href="https://www.jasonyuan.design">
                    jasonyuan.design
                </InlineUnderlinedLink>
            ),
            src: aboutProfileJason,
            heading: 'Jason Yuan',
            paragraph: (
                <>
                    Jason Yuan is an actor turned designer of humane interfaces.
                    He studied Theatre at Northwestern University and received
                    his BFA in Graphic Design from the Rhode Island School of
                    Design. He has worked for Apple, Klim, Sensei and Sony Music
                    on projects spanning motion, user experience and product
                    design.
                </>
            ),
        },
        {
            personalBrandText: (
                <InlineUnderlinedLink href="https://www.dia.tv">
                    dia.tv
                </InlineUnderlinedLink>
            ),
            src: aboutProfileDIA,
            heading: 'DIA',
            title: '',
            paragraph: (
                <>
                    Mitch Paone and Meg Donohoe are the co-founders of DIA. DIA
                    is a Brooklyn, NY, and Geneva, Switzerland-based design
                    agency specialising in kinetic identity systems. The
                    animations for Klim’s latest typeface release,{' '}
                    <InlineUnderlinedInternalLink
                        to={createCollectionUrl('soehne')}
                    >
                        Söhne
                    </InlineUnderlinedInternalLink>
                    , were produced in collaboration with DIA.
                </>
            ),
        },
        {
            personalBrandText: (
                <InlineUnderlinedLink href="https://www.heralindsaybird.com">
                    heralindsaybird.com
                </InlineUnderlinedLink>
            ),
            src: aboutProfileHera,
            heading: 'Hera Lindsay Bird',
            title: '',
            paragraph: (
                <>
                    Hera graduated from the International Institute of Modern
                    Letters in 2011 and has since won a number of prestigious
                    poetry and book awards. In 2018, Hera collaborated on a
                    publishing project with Klim and Alt Group.
                    “Sincerity/Irony” is the result – a book written by Bird,
                    designed by Alt Group, and set in Klim’s{' '}
                    <InlineUnderlinedInternalLink
                        to={createCollectionUrl('heldane')}
                    >
                        Heldane
                    </InlineUnderlinedInternalLink>
                    .
                </>
            ),
        },
        {
            src: aboutProfilePanos,
            heading: 'Panos Haratzopoulos',
            paragraph: (
                <>
                    Panos Haratzopoulos is an expert in Greek typeface design.
                    He has been active in the graphic design field since 1990.
                    He works as a design consultant and educator in Greece and
                    internationally. He holds a Master of Arts — Graphic Fine
                    Arts from the University of Kent, UK.
                </>
            ),
        },
        /*
        {
            src: aboutProfileMonika,
            heading: 'Monika Bartels',
            paragraph: (
                <>
                    Monika Bartels is a type technology expert specialising in
                    TrueType hinting. She worked as a font engineer at FontShop
                    in Berlin for nine years before launching FontWerk, her own
                    company dedicated to top-quality TrueType hinting. Since
                    January 2018, Monika is offering her extensive expertise
                    under the roof of Alphabet Type.
                </>
            ),
        },
         */
        {
            src: aboutProfileAndrew,
            heading: 'Andrew Johnson',
            paragraph: (
                <>
                    Andrew Johnson is a designer that likes creating new things.
                    He’s drawn to the nexus of disciplines that can craft
                    beautiful experiences for people while being committed to
                    solving complex human problems responsibly. He is currently
                    an Interaction Designer at Medium.
                </>
            ),
        },
    ];
    return (
        <Row>
            <Column>Contributors</Column>
            {contributors.map(
                (contributor): React.ReactElement => (
                    <Column key={contributor.heading}>
                        <InnerWrapper
                            isLargeGapContainer={
                                contributor.isLargeGapContainer
                            }
                        >
                            <AboutContentBlock
                                isLargeGapContainer={
                                    contributor.isLargeGapContainer
                                }
                                personalBrandText={
                                    contributor.personalBrandText
                                }
                                src={contributor.src}
                                heading={contributor.heading}
                                paragraph={contributor.paragraph}
                                aspectRatio={1}
                            />
                        </InnerWrapper>
                    </Column>
                ),
            )}
        </Row>
    );
}

function AboutContentImageBlock(): React.ReactElement {
    return (
        <Container>
            <AboutContentImageBlockOne />
            <Spacer />
            <AboutContentImageBlockTwo />
        </Container>
    );
}

export default React.memo(AboutContentImageBlock);
