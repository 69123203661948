import React from 'react';
import styled from 'styled-components';
import type { Glyph } from 'opentype.js';
import {
    useGlyphInspectorState,
    useGlyphInspectorDispatch,
    useFontStyleState,
} from './GlyphInspectorContext';
import { Select, SelectItem } from './Select';
import loadFont from '../utils/glyph-inspector/loadFont';
import { setFontAndGlyph } from './GlyphInspector.actions';
import { useFontFamily } from './PageContext';
import { createStaticAssetUrl } from '../utils/urlHelper';
import { LOZENGE_SPACING } from '../settings/Global';

function isValidGlyph(glyph: Glyph): boolean {
    // We never want to show the .notdef glyph
    return glyph.name?.toLowerCase() !== '.notdef';
}

const Wrap = styled.div`
    --lozengeMinWidth: 180px;
    --lozengeWidth: 15vw;
    --lozengeBackgroundColor: var(--menuBackgroundColor);
    padding-right: ${LOZENGE_SPACING}px;
`;

export default function GlyphInspectorStylesInput(): React.ReactElement {
    const [fontStyle, setFontStyle] = useFontStyleState();
    const glyphInspectorState = useGlyphInspectorState();
    const glyphInspectorDispatch = useGlyphInspectorDispatch();

    const fontFamily = useFontFamily();

    const onChange = React.useCallback(
        (value: string): void => {
            const newStyleIndex = fontFamily.fontStyles.findIndex(
                (style): boolean => style.name === value,
            );

            if (newStyleIndex > -1) {
                setFontStyle(fontFamily.fontStyles[newStyleIndex]);
            }
        },
        [setFontStyle, fontFamily.fontStyles],
    );

    const { glyph } = glyphInspectorState;

    React.useEffect((): void => {
        const fontUrl = createStaticAssetUrl(fontStyle.woffOtf || '');
        loadFont(fontUrl).then((font): void => {
            // Attempt to "restore" the same character when changing to a different fontStyle
            let newGlyph =
                glyph && glyph.unicode
                    ? font.charToGlyph(String.fromCharCode(glyph.unicode))
                    : undefined;

            if ((!newGlyph || !isValidGlyph(newGlyph)) && glyph && glyph.name) {
                // Attempt to match a corresponding glyph by name
                for (
                    let glyphIndex = 0;
                    glyphIndex < font.numGlyphs;
                    glyphIndex++
                ) {
                    const iterationGlyph = font.glyphs.get(glyphIndex);
                    if (iterationGlyph.name === glyph.name) {
                        newGlyph = iterationGlyph;
                        break;
                    }
                }
            }

            if (!newGlyph || !isValidGlyph(newGlyph)) {
                // Default to first character of the fontFamily's name.
                newGlyph = font.charToGlyph(fontFamily.name.slice(0, 1));
            }
            glyphInspectorDispatch(setFontAndGlyph(font, newGlyph));
        });
    }, [fontStyle, fontFamily]);

    return (
        <Wrap>
            <Select
                onValueChange={onChange}
                placeholder={fontStyle ? `${fontStyle.name}` : 'Styles'}
                value={fontStyle ? fontStyle.name : undefined}
            >
                {fontFamily.fontStyles.map(
                    (style): React.ReactElement => (
                        <SelectItem key={style.name} value={style.name}>
                            {style.name}
                        </SelectItem>
                    ),
                )}
            </Select>
        </Wrap>
    );
}
