import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { HeadingSmall } from './Heading';
import AccountUserOrderDetails from './AccountUserOrderDetails';
import useUserOrdersQuery from '../hooks/useUserOrdersQuery';
import { VIEWPORT } from '../settings/Global';
import { GridContainer, GridMainColumn } from './AccountOrderElements';
import { TEST_ID } from '../settings/E2e';

const Heading = styled(HeadingSmall)`
    grid-column: 1 / span 2;
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const Spacer = styled.div`
    border-bottom: 1px solid var(--borderColor);
    margin: var(--spacing7) 0 var(--spacing7) 0;
    grid-column: 3 / span 10;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
        margin: var(--spacing7) 0 var(--spacing7) 0;
    }

    &:first-child {
        margin-top: 0;
    }
`;

const OrderNumber = styled(GridMainColumn)`
    margin: 0 0 var(--spacing5) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 /-1;
        font-size: var(--fontSizeFixed6);
    }
`;

const HeadingSmallStyled = styled(HeadingSmall)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed6);
    }
`;

const ORDER_QUERY_LIMIT = 3;

export default function AccountOrderHistory(): React.ReactElement {
    const [limit, setLimit] = React.useState(ORDER_QUERY_LIMIT);
    const { loading, fetchMore, data } = useUserOrdersQuery({
        offset: 0,
        limit,
    });
    const orders = data?.user?.orders;
    const [doFetchMore, setDoFetchMore] = React.useState(true);

    return (
        <GridContainer data-cy={TEST_ID.ACCOUNT_ORDER_HISTORY}>
            <Spacer />
            <Heading>Purchases</Heading>
            {orders?.map(
                (order): React.ReactElement => (
                    <React.Fragment key={order.id}>
                        <OrderNumber>
                            <HeadingSmallStyled>
                                Order Nº {order.orderNumber}
                            </HeadingSmallStyled>
                        </OrderNumber>
                        <AccountUserOrderDetails order={order} />
                        <Spacer />
                    </React.Fragment>
                ),
            )}
            {loading ? (
                <OrderNumber>
                    <HeadingSmallStyled>Loading…</HeadingSmallStyled>
                </OrderNumber>
            ) : doFetchMore ? (
                <InView
                    onChange={(inView): void => {
                        if (inView) {
                            const currentLength = orders?.length || 0;
                            fetchMore({
                                variables: {
                                    offset: currentLength,
                                    limit: ORDER_QUERY_LIMIT,
                                },
                            }).then((fetchMoreResult) => {
                                if (!fetchMoreResult.data.user?.orders.length) {
                                    setDoFetchMore(false);
                                }
                                setLimit(
                                    currentLength +
                                        (fetchMoreResult.data.user?.orders
                                            .length || 0),
                                );
                            });
                        }
                    }}
                ></InView>
            ) : null}
        </GridContainer>
    );
}
