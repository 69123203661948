import React from 'react';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import useUuid from '../hooks/useUuid';
import { FieldType, RadioCheckbox } from './RadioCheckbox';

const Label = styled.label<{ isError?: boolean; isDisabled?: boolean }>`
    color: ${({ isError }): string =>
        isError ? 'var(--errorColor)' : 'inherit'};

    ${({ isDisabled }): FlattenSimpleInterpolation =>
        isDisabled
            ? css`
                  color: var(--foregroundColorMix7);
                  cursor: not-allowed;
              `
            : css`
                  cursor: pointer;
              `};
`;

const Wrapper = styled.div<{ $rtl?: boolean }>`
    display: inline-block;
    ${({ $rtl }): FlattenSimpleInterpolation =>
        $rtl
            ? css`
                  margin-left: var(--spacingCheckable);
              `
            : css`
                  margin-right: var(--spacingCheckable);
              `};
`;

interface FieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: React.ReactNode;
    indeterminate?: boolean;
    isError?: boolean;
    rtl?: boolean;
}

interface CombinedFieldProps extends FieldProps {
    fieldType: FieldType;
}

/**
 * A radio or checkbox field are very similar, so they use the same code.
 */
export const FieldRadioCheckbox = React.forwardRef(
    (
        { label, rtl, isError, disabled, ...inputProps }: CombinedFieldProps,
        ref: React.Ref<HTMLInputElement>,
    ): React.ReactElement => {
        const inputId = useUuid();
        return (
            <Label htmlFor={inputId} isError={isError} isDisabled={disabled}>
                {rtl ? label : null}
                <Wrapper $rtl={rtl}>
                    <RadioCheckbox
                        {...inputProps}
                        disabled={disabled}
                        id={inputId}
                        ref={ref}
                    />
                </Wrapper>
                {!rtl ? label : null}
            </Label>
        );
    },
);
FieldRadioCheckbox.displayName = 'FieldRadioCheckbox';

export const FieldRadio = React.forwardRef(
    (
        inputProps: FieldProps,
        ref: React.Ref<HTMLInputElement>,
    ): React.ReactElement => {
        return (
            <FieldRadioCheckbox
                {...inputProps}
                fieldType={FieldType.RADIO}
                ref={ref}
            />
        );
    },
);
FieldRadio.displayName = 'FieldRadio';

export const FieldCheckbox = React.forwardRef(
    (
        inputProps: FieldProps,
        ref: React.Ref<HTMLInputElement>,
    ): React.ReactElement => {
        return (
            <FieldRadioCheckbox
                {...inputProps}
                ref={ref}
                fieldType={FieldType.CHECKBOX}
            />
        );
    },
);
FieldCheckbox.displayName = 'FieldCheckbox';
