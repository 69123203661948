import 'what-input';
// Include react-spring in commons.js, so that it doesn't need to be included in every page template
import '@react-spring/web';
import React from 'react';
import loadable, { lazy } from '@loadable/component';
import type { PageProps as GatsbyPageProps } from 'gatsby';
import type { DefaultTheme } from 'styled-components';
import Navbar, { EXPANDED_LABEL_CLASSNAME } from '../components/Navbar';
import {
    CssColorVariableDefinitions,
    CssVariableDefinitions,
} from '../components/CSSVariableDefinitions';
import {
    defaultColorSchemes,
    Provider as ColorSchemeProvider,
    useCycleColorScheme,
} from '../components/ColorSchemeContext';
import { Provider as DebouncedCartUpdateProvider } from '../hooks/useDebouncedCartUpdateMutation';
import GlobalFonts from '../components/GlobalFonts';
import SiteOverlay from '../components/SiteOverlay';
import ErrorOverlay from '../components/ErrorOverlay';
import Footer, { FooterMinimal } from '../components/Footer';
import PageQueryContext from '../components/PageContext';
import type { BlogPostStubsQuery, EulasQuery } from '../gql/api-ssr';
import type { PageQueryContextType } from '../components/PageContext';
import WindowSizeCalc from '../components/WindowSizeCalc';
import { sentryMessage } from '../utils/sentry';
import Reset from '../components/Reset';
import Globals from '../components/Globals';
import type { Metadata as MetadataType } from '../data/metadata';
import GlobalRemoteFonts from '../components/GlobalRemoteFonts';
import { ApolloProvider } from '@apollo/client';
import runtimeApolloClient from '../utils/runtimeApolloClient';
import isBrowser from '../utils/isBrowser';
import getColorThemeFromBaseColors from '../utils/getColorThemeFromBaseColors';
import { useGlobalState } from '../components/GlobalRuntimeState';
import type { NavLinkProps } from '../components/Navbar';
import { createHomeUrl } from '../utils/urlHelper';
import useAllFonts from '../hooks/useAllFonts';
import notPreRelease from '../utils/notPreRelease';

// Code-split a few things to prevent `commons.js` from getting too big
const Modal = loadable(() => import('../components/Modal'));
const AdminColorPicker = lazy(() => import('../components/AdminColorPicker'));
const GridOverlay = lazy(() => import('../components/GridOverlay'));

type BlogPostStub = NonNullable<BlogPostStubsQuery['ssr']['blogPosts']>[number];

export interface PageContext extends PageQueryContextType {
    breadcrumbs?: (NavLinkProps | NavLinkProps[] | NavLinkProps[][])[];
    colorSchemes?: DefaultTheme[];
    hideCartCheckoutButton?: boolean;
    isBuyOrCheckoutPage?: boolean;
    metadata?: MetadataType;
    isBuyPage?: boolean;
    isInUsePostPage?: boolean;
    blogPostStubs?: BlogPostStub[];
    relatedBlogPostStubs?: BlogPostStub[];
    noindex?: boolean;
    minimalFooter?: boolean;
    isPreviewPage?: boolean;
    subscriptionSuccess?: boolean;
    eula?: EulasQuery['ssr']['config']['eulas'][number];
    hasMounted?: boolean;
}

export interface KlimPageProps extends GatsbyPageProps {
    pageContext: PageContext;
}

function Layout({
    pageContext,
    children,
    location,
}: KlimPageProps): React.ReactElement | null {
    const {
        hideCartCheckoutButton,
        isBuyOrCheckoutPage,
        isBuyPage,
        metadata,
        minimalFooter,
        fontFamily,
        fontFamilyGroup,
        breadcrumbs,
    } = pageContext;
    const fontFamilyGroups = useAllFonts();
    const [modalState] = useGlobalState('modal');
    const [isAdminColorPickerShown] = useGlobalState('isAdminColorPickerShown');
    const [isGridShown, setIsGridShown] = React.useState<boolean>(false);
    const cycleColorScheme = useCycleColorScheme();
    const [errorOverlayState] = useGlobalState('errorOverlay');

    if (!metadata) {
        sentryMessage(`Page ${location.pathname} lacks metadata!`);
    }

    React.useEffect((): void => {
        if (!isBrowser()) {
            return;
        }
        console.log(
            'Press Ctrl+Alt+Shift+g to toggle the Grid Overlay' +
                '\n' +
                'Press Ctrl+Alt+Shift+t to toggle the Theme',
        );
    }, []);

    React.useEffect((): (() => void) | void => {
        if (!isBrowser()) {
            return;
        }
        const onKeyPress = (event: KeyboardEvent): void => {
            const gPressed =
                event.keyCode === 71 ||
                event.key === 'g' ||
                event.code === 'KeyG';
            const tPressed =
                event.keyCode === 84 ||
                event.key === 't' ||
                event.code === 'KeyT';

            if (event.shiftKey && event.ctrlKey && event.altKey && gPressed) {
                event.preventDefault();
                setIsGridShown((state): boolean => !state);
            }

            if (event.shiftKey && event.ctrlKey && event.altKey && tPressed) {
                event.preventDefault();
                cycleColorScheme();
            }
        };
        window.addEventListener('keydown', onKeyPress);

        return (): void => window.removeEventListener('keydown', onKeyPress);
    }, [setIsGridShown, cycleColorScheme]);

    // Count the group with the most families, to set the minimum height of the font blocks
    const maxFontFamilies = React.useMemo(
        () =>
            fontFamilyGroups.reduce(
                (previousValue, currentValue) =>
                    Math.max(
                        previousValue,
                        currentValue.fontFamilies.filter(notPreRelease).length,
                    ),
                1,
            ),
        [fontFamilyGroups],
    );

    return (
        <>
            <GlobalFonts />
            <GlobalRemoteFonts
                fontFamily={fontFamily}
                fontFamilyGroup={fontFamilyGroup}
            />
            <Reset />
            <CssVariableDefinitions
                $numBreadCrumbs={
                    (breadcrumbs && Array.isArray(breadcrumbs)
                        ? breadcrumbs.length
                        : 0) + 1
                }
                $maxFontFamilies={maxFontFamilies}
            />
            <Globals />
            <CssColorVariableDefinitions />

            <DebouncedCartUpdateProvider>
                <Navbar
                    breadcrumbs={[
                        {
                            url: createHomeUrl(),
                            label: (
                                <>
                                    Klim
                                    <span className={EXPANDED_LABEL_CLASSNAME}>
                                        &nbsp;Type Foundry
                                    </span>
                                </>
                            ),
                        },
                        ...(breadcrumbs || []),
                    ]}
                    fontFamily={fontFamily}
                    fontFamilyGroup={fontFamilyGroup}
                    hideCartCheckoutButton={hideCartCheckoutButton}
                    isBuyOrCheckoutPage={isBuyOrCheckoutPage}
                    isBuyPage={isBuyPage}
                />
                <WindowSizeCalc />
                <div role="main" id="main">
                    {children}
                    {minimalFooter ? (
                        <FooterMinimal
                            isBuyOrCheckoutPage={isBuyOrCheckoutPage}
                        />
                    ) : (
                        <Footer />
                    )}
                    {isBrowser() && isAdminColorPickerShown ? (
                        <React.Suspense fallback={null}>
                            <AdminColorPicker />
                        </React.Suspense>
                    ) : null}
                </div>
                {isBrowser() && isGridShown ? (
                    <React.Suspense fallback={null}>
                        <GridOverlay />
                    </React.Suspense>
                ) : null}
                <SiteOverlay />
                {modalState.isVisible ? <Modal /> : null}
                {errorOverlayState.isShown ? <ErrorOverlay /> : null}
            </DebouncedCartUpdateProvider>
        </>
    );
}

function LayoutWrapper(props: KlimPageProps): React.ReactElement {
    const [, setPageHasMounted] = useGlobalState('pageHasMounted');
    const {
        fontFamilyGroup,
        fontFamily,
        customFontsPostPage,
        isBuyPage,
        isInUsePostPage,
        colorSchemes: contextColorSchemes,
    } = props.pageContext;

    // Let the global state know that the page has mounted
    React.useEffect(() => {
        setPageHasMounted(true);
    }, []);

    // Provide colour schemes and add background luminance
    const colorSchemes = React.useMemo(
        () =>
            // The pageContext colorSchemes always trump any others
            contextColorSchemes ||
            (customFontsPostPage && [
                getColorThemeFromBaseColors({
                    backgroundColor: customFontsPostPage.backgroundColor,
                    foregroundColor: customFontsPostPage.foregroundColor,
                    highlightColor: customFontsPostPage.highlightColor,
                }),
            ]) ||
            (fontFamily?.fontFamilyGroup || fontFamilyGroup)?.colorSchemes.map(
                (colorScheme) =>
                    getColorThemeFromBaseColors({
                        backgroundColor: colorScheme.backgroundColor,
                        foregroundColor: colorScheme.foregroundColor,
                        highlightColor: colorScheme.highlightColor,
                    }),
            ) ||
            defaultColorSchemes,
        [
            contextColorSchemes,
            customFontsPostPage,
            fontFamily?.fontFamilyGroup,
            fontFamilyGroup,
        ],
    );

    const colorSchemePersistKey = React.useMemo(
        () =>
            (isBuyPage && 'buy-page') ||
            (isInUsePostPage && 'in-use-post-page') ||
            (customFontsPostPage && customFontsPostPage.slug) ||
            (fontFamily && `${fontFamily.fontFamilyGroup.slug}-0`) ||
            // Only persist to font family groups without campaign, hence the 0
            // which will differ from the `withCampaign`.
            (fontFamilyGroup &&
                `${fontFamilyGroup.slug}-${
                    fontFamilyGroup.campaign ? 'withCampaign' : '0'
                }`),
        [
            customFontsPostPage,
            fontFamily,
            fontFamilyGroup,
            isBuyPage,
            isInUsePostPage,
        ],
    );

    return (
        <React.StrictMode>
            <ApolloProvider client={runtimeApolloClient}>
                <PageQueryContext.Provider value={props.pageContext}>
                    <ColorSchemeProvider
                        colorSchemes={colorSchemes}
                        persistKey={colorSchemePersistKey}
                    >
                        <Layout {...props} />
                    </ColorSchemeProvider>
                </PageQueryContext.Provider>
            </ApolloProvider>
        </React.StrictMode>
    );
}

export default React.memo(LayoutWrapper);
