import React from 'react';

import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import { Button } from './InlineUnderlinedLink';
import { HeadingSmall } from '../components/Heading';
import Disclosure, { useDisclosure } from './Disclosure';
import Paragraph from './Paragraph';
import { useStaticFontMetricOffsets } from './PageContext';

export const LinkAccordion = styled.div`
    display: grid;
    grid-template-columns: 100%;
    --accordionSpacing: var(--spacing5);
    padding-bottom: var(--accordionSpacing);
    grid-row-gap: var(--spacing3);

    --rowBorder: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
`;

const UnderlinedLink = styled(Button)`
    text-align: left;
    color: var(--foregroundColor);
    font-size: var(--fontSizeFixed2);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed1);
    }
`;

const Heading = styled(HeadingSmall)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

const SubHeading = styled(Paragraph)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

const Panel = styled.div`
    margin: var(--spacing1) 0;
    font-size: var(--fontSizeFixed3);
`;

interface LinkAccordionItemProps {
    heading?: React.ReactNode;
    subHeading?: React.ReactNode;
    panel: React.ReactNode;
}

export function LinkAccordionItem({
    heading,
    panel,
    subHeading,
}: LinkAccordionItemProps): React.ReactElement {
    const disclosure = useDisclosure({
        preExpanded: false,
    });
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Disclosure>
            {(): React.ReactElement => (
                <>
                    <Heading $metricOffsets={staticFontMetrics}>
                        {' '}
                        {heading}
                    </Heading>
                    <SubHeading>{subHeading}</SubHeading>

                    <p>
                        <UnderlinedLink {...disclosure.buttonAttributes}>
                            {disclosure.panelAttributes.hidden
                                ? 'Learn more'
                                : 'Close'}
                        </UnderlinedLink>
                    </p>

                    <Panel {...disclosure.panelAttributes}>{panel}</Panel>
                </>
            )}
        </Disclosure>
    );
}
