import React from 'react';
import styled from 'styled-components';
import { A as ExternalLink } from '../components/InlineUnderlinedLink';
import { VIEWPORT } from '../settings/Global';
import { Paragraph } from '../components/Paragraph';
import {
    createTwitterUrl,
    createInstagramUrl,
    createSubscribeUrl,
    createSupportEmailUrl,
    createSalesEmailUrl,
    createEulaOverviewUrl,
    createFaqUrl,
} from '../utils/urlHelper';
import { HeadingExtraLarge, HeadingLarge } from '../components/Heading';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    display: flex;
    min-height: 90vh;
    flex-direction: column;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
    }
`;

const Column = styled.div`
    &:nth-child(1) {
        grid-column: 1 / span 2;
    }

    &:nth-child(2) {
        grid-column: 3 / span 5;
        padding: 0 0 var(--spacing7) 0;
    }

    &:nth-child(3) {
        grid-column: 3 / span 5;
        padding: 0 0 var(--spacing6) 0;
    }

    &:nth-child(4) {
        grid-column: 3 / span 4;
    }

    &:nth-child(5) {
        grid-column: 8 / span 4;
        padding: 0 0 var(--spacing5) 0;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(2) {
            grid-column: 1 / span 6;
            padding: 0 0 var(--spacing7) 0;
        }

        &:nth-child(1) {
            grid-column: 1 / span 6;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            padding: var(--spacing3) 0 var(--spacing3) 0;
            grid-column: 1 / span 6;
        }
    }
`;

const Title = styled(Paragraph)`
    font-size: var(--fontSizeFixed2);
    line-height: var(--lineHeightBody1);
    color: var(--foregroundColorMix4);
    margin-bottom: var(--spacing2);
    margin-top: var(--spacing2);
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed2);
    }
`;

const Heading = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing3);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed7);
    }
`;

const Description = styled(HeadingLarge)`
    font-weight: normal;
    line-height: var(--lineHeightBody2);
`;

function ContactUsPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Container>
            <Row>
                <Column />
                <Column>
                    <Heading $metricOffsets={staticFontMetrics} as="h1">
                        Contact
                    </Heading>

                    <Paragraph>
                        Please read our{' '}
                        <ExternalLink href={createFaqUrl()}>FAQs</ExternalLink>{' '}
                        and our{' '}
                        <ExternalLink href={createEulaOverviewUrl()}>
                            font licences
                        </ExternalLink>{' '}
                        section for more information. If your questions are not
                        answered there, send us a message via our support email.
                        If your inquiry relates to one of our unlimited font
                        licences, or is about a custom typeface commission,
                        please contact{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            Sonja
                        </ExternalLink>
                        .
                    </Paragraph>
                </Column>
                <Column>
                    <Title>Support</Title>
                    <Description>
                        <ExternalLink href={createSupportEmailUrl(true)}>
                            {createSupportEmailUrl()}
                        </ExternalLink>
                    </Description>
                </Column>
                <Column>
                    <Title>Address</Title>
                    <Description>23-B Totara Road</Description>
                    <Description>Miramar, Wellington</Description>
                    <Description>New Zealand 6022</Description>
                </Column>
                <Column>
                    <Title>Follow</Title>
                    <Description>
                        <ExternalLink href={createSubscribeUrl()}>
                            Newsletter
                        </ExternalLink>
                    </Description>
                    <Description>
                        <ExternalLink href={createTwitterUrl()}>
                            Twitter
                        </ExternalLink>
                    </Description>
                    <Description>
                        <ExternalLink href={createInstagramUrl()}>
                            Instagram
                        </ExternalLink>
                    </Description>
                </Column>
            </Row>
        </Container>
    );
}

export default React.memo(ContactUsPage);
