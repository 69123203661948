import React from 'react';

export default function useOnEscapeKeyPress(callback?: () => void): void {
    React.useEffect((): (() => void) => {
        const onKeyPress = ({ key, keyCode }: KeyboardEvent): void => {
            if ((key === 'Escape' || keyCode === 27) && callback) {
                callback();
            }
        };

        window.addEventListener('keydown', onKeyPress, false);

        return (): void => window.removeEventListener('keydown', onKeyPress);
    }, [callback]);
}
