import * as Sentry from '@sentry/gatsby';
import notUndefined from './src/utils/notUndefined';
import domainNameToSentryFilterRegex from './src/utils/domainNameToSentryFilterRegex';

const LOAD_PAGE_DATA_RE = /We couldn't load "\/page-data\/.*\.json"/gm;

function getErrorDetails(
    originalException: string | Error | null | undefined,
): { name: string; message?: string } | undefined {
    if (!originalException) return;
    return typeof originalException === 'string'
        ? { name: originalException }
        : originalException;
}

Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_SENTRY_ENVIRONMENT,
    release: process.env.GATSBY_SENTRY_RELEASE,
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    allowUrls: [
        // Filtering out errors from browser extensions by
        // whitelisting only our own assets.
        process.env.GATSBY_STATIC_SITE_DOMAIN &&
            domainNameToSentryFilterRegex(
                process.env.GATSBY_STATIC_SITE_DOMAIN,
            ),
        process.env.GATSBY_STATIC_ASSET_DOMAIN &&
            domainNameToSentryFilterRegex(
                process.env.GATSBY_STATIC_ASSET_DOMAIN,
            ),
    ].filter(notUndefined),
    beforeSend(event, hint) {
        const errorDetails = getErrorDetails(hint.originalException);
        // Group some events together by fingerprinting them.
        // See https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/
        switch (errorDetails?.name) {
            case 'ChunkLoadError':
                event.fingerprint = ['chunk-load-error'];
                break;
            case 'AbortError':
                event.fingerprint = ['abort-error'];
                break;
            case 'UnhandledRejection':
                if (!errorDetails.message) break;
                if (
                    errorDetails.message.trim() ===
                    'Non-Error promise rejection captured with value: Font could not be loaded'
                ) {
                    event.fingerprint = ['glyph-font-load-fail'];
                }
                break;
            case 'Error':
                if (!errorDetails.message) break;
                if (LOAD_PAGE_DATA_RE.test(errorDetails.message)) {
                    event.fingerprint = ['could-not-load-page-data-json'];
                } else if (
                    errorDetails.message
                        .trim()
                        .startsWith('Unsupported OpenType signature <htm')
                ) {
                    event.fingerprint = ['glyph-font-load-fail'];
                }
                break;
        }
        return event;
    },
});
