import React from 'react';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import isBrowser from '../utils/isBrowser';

export type ImageFetchPriority = 'high' | 'low' | 'auto';

const Img = styled.img.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
        // The `fetchpriority` attribute isn't recognised by Styled Components at the time of writing
        return ['fetchpriority'].includes(prop) || defaultValidatorFn(prop);
    },
})<{
    isImageLoaded: boolean;
}>`
    ${({ isImageLoaded }): FlattenSimpleInterpolation | null => {
        return !isImageLoaded
            ? css`
                  --imageOpacity: 0;
              `
            : null;
    }};

    /* Workaround for jumpy filter transition in Chrome... */
    will-change: opacity;

    /* These can be set by HOC */
    opacity: var(--imageOpacity);
    filter: var(--imageFilter);
`;

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    onLoadCallback?: () => void;
    fetchPriority?: ImageFetchPriority;
}

export default function LazyImage({
    onLoadCallback,
    fetchPriority,
    ...props
}: Props): React.ReactElement {
    const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

    const onLoad = React.useCallback((): void => {
        if (!isImageLoaded) {
            setIsImageLoaded(true);
            if (onLoadCallback) {
                onLoadCallback();
            }
        }
    }, [onLoadCallback]);

    const imageRef = React.useRef<HTMLImageElement | null>(null);
    const pollForComplete = React.useCallback((): void => {
        if (isImageLoaded) return;
        const elm = imageRef.current;
        if (elm) {
            if (elm.complete) {
                onLoad();
            } else {
                requestAnimationFrame(pollForComplete);
            }
        }
    }, [isImageLoaded, onLoad]);

    const ref = React.useCallback(
        (elm: HTMLImageElement | null): void => {
            imageRef.current = elm;
            // Give react a chance to render before starting to poll
            // This gives us more chance of the opacity transition being visible
            // Should also fix rendering glitches in firefox where native image placeholder shows briefly
            setTimeout(pollForComplete, 300);
        },
        [imageRef, pollForComplete],
    );

    return (
        <Img
            {...props}
            onLoad={isBrowser() ? onLoad : undefined}
            isImageLoaded={isImageLoaded}
            ref={isBrowser() ? ref : undefined}
            // @ts-ignore Not supported at time of writing, in @types/react-dom
            fetchpriority={fetchPriority}
        />
    );
}
