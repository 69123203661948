import React from 'react';
import type { HeadProps } from 'gatsby';
import type { PageContext } from '../layouts';
import { defaultTitle, defaultImage } from '../data/metadata';
import {
    createStaticAssetOrigin,
    createImgixOrigin,
    createStaticSiteOrigin,
} from '../utils/urlHelper';
import stripHtml from '../utils/stripHtml';
import { COLOR } from '../settings/Global';

export const Head = ({
    location: { pathname },
    pageContext: { metadata, noindex },
}: HeadProps<undefined, PageContext>): React.ReactElement => {
    const description = React.useMemo(
        () =>
            metadata?.description
                ? stripHtml(metadata.description).trim()
                : undefined,
        [metadata?.description],
    );
    const title = React.useMemo(
        () => metadata?.title || defaultTitle,
        [metadata?.title],
    );
    const image = React.useMemo(
        () => metadata?.image || defaultImage,
        [metadata?.image],
    );

    return (
        <>
            <meta charSet="UTF-8" />
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {noindex && <meta name="robots" content="noindex" />}
            {/*
            Gatsby will already add its own viewport meta tag in .cache/html.js...
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            */}
            <meta
                key="apple-mobile-web-app-capable"
                name="apple-mobile-web-app-capable"
                content="yes"
            />
            <meta
                key="apple-mobile-web-app-status-bar-style"
                name="apple-mobile-web-app-status-bar-style"
                content="black"
            />
            <link
                key="apple-touch-startup-image"
                rel="apple-touch-startup-image"
                href={'/favicons/klim-favicon-1024.png'}
            />
            <link
                key="mask-icon"
                rel="mask-icon"
                href={'/favicons/klim-mask-icon.svg'}
                color={COLOR.BLACK}
            />
            {/* The theme colour will be updated via ColorSchemeContext */}
            <meta name="theme-color" content={COLOR.BLACK} />

            {/* Performance optimizations - suggests that the browser
             * should immediately do DNS lookups for these domains and
             * even start a TCP handshake etc.  */}
            <link
                rel="preconnect"
                href={createStaticAssetOrigin()}
                crossOrigin="anonymous"
            />
            <link rel="dns-prefetch" href={createStaticAssetOrigin()} />
            <link
                rel="preconnect"
                href={createImgixOrigin()}
                crossOrigin="anonymous"
            />
            <link rel="dns-prefetch" href={createImgixOrigin()} />

            {/*
             * If content is set to yes, the web application runs in
             * full-screen mode; otherwise, it does not. The default
             * behavior is to use Safari to display web content.
             * You can determine whether a webpage is displayed in
             * full-screen mode using the window.navigator.standalone
             * read-only Boolean JavaScript property.
             *
             * From: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html
             */}
            <meta name="apple-mobile-web-app-capable" content="yes" />

            {/* TWITTER */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@klimtypefoundry" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:image" content={image} />
            {description && (
                <meta name="twitter:description" content={description} />
            )}

            {/* OPENGRAPH (FACEBOOK) */}
            <meta
                property="og:url"
                content={`${createStaticSiteOrigin()}${pathname}`}
            />
            <meta property="og:title" content={metadata?.ogTitle || title} />
            {description && (
                <meta property="og:description" content={description} />
            )}
            <meta property="og:image" content={image} />
            {metadata?.ogType && (
                <meta property="og:type" content={metadata.ogType} />
            )}
            {metadata?.ogArticle?.publishedTime && (
                <meta
                    property="article:published_time"
                    content={metadata.ogArticle.publishedTime}
                />
            )}
            {metadata?.ogArticle?.modifiedTime && (
                <meta
                    property="article:modified_time"
                    content={metadata.ogArticle.modifiedTime}
                />
            )}
            {metadata?.ogArticle?.expirationTime && (
                <meta
                    property="article:expiration_time"
                    content={metadata.ogArticle.expirationTime}
                />
            )}
            {metadata?.ogArticle && (
                <>
                    <meta
                        property="article:author:profile:first_name"
                        content={metadata.ogArticle.author.firstName}
                    />
                    <meta
                        property="article:author:profile:last_name"
                        content={metadata.ogArticle.author.lastName}
                    />
                    {metadata.ogArticle.tags.map(
                        (tag): React.ReactNode => (
                            <meta
                                property="article:tag"
                                key={tag}
                                content={tag}
                            />
                        ),
                    )}
                </>
            )}
        </>
    );
};
