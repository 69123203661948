import React from 'react';
import styled from 'styled-components';
import { ZINDEX } from '../settings/Global';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    --border: 1px solid var(--highlightColor);

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    padding: 0 var(--gridMarginGap);
    z-index: ${ZINDEX.GRID_OVERLAY};
`;

const Hr = styled.hr<{ top: number }>`
    position: absolute;
    top: ${({ top }): string => top.toFixed(0)}px;
    left: 0;
    width: 100%;
    border: 0;
    outline: 0;
    height: 0;
    margin: 0;
    border-top: var(--border);
`;

const Inner = styled.div`
    border-left: var(--border);
    border-right: var(--border);
`;

function GridOverlay(): React.ReactElement {
    const [mouseY, setMouseY] = React.useState<number>(0);

    React.useEffect((): (() => void) => {
        const onMousemove = (e: MouseEvent): void => {
            setMouseY(e.clientY);
        };

        window.addEventListener('mousemove', onMousemove);

        return (): void => window.removeEventListener('mousemove', onMousemove);
    }, []);

    return (
        <Container>
            <Hr top={mouseY} />
            {Array.from(Array(12)).map(
                (column, columnIndex): React.ReactElement => (
                    <Inner key={columnIndex}>
                        <Inner />
                    </Inner>
                ),
            )}
        </Container>
    );
}

export default React.memo(GridOverlay);
