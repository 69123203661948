import React from 'react';
import styled from 'styled-components';
import type { Item } from '../hooks/useUserOrdersQuery';
import useAllFonts from '../hooks/useAllFonts';
import { VIEWPORT } from '../settings/Global';
import getLineItemUpgradeDescription from '../utils/getLineItemUpgradeDescription';
import getStyledFontName from '../utils/getStyledFontName';
import getFontUrl from '../utils/getFontUrl';
import { Link as GatsbyLink } from 'gatsby';

const Container = styled.div<{ isFirstInList?: boolean }>`
    border-top: ${({ isFirstInList }): string =>
        isFirstInList ? '1px solid var(--borderColor)' : 'none'};
    border-bottom: 1px solid var(--borderColor);

    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
    padding: var(--spacing1) 0;
`;

const FontName = styled.div<{ fontSizeMultiplier?: number }>`
    color: var(--foregroundColor);
    --baseFontSize: var(--fontSizeFixed5);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin: var(--spacing1) 0;
    }

    font-size: ${({ fontSizeMultiplier }): string =>
        fontSizeMultiplier && fontSizeMultiplier != 1
            ? `calc(var(--baseFontSize) * ${fontSizeMultiplier});`
            : 'var(--baseFontSize)'};
`;

const Description = styled.p<{ primary?: boolean }>`
    display: inline;
    white-space: pre-wrap;
    color: ${({ primary }): string =>
        primary ? 'var(--foregroundColor)' : 'var(--foregroundColorMix4)'};

    padding-bottom: ${({ primary }): string =>
        primary ? '0' : 'var(--spacing1)'};
`;

const FontLink = styled(GatsbyLink)`
    &:hover,
    &:focus,
    &:active {
        color: var(--foregroundColorMix4);
    }
`;

export default function AccountCartLineItem({
    item,
    isFirstInList,
}: {
    item: Item;
    isFirstInList?: boolean;
}): React.ReactElement {
    const fontFamilyGroups = useAllFonts();

    const styledFontName = getStyledFontName(item, fontFamilyGroups);
    const upgradeDescription = getLineItemUpgradeDescription(item);
    const fontUrl = getFontUrl(item, fontFamilyGroups);

    return (
        <Container isFirstInList={isFirstInList}>
            {styledFontName && (
                <FontName fontSizeMultiplier={item.font?.fontSizeMultiplier}>
                    {fontUrl ? (
                        <FontLink to={fontUrl}>{styledFontName}</FontLink>
                    ) : (
                        styledFontName
                    )}
                </FontName>
            )}
            {upgradeDescription && (
                <Description>{upgradeDescription}</Description>
            )}
            {item.description && (
                <Description primary={!styledFontName}>
                    {item.description}
                </Description>
            )}
        </Container>
    );
}
