import React from 'react';
import styled, { css } from 'styled-components';
import {
    blogAndInUseWrapStyles,
    bodyStyles,
    hyperlinkStyle,
} from './BlogPostStyles';
import { BlogContentWithFootnotesProps } from './BlogPost';
import {
    COUNTER_SIDENOTE,
    FOOTNOTE_BACK_CLASSNAME,
    FOOTNOTE_CLASSNAME,
    VIEWPORT,
} from '../settings/Global';
import { hyphensAuto, preventPrintPageBreak } from '../utils/stylesMixins';

const Wrap = styled.div`
    ${blogAndInUseWrapStyles};

    /* Print stylesheets seem to have trouble with this */
    @media screen {
        align-items: baseline;
    }

    grid-template-areas: '. . a a a a a a . b b .';

    @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
        grid-template-areas: '. . a a a a a . b b b .';
    }

    @media screen and (max-width: ${VIEWPORT.DESKTOP}px) {
        grid-template-areas: '. . a a a a a a . b b b';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-areas: '. a a a a a a a a a . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: 'a a a a a a';
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const BlogStringBody = styled.div`
    p {
        ${preventPrintPageBreak}
    }

    grid-area: a;

    --bulletOffset: 1em;
    --listOffset: 1.6em;

    ${bodyStyles}

    a:not(.${FOOTNOTE_CLASSNAME}) {
        ${hyperlinkStyle}
    }

    .${FOOTNOTE_CLASSNAME} {
        scroll-margin-top: var(--navbarHeightCollapsed);
        font-family: var(--fontFamilySoehne);
        color: var(--foregroundColorMix5);
        /* Must be less than var(--lineHeightBlogBody) to ensure that the SoehneBuch font doesn't affect the
        rendered line height of the line where the footnote is :corner-present. */
        line-height: 1;

        :focus,
        :hover,
        :active {
            color: var(--foregroundColorMix1);
        }
    }
`;

const SidenoteWrap = styled.ol`
    list-style-type: none;

    grid-area: b;
    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const footnoteSharedStyles = css`
    font-size: var(--fontSizeFixed1);
    line-height: var(--lineHeightBody2);
    color: var(--foregroundColorMix5);
    @media print {
        color: var(--foregroundColorMix3);
    }
`;

export const footnoteBodyStyles = css`
    ${footnoteSharedStyles};
    ${hyphensAuto};
    ${preventPrintPageBreak}

    a:not(.${FOOTNOTE_BACK_CLASSNAME}) {
        ${hyperlinkStyle};

        --underlineColor: var(--foregroundColorMix6);
        --hoverUnderlineColor: var(--foregroundColorMix1);

        :focus,
        :hover,
        :active {
            color: var(--foregroundColorMix1);
        }
    }

    a.${FOOTNOTE_BACK_CLASSNAME} {
        font-family: var(--fontFamilySoehneIkon);
        color: var(--foregroundColorMix3);
        padding-left: 0.2em;

        :focus,
        :hover,
        :active {
            color: var(--foregroundColorMix8);
        }
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    p {
        ${preventPrintPageBreak}
    }
`;

export const footnoteNumberStyles = css`
    ${footnoteSharedStyles};
    font-feature-settings: 'tnum';
    /* Not using absolute as it doesn't work for Firefox when printing... */
    position: relative;
    float: left;
    margin-left: calc((var(--gridColumnGap) + var(--columnWidth)) * -1);
    text-align: right;
    width: var(--columnWidth);
`;

const Sidenote = styled.li`
    ${footnoteBodyStyles};
    margin-bottom: 1em;
    counter-increment: ${COUNTER_SIDENOTE};
    .${FOOTNOTE_BACK_CLASSNAME} {
        display: none;
    }

    p:first-child::before {
        content: counter(${COUNTER_SIDENOTE});
        ${footnoteNumberStyles};
    }
`;

export function BlogBodyTextWithFootNotes(
    props: BlogContentWithFootnotesProps,
): React.ReactElement {
    const { textContent } = props.block;
    const html = textContent && textContent.replace(/<p>\s*<\/p>/gi, ''); // remove empty paragraphs

    return (
        <Wrap>
            <BlogStringBody dangerouslySetInnerHTML={{ __html: html || '' }} />
            <SidenoteWrap start={props.numberOfPreviousFootnotes + 1}>
                {props.footnotesList.map(
                    (footNote, idx): React.ReactElement => {
                        return (
                            <Sidenote
                                key={idx}
                                dangerouslySetInnerHTML={{
                                    __html: footNote || '',
                                }}
                            />
                        );
                    },
                )}
            </SidenoteWrap>
        </Wrap>
    );
}
