import React from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';

import { useCustomFont } from './PageContext';
import type { CustomFont } from './PageContext';
import { VIEWPORT } from '../settings/Global';
import _LazyResponsiveImage from './LazyResponsiveImage';
import getLozengeHeight from '../utils/getLozengeHeight';
import { EXPANDED_TOOLBAR_BREAKPOINT } from './TypeEditorToolbar';

type CustomFontSpecimen = CustomFont['specimens'][number];

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;

    grid-row-gap: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing5);
    }
`;

const LazyResponsiveImage = styled(_LazyResponsiveImage)`
    display: block;
    width: 100%;
`;

const Item = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
`;

const detailStyles = css`
    color: var(--foregroundColorMix4);
    /* Consistent with retail specimens */
    min-height: ${getLozengeHeight(2)}px;
    @media screen and (max-width: ${EXPANDED_TOOLBAR_BREAKPOINT}px) {
        min-height: ${getLozengeHeight()}px;
    }
`;

const FamilyName = styled.div`
    ${detailStyles};
    grid-column: 1 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 3;
    }
`;

const StyleName = styled.div`
    ${detailStyles};
    grid-column: 3 / span 10;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 4 / span 3;
        text-align: right;
    }
`;

const Specimen = styled.div`
    grid-column: 1 / span var(--gridColumnCount);
`;

const Svg = styled(SVG)`
    display: block;
    width: 100%;
    height: auto;
    fill: var(--foregroundColor);
    overflow: visible;
`;

function sortSpecimensFn(a: CustomFontSpecimen, b: CustomFontSpecimen): number {
    return (
        // Sort by sort order (ascending)
        a.sortOrder - b.sortOrder
    );
}

function CustomFontsPostSpecimens(): React.ReactElement {
    const { name, specimens } = useCustomFont();

    return (
        <Container>
            {specimens.sort(sortSpecimensFn).map(
                (specimen): React.ReactElement => (
                    <Item key={specimen.id}>
                        <FamilyName>
                            {specimen.family && specimen.family.name
                                ? specimen.family.name
                                : name}
                        </FamilyName>
                        <StyleName>{specimen.name}</StyleName>
                        <Specimen>
                            {specimen.bitmap ? (
                                <LazyResponsiveImage
                                    sources={[{ src: specimen.bitmap }]}
                                    src={specimen.bitmap}
                                />
                            ) : (
                                specimen.svg && (
                                    <Svg
                                        src={specimen.svg}
                                        title={undefined}
                                        description={`${
                                            specimen.family &&
                                            specimen.family.name
                                                ? specimen.family.name
                                                : name
                                        } ${specimen.name} specimen`}
                                        uniquifyIDs={true}
                                    />
                                )
                            )}
                        </Specimen>
                    </Item>
                ),
            )}
        </Container>
    );
}

export default CustomFontsPostSpecimens;
