import React from 'react';
import styled from 'styled-components';
import { styles as underlinedLinkStyles } from './InlineUnderlinedLink';
import Link from './Lozenge';
import { ParagraphLarge, ParagraphExtraLargeStyles } from './Paragraph';
import { createBlogPostUrl } from '../utils/urlHelper';
import Icon, { IconType } from './Icon';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing4);
`;

const InformationWrapper = styled.div`
    ${ParagraphExtraLargeStyles};
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    a {
        ${underlinedLinkStyles};
        padding-bottom: 0;
        ${ParagraphExtraLargeStyles};
    }
`;

function FontIntroduction({
    informationIntro,
    blogPostSlug,
}: {
    informationIntro: string;
    blogPostSlug: string | null;
}): React.ReactElement {
    return (
        <Container>
            <InformationWrapper
                dangerouslySetInnerHTML={{
                    __html: informationIntro,
                }}
            />

            {blogPostSlug && (
                <ParagraphLarge>
                    <Link to={createBlogPostUrl(blogPostSlug)}>
                        Continue reading&nbsp;
                        <Icon type={IconType.ARROW_RIGHT} />
                    </Link>
                </ParagraphLarge>
            )}
        </Container>
    );
}

export default React.memo(FontIntroduction);
