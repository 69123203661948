import React from 'react';
import styled from 'styled-components';
import useCartQuery from '../hooks/useCartQuery';
import { useErrorOverlayState } from '../components/GlobalRuntimeState';
import { VIEWPORT } from '../settings/Global';
import { HeadingExtraLarge } from '../components/Heading';
import Paragraph from '../components/Paragraph';
import { useMutation } from '@apollo/client';
import { applySharedCartMutation } from '../utils/runtimeQueries';
import {
    ApplySharedCartMutation,
    ApplySharedCartMutationMutationVariables,
} from '../gql/api-public';
import { ErrorOverlayType } from '../components/ErrorOverlay';
import useLocationSharedCartId from '../hooks/useLocationSharedCartId';
import { navigate } from 'gatsby';
import { createCheckoutUrl } from '../utils/urlHelper';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--gridColumnGap);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed1);
        grid-template-columns: var(--gridTemplateColumnsDefault);
    }
`;

const Section = styled.div`
    grid-column: 3 / span 6;

    &:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: var(--gridColumnGap);
        grid-row-gap: var(--spacing4);
    }

    ${HeadingExtraLarge} {
        grid-column: 1 / span 6;
        margin-bottom: var(--spacing4);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            margin-bottom: var(--spacing6);
        }
    }

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 12;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
    }
`;

export default function SharedCartPage(): React.ReactElement {
    const sharedCartId = useLocationSharedCartId();
    const [, setErrorOverlayState] = useErrorOverlayState();
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const [doApplySharedCart] = useMutation<
        ApplySharedCartMutation,
        ApplySharedCartMutationMutationVariables
    >(applySharedCartMutation);

    React.useEffect(() => {
        if (!cart) {
            return;
        }
        if (!sharedCartId) {
            setErrorOverlayState({
                isShown: true,
                type: ErrorOverlayType.SHARED_CART_NOT_FOUND,
            });
            return;
        }
        doApplySharedCart({
            variables: {
                sharedCartId: sharedCartId,
            },
        })
            .then(() => {
                navigate(createCheckoutUrl(), {
                    replace: true,
                });
            })
            .catch((error) => {
                switch (error.message) {
                    case 'not_found':
                        setErrorOverlayState({
                            isShown: true,
                            type: ErrorOverlayType.SHARED_CART_NOT_FOUND,
                        });
                        break;
                    case 'invalid_contents':
                        setErrorOverlayState({
                            isShown: true,
                            type: ErrorOverlayType.SHARED_CART_INVALID_CONTENTS,
                        });
                        break;
                    default:
                        setErrorOverlayState({
                            isShown: true,
                        });
                        break;
                }
            });
    }, [cart, doApplySharedCart, setErrorOverlayState, sharedCartId]);

    return (
        <Container>
            <Section>
                <HeadingExtraLarge>Shared cart</HeadingExtraLarge>
                <Paragraph>Loading…</Paragraph>
            </Section>
        </Container>
    );
}
