import styled from 'styled-components';
import { VIEWPORT, GLYPH_INSPECTOR_BREAKPOINT } from '../settings/Global';
import React from 'react';

const AboveMobileWrapper = styled.span`
    display: none;
    @media screen and (min-width: ${VIEWPORT.MOBILE + 1}px) {
        display: initial;
    }
`;

export function AboveMobile({
    children,
    ...delegated
}: {
    children: React.ReactNode;
}): React.ReactElement {
    return <AboveMobileWrapper {...delegated}>{children}</AboveMobileWrapper>;
}

const MobileOnlyWrapper = styled.span`
    display: none;
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: initial;
    }
`;

export function MobileOnly({
    children,
    ...delegated
}: {
    children: React.ReactNode;
}): React.ReactElement {
    return <MobileOnlyWrapper {...delegated}>{children}</MobileOnlyWrapper>;
}

const UpToGlyphBreakpointWrapper = styled.span`
    display: none;
    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        display: initial;
    }
`;

export function UpToGlyphBreakpoint({
    children,
    ...delegated
}: {
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <UpToGlyphBreakpointWrapper {...delegated}>
            {children}
        </UpToGlyphBreakpointWrapper>
    );
}

const AboveGlyphBreakpointWrapper = styled.span`
    display: none;
    @media screen and (min-width: ${GLYPH_INSPECTOR_BREAKPOINT + 1}px) {
        display: initial;
    }
`;

export function AboveGlyphBreakpoint({
    children,
    ...delegated
}: {
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <AboveGlyphBreakpointWrapper {...delegated}>
            {children}
        </AboveGlyphBreakpointWrapper>
    );
}
