import React from 'react';
import styled from 'styled-components';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { LOZENGE_SPACING } from '../settings/Global';

const Label = styled.label`
    display: inline-flex;
    flex-direction: row;
    gap: ${LOZENGE_SPACING}px;
    align-items: center;
    cursor: pointer;
`;

const Root = styled(SwitchPrimitive.Root)`
    width: 34px;
    height: 20px;
    background-color: var(--backgroundColor);
    border: 1px solid var(--foregroundColor);
    border-radius: 9999px;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const Thumb = styled(SwitchPrimitive.Thumb)`
    display: block;
    width: 14px;
    height: 14px;
    background-color: var(--foregroundColorMix5);
    border-radius: 9999px;
    transform: translateX(3px);
    will-change: transform;

    &[data-state='checked'] {
        transform: translateX(15px);
        background-color: var(--foregroundColor);
    }
`;

interface SwitchProps extends SwitchPrimitive.SwitchProps {
    label?: string;
}

const Switch = React.forwardRef(
    (
        props: SwitchProps,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const label = props.label?.trim();
        return (
            <Label>
                {label}
                <Root ref={forwardedRef} {...props}>
                    <Thumb />
                </Root>
            </Label>
        );
    },
);
Switch.displayName = 'Switch';

export default Switch;
