import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LineHeightIcon } from '../assets/icons/line-height.svg';
import { ReactComponent as LetterSpacingIcon } from '../assets/icons/letter-spacing.svg';
import { ReactComponent as AlignLeftIcon } from '../assets/icons/align-left.svg';
import { ReactComponent as AlignRightIcon } from '../assets/icons/align-right.svg';
import { ReactComponent as AlignCenterIcon } from '../assets/icons/align-center.svg';
import { ReactComponent as AlignJustifiedIcon } from '../assets/icons/align-justified.svg';
import { ReactComponent as ColumnsOneIcon } from '../assets/icons/columns-one.svg';
import { ReactComponent as ColumnsTwoIcon } from '../assets/icons/columns-two.svg';
import { ReactComponent as ColumnsThreeIcon } from '../assets/icons/columns-three.svg';
import { ReactComponent as FontSizeIcon } from '../assets/icons/font-size.svg';

export type IconType =
    | 'LINE_HEIGHT'
    | 'LETTER_SPACING'
    | 'ALIGN_LEFT'
    | 'ALIGN_RIGHT'
    | 'ALIGN_CENTER'
    | 'ALIGN_JUSTIFIED'
    | 'COLUMNS_1'
    | 'COLUMNS_2'
    | 'COLUMNS_3'
    | 'FONT_SIZE';

const Wrapper = styled.span`
    width: var(--iconSize, 22px);
    height: var(--iconSize, 22px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.svg`
    height: 1em;
    width: 1em;
    fill: currentColor;
`;

function getSvgElementForType(
    type: IconType,
): React.ComponentType<React.SVGProps<SVGElement>> | never {
    switch (type) {
        case 'LINE_HEIGHT':
            return LineHeightIcon;
        case 'LETTER_SPACING':
            return LetterSpacingIcon;
        case 'ALIGN_LEFT':
            return AlignLeftIcon;
        case 'ALIGN_RIGHT':
            return AlignRightIcon;
        case 'ALIGN_CENTER':
            return AlignCenterIcon;
        case 'ALIGN_JUSTIFIED':
            return AlignJustifiedIcon;
        case 'COLUMNS_1':
            return ColumnsOneIcon;
        case 'COLUMNS_2':
            return ColumnsTwoIcon;
        case 'COLUMNS_3':
            return ColumnsThreeIcon;
        case 'FONT_SIZE':
            return FontSizeIcon;
        default: {
            throw new Error(`Unhandled IconType: ${type}`);
        }
    }
}

interface Props {
    type: IconType;
    className?: string; // makes this component extendable with `styled()`
    // size; configured with 'font-size' or `--iconSize`
    title?: string;
}

function SVGIcon({ type, className, title }: Props): React.ReactElement {
    return (
        <Wrapper className={className} title={title}>
            <Icon as={getSvgElementForType(type)} />
        </Wrapper>
    );
}

export default React.memo(SVGIcon);
