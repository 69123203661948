import React from 'react';
import { v4 as uuid } from 'uuid';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Popover from '@radix-ui/react-popover';
import type { FontFamily, FontFamilyGroup } from './PageContext';
import type { InUseCategoriesQuery } from '../gql/api-ssr';
import Link, {
    A as LozengeA,
    Button as LozengeButton,
    LOZENGE_BORDER_RADIUS,
    LOZENGE_VERTICAL_PADDING,
} from './Lozenge';
import { Select, SelectItem } from './Select';
import {
    createAboutUrl,
    createAccountUrl,
    createAwardsAndAccoladesUrl,
    createBlogIndexUrl,
    createBuyUrl,
    createContactUrl,
    createCustomFontsMenuUrl,
    createEulaOverviewUrl,
    createFaqUrl,
    createFontsInUseUrl,
    createFontsMenuUrl,
    createHomeUrl,
    createPreReleaseTestFontsUrl,
    createRefundPaymentPolicyUrl,
    createTestFontsUrl,
} from '../utils/urlHelper';
import { useOnScroll } from '../utils/ScrollHelper';
import QuantityBadge from './QuantityBadge';
import {
    CART_CONTAINER_ID,
    COLOR,
    LOZENGE_SPACING,
    NAVBAR_VERTICAL_MARGIN,
    VIEWPORT,
    ZINDEX,
} from '../settings/Global';
import useCartQuery from '../hooks/useCartQuery';
import { TEST_ID } from '../settings/E2e';
import useIsCartEmpty from '../hooks/useIsCartEmpty';
import { useGlobalState } from './GlobalRuntimeState';
import Icon, { IconType, SocialIcon } from './Icon';
import { SOCIAL_LINK_DEFS } from '../settings/Social';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import isBrowser from '../utils/isBrowser';
import isExternalUrl from '../utils/isExternalUrl';
import { useCycleColorScheme } from './ColorSchemeContext';
import CartSummary from './CartSummary';
import clamp from '../utils/clamp';
import useUserQuery from '../hooks/useUserQuery';
import useConfig from '../hooks/useConfig';
import useHasMounted from '../hooks/useHasMounted';
import { hideScrollbars } from '../utils/stylesMixins';

const SEPARATOR = 'sep';
const BREAKPOINT = VIEWPORT.MOBILE;
export const EXPANDED_LABEL_CLASSNAME = 'expanded-label';

export type NavLinkProps = {
    url: string;
    label: React.ReactNode;
    selected?: boolean;
    e2eId?: TEST_ID;
    // Optional metadata, used when creating links via createPages
    metadata?: {
        fontFamily?: FontFamily;
        fontFamilyGroup?: FontFamilyGroup;
        inUseCategory?: InUseCategoriesQuery['ssr']['inUseCategories'][number];
    };
};

export type BreadCrumb = NavLinkProps | NavLinkProps[] | NavLinkProps[][];

const NavbarLink = styled(Link)``;

const SkipToContent = styled.a`
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    font-size: 1px;
`;

const MenuRoot = styled(NavigationMenu.Root)<{
    $navColors?: {
        background: string;
        foreground: string;
        hoverBackground?: string;
        hoverForeground?: string;
    };
    $breadcrumbCount?: number;
}>`
    position: fixed;
    @media print {
        position: absolute;
    }
    top: 0;
    z-index: ${ZINDEX.NAVBAR};
    padding: var(--navbarVerticalMargin) var(--gridMarginGap);

    ${({ $navColors }): FlattenSimpleInterpolation | null =>
        $navColors
            ? css`
                  --lozengeBackgroundColor: ${$navColors.background};
                  --lozengeColor: ${$navColors.foreground};
                  --lozengeBackgroundHoverColor: ${$navColors.hoverBackground ||
                  $navColors.foreground};
                  --lozengeHoverColor: ${$navColors.hoverForeground ||
                  $navColors.background};
              `
            : null}
`;

const NavList = styled(NavigationMenu.List)`
    display: flex;
    flex-direction: row;
    column-gap: ${LOZENGE_SPACING}px;
`;

const NavListLeft = styled(NavList)`
    @media screen and (max-width: ${BREAKPOINT}px) {
        flex-direction: column;
        row-gap: ${LOZENGE_SPACING}px;
    }
`;

const NavItem = styled(NavigationMenu.Item)``;

const SelectWrap = styled.span`
    margin-right: ${LOZENGE_SPACING}px;
`;

const RightMenuSecondaryItem = styled(NavigationMenu.Item)`
    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const LeftMenuRoot = styled(MenuRoot)`
    @media screen and (max-width: ${BREAKPOINT}px) {
        will-change: transform;

        ${NavItem}:not(:last-child) {
            will-change: opacity;
        }
    }

    ${({ $breadcrumbCount }): FlattenSimpleInterpolation | null =>
        $breadcrumbCount && $breadcrumbCount >= 4
            ? css`
                  @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) and (min-width: ${VIEWPORT.MOBILE +
                      1}px) {
                      ${NavItem} .${EXPANDED_LABEL_CLASSNAME} {
                          display: none;
                      }
                  }
              `
            : null}
`;

const RightMenuRoot = styled(MenuRoot)`
    right: 0;
`;

const NavViewportPosition = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100%;
    left: 0;
`;

const SubNavViewport = styled(NavigationMenu.Viewport)`
    position: relative;
    transform-origin: top center;
    width: 100%;
    height: 100%;
`;

const dropdownStyles = css`
    background-color: var(--menuBackgroundColor);

    --linkColor: var(--foregroundColor);
    --linkHoverColor: var(--backgroundColor);
    --lozengeBackgroundColor: var(--menuBackgroundColor);

    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    padding: ${LOZENGE_VERTICAL_PADDING}px;

    /* Allow the menu to scroll if the viewport height isn't sufficient to display it fully */
    max-height: calc(
        var(--radix-popper-available-height) - var(--gridMarginGap)
    );
    --radix-popper-inside-available-height: calc(
        var(--radix-popper-available-height) - ${2 * LOZENGE_VERTICAL_PADDING}px -
            var(--gridMarginGap)
    );
    overflow-y: scroll;

    ${hideScrollbars};
`;

const DropdownMenuGridWrapper = styled(DropdownMenu.Content)`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    padding: 0 var(--gridMarginGap);
    grid-column-gap: var(--gridColumnGap);
    width: 100vw;
    max-width: var(--radix-popper-available-width);

    z-index: ${ZINDEX.MENU_OVERLAY};
    pointer-events: none;
`;

const DropdownMenuContent = styled.div`
    grid-column: 11 / -1;

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 10 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 9 / -1;
    }
    pointer-events: auto;

    ${dropdownStyles};
    padding-bottom: var(--gridMarginGap);
    --radix-popper-inside-available-height: calc(
        var(--radix-popper-available-height) - ${LOZENGE_VERTICAL_PADDING}px -
            var(--gridMarginGap)
    );
`;

const CartGridWrapper = styled(Popover.Content)<{
    $isBuyOrCheckoutPage?: boolean;
}>`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    padding: 0 var(--gridMarginGap);
    grid-column-gap: var(--gridColumnGap);
    width: 100vw;
    max-width: var(--radix-popper-available-width);
    z-index: ${ZINDEX.NAVBAR};
    pointer-events: none;

    ${({ $isBuyOrCheckoutPage }): FlattenSimpleInterpolation | null =>
        $isBuyOrCheckoutPage
            ? css`
                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      display: none;
                  }
              `
            : null}
`;
const CartContentWrapper = styled.div<{ $isMenuOpen: boolean }>`
    ${dropdownStyles};

    padding: var(--cartPaddingTop) var(--cartPaddingRight)
        var(--cartPaddingBottom) var(--cartPaddingLeft);
    --radix-popper-inside-available-height: calc(
        var(--radix-popper-available-height) - var(--cartPaddingBottom) -
            var(--gridMarginGap) * 2
    );

    grid-column: 9 / -1;
    pointer-events: auto;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;

        --radix-popper-inside-available-height: calc(
            var(--radix-popper-available-height) - var(--cartPaddingBottom) -
                var(--gridMarginGap) * 2 - var(--navbarVerticalMargin)
        );
    }

    ${({ $isMenuOpen }): FlattenSimpleInterpolation | null =>
        $isMenuOpen
            ? css`
                  opacity: 0.4;
              `
            : null}
`;

const DropdownMenuSeparator = styled(DropdownMenu.Separator)`
    min-height: 1px;
    background-color: var(--borderColor);
    margin: 5px;
`;

const subNavLinkStyles = css`
    display: flex;
`;

const SubNavLink = styled(NavbarLink)`
    ${subNavLinkStyles};
`;

const SubNavA = styled(LozengeA)`
    ${subNavLinkStyles};
`;

const SubNavButton = styled(LozengeButton)`
    ${subNavLinkStyles};
`;

const SocialLink = styled(SubNavA)`
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &[data-first='true'] {
        margin-left: 5px;
    }
    &:last-child {
        margin-right: 5px;
    }
`;

const BuyLink = styled(NavbarLink)`
    color: ${COLOR.WHITE};
    background-color: ${COLOR.RED};
`;

const BuyLinkSecondaryText = styled.span`
    @media screen and (max-width: ${BREAKPOINT}px) {
        display: none;
    }
`;

const mainMenuLinks: (NavLinkProps | typeof SEPARATOR)[] = [
    { label: 'Home', url: createHomeUrl() },
    SEPARATOR,
    {
        label: 'Fonts',
        url: createFontsMenuUrl(),
        e2eId: TEST_ID.NAV_ITEM_FONTS,
    },
    { label: 'Fonts in use', url: createFontsInUseUrl() },
    { label: 'Custom fonts', url: createCustomFontsMenuUrl() },
    { label: 'Test fonts', url: createTestFontsUrl() },
    SEPARATOR,
    { label: 'Account', url: createAccountUrl() },
    { label: 'Blog', url: createBlogIndexUrl() },
    SEPARATOR,
    { label: 'About', url: createAboutUrl() },
    { label: 'Interviews', url: createBlogIndexUrl('interviews') },
    { label: 'Awards', url: createAwardsAndAccoladesUrl() },
    SEPARATOR,
    { label: 'Contact', url: createContactUrl() },
    { label: 'FAQs', url: createFaqUrl() },
    { label: 'Font licences', url: createEulaOverviewUrl() },
    { label: 'Payments & refunds', url: createRefundPaymentPolicyUrl() },
    SEPARATOR,
];

const MenuButton = styled(LozengeButton)`
    justify-content: center;
    width: 2.2975em;
`;

const LoadingIcon = styled.div`
    position: absolute;
    border-radius: 50%;
    background-color: var(--lozengeColor);
    width: 16px;
    height: 16px;
    animation: mouth 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite
        alternate;

    @keyframes mouth {
        0% {
            clip-path: polygon(
                100% 0,
                100% 100%,
                0 100%,
                0 75%,
                50% 50%,
                0 25%,
                0 0
            );
        }
        100% {
            clip-path: polygon(
                100% 0,
                100% 100%,
                0 100%,
                0 50%,
                50% 50%,
                0 50%,
                0 0
            );
        }
    }
`;

function Navbar({
    breadcrumbs,
    fontFamily,
    fontFamilyGroup,
    hideCartCheckoutButton,
    isBuyOrCheckoutPage,
    isBuyPage,
}: {
    breadcrumbs: BreadCrumb[] | undefined;
    fontFamily: FontFamily | undefined;
    fontFamilyGroup?: FontFamilyGroup;
    hideCartCheckoutButton: boolean | undefined;
    isBuyOrCheckoutPage: boolean | undefined;
    isBuyPage: boolean | undefined;
}): React.ReactElement {
    const isMounted = useHasMounted();
    const cartButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const cycleColorScheme = useCycleColorScheme();
    const [isCartShown, setIsCartShown] = useGlobalState('isCartShown');
    const [isMenuOpen, setIsMenuOpen] = useGlobalState('isMenuOpen');
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [navColors] = useGlobalState('navColors');
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const cartIsEmpty = useIsCartEmpty(cart);
    const location = useLocation();
    const leftMenuNavListRef = React.useRef<HTMLUListElement | null>(null);
    const [stickyItemYPos, setStickyItemYPos] = React.useState<number>();
    const [leftMenuYOffset, setLeftMenuYOffset] = React.useState<number>(0);
    const [nonStickyItemOpacity, setNonStickyItemOpacity] =
        React.useState<number>(1);
    const config = useConfig();
    const userQuery = useUserQuery();
    const [isAdminColorPickerShown, setIsAdminColorPickerShown] =
        useGlobalState('isAdminColorPickerShown');
    const showAdminOptions =
        !config.isProductionSite || userQuery.data?.user?.isAdmin;

    // Re-calculate --navbarHeight when y-position of the sticky item changes
    React.useEffect(() => {
        if (!leftMenuNavListRef.current) {
            return;
        }
        document.documentElement.style.setProperty(
            '--navbarCalculatedHeight',
            `${
                leftMenuNavListRef.current.getBoundingClientRect().height +
                NAVBAR_VERTICAL_MARGIN * 2
            }px`,
        );
    }, [stickyItemYPos]);

    // Calculate the position of the nav item that should be sticky on mobile
    React.useEffect(() => {
        if (
            !leftMenuNavListRef.current ||
            !leftMenuNavListRef.current.lastElementChild
        ) {
            return;
        }
        setStickyItemYPos(
            (leftMenuNavListRef.current.lastElementChild as HTMLElement)
                .offsetTop,
        );
        // These dependencies make the position re-calculate
    }, [leftMenuNavListRef, location, viewportWidth]);

    const handleScroll = React.useCallback((): void => {
        if (
            stickyItemYPos === undefined ||
            !viewportWidth ||
            viewportWidth > VIEWPORT.TABLET ||
            !isBrowser() ||
            window.scrollY <= 0
        ) {
            // Reset
            setLeftMenuYOffset(0);
            setNonStickyItemOpacity(1);
            return;
        }
        const newLeftMenuYOffset = clamp(-window.scrollY, -stickyItemYPos, 0);
        const newOpacity = 1 + newLeftMenuYOffset / stickyItemYPos;
        setLeftMenuYOffset(newLeftMenuYOffset);
        setNonStickyItemOpacity(newOpacity);
    }, [viewportWidth, stickyItemYPos]);
    useOnScroll({ onScrollDown: handleScroll, onScrollUp: handleScroll });

    // Show cart on Buy/Checkout pages
    React.useEffect(() => {
        if (
            !isBuyOrCheckoutPage ||
            (viewportWidth && viewportWidth <= VIEWPORT.MOBILE)
        ) {
            return;
        }
        setIsCartShown(true);
    }, [isBuyOrCheckoutPage, viewportWidth]);

    // When navigating away from a page to a non-checkout page,
    // close the cart...
    React.useEffect(() => {
        if (isBuyOrCheckoutPage || !isCartShown) {
            return;
        }
        setIsCartShown(false);
    }, [location.href]);

    // Trigger scroll when needed, e.g. when changing viewport width or when
    // the position of the sticky item changes
    React.useEffect((): void => {
        if (!viewportWidth || stickyItemYPos === undefined) {
            return;
        }
        handleScroll();
    }, [viewportWidth, stickyItemYPos, leftMenuYOffset]);

    const themeToggle = (
        <NavigationMenu.Link asChild>
            <LozengeButton onClick={cycleColorScheme}>
                <Icon type={IconType.THEME_TOGGLE} />
            </LozengeButton>
        </NavigationMenu.Link>
    );

    const cartButtonContents = (
        <>
            Cart&nbsp;
            <QuantityBadge
                quantity={cartIsEmpty ? 0 : cart?.items.length || 0}
            />
        </>
    );

    return (
        <>
            <SkipToContent id="top" href="#main">
                Skip to content
            </SkipToContent>
            {/*

            Left side menu (breadcrumbs)

            */}
            <LeftMenuRoot
                style={
                    leftMenuYOffset
                        ? {
                              transform: `translateY(${leftMenuYOffset}px)`,
                          }
                        : undefined
                }
                $navColors={navColors}
                $breadcrumbCount={
                    /* This makes nav items fit better for family pages */
                    fontFamily && Array.isArray(breadcrumbs)
                        ? breadcrumbs.length
                        : 1
                }
            >
                <NavListLeft ref={leftMenuNavListRef}>
                    {breadcrumbs?.map((crumb, idx) => {
                        const navItemStyle =
                            idx < breadcrumbs.length - 1 &&
                            nonStickyItemOpacity < 1
                                ? {
                                      opacity: nonStickyItemOpacity,
                                  }
                                : undefined;
                        if (Array.isArray(crumb)) {
                            if (!crumb.length) {
                                return null;
                            }
                            return (
                                <NavItem key={uuid()} style={navItemStyle}>
                                    {(
                                        (Array.isArray(crumb[0])
                                            ? crumb
                                            : [crumb]) as NavLinkProps[][]
                                    ).map((crumby, idx2) => {
                                        const selectKey = `navSelect-${idx}-${idx2}`;
                                        // See if there's a specifically-selected value
                                        const selectedValue =
                                            crumby.find(
                                                (crumb) =>
                                                    crumb.selected === true,
                                            )?.url ||
                                            crumby.find((c) =>
                                                c.url.includes('#')
                                                    ? c.url == location.href
                                                    : c.url ==
                                                      location.pathname,
                                            )?.url ||
                                            crumby[0].url;
                                        return (
                                            <SelectWrap key={selectKey}>
                                                <Select
                                                    defaultValue={selectedValue}
                                                    onValueChange={(
                                                        val,
                                                    ): Promise<void> => {
                                                        return navigate(val);
                                                    }}
                                                    sideOffset={
                                                        NAVBAR_VERTICAL_MARGIN
                                                    }
                                                >
                                                    {crumby.map((crumbItem) => (
                                                        <SelectItem
                                                            value={
                                                                crumbItem.url
                                                            }
                                                            key={crumbItem.url}
                                                        >
                                                            {crumbItem.label}
                                                        </SelectItem>
                                                    ))}
                                                </Select>
                                            </SelectWrap>
                                        );
                                    })}
                                </NavItem>
                            );
                        }
                        return (
                            <NavItem key={crumb.url} style={navItemStyle}>
                                <NavigationMenu.Link asChild>
                                    {isExternalUrl(crumb.url, location) ? (
                                        <LozengeA href={crumb.url}>
                                            {crumb.label}
                                        </LozengeA>
                                    ) : (
                                        <NavbarLink to={crumb.url}>
                                            {crumb.label}
                                        </NavbarLink>
                                    )}
                                </NavigationMenu.Link>
                            </NavItem>
                        );
                    })}
                </NavListLeft>
            </LeftMenuRoot>
            {/*

            Right side menu

            */}
            <RightMenuRoot $navColors={navColors}>
                <NavList>
                    {(fontFamily || fontFamilyGroup) && !isBuyOrCheckoutPage ? (
                        <>
                            {!fontFamilyGroup ||
                            fontFamilyGroup.campaign === null ? (
                                cartIsEmpty ? (
                                    <NavigationMenu.Item>
                                        {themeToggle}
                                    </NavigationMenu.Item>
                                ) : (
                                    <RightMenuSecondaryItem>
                                        {themeToggle}
                                    </RightMenuSecondaryItem>
                                )
                            ) : null}
                            <RightMenuSecondaryItem>
                                {fontFamily ||
                                (fontFamilyGroup &&
                                    fontFamilyGroup.fontFamilies.some(
                                        (ff) => !ff.isPreRelease,
                                    )) ? (
                                    <NavigationMenu.Link asChild>
                                        <NavbarLink
                                            to={
                                                fontFamily &&
                                                fontFamily.isPreRelease
                                                    ? createPreReleaseTestFontsUrl(
                                                          fontFamily.id,
                                                      )
                                                    : createTestFontsUrl()
                                            }
                                        >
                                            Download test fonts
                                        </NavbarLink>
                                    </NavigationMenu.Link>
                                ) : null}
                            </RightMenuSecondaryItem>
                            <NavigationMenu.Item>
                                <NavigationMenu.Link asChild>
                                    <BuyLink
                                        to={createBuyUrl(
                                            fontFamily
                                                ? fontFamily.fontFamilyGroup
                                                      .slug
                                                : fontFamilyGroup
                                                ? fontFamilyGroup.slug
                                                : '',
                                        )}
                                        state={
                                            fontFamily
                                                ? {
                                                      fromFontFamilyId:
                                                          fontFamily.id,
                                                  }
                                                : undefined
                                        }
                                    >
                                        Buy
                                        <BuyLinkSecondaryText>
                                            &nbsp;fonts
                                        </BuyLinkSecondaryText>
                                    </BuyLink>
                                </NavigationMenu.Link>
                            </NavigationMenu.Item>
                        </>
                    ) : null}
                    {(!cartIsEmpty || isBuyOrCheckoutPage) && (
                        <NavigationMenu.Item>
                            <Popover.Root
                                open={
                                    isCartShown || Boolean(isBuyOrCheckoutPage)
                                }
                                onOpenChange={setIsCartShown}
                            >
                                <Popover.Trigger asChild>
                                    {!isBuyOrCheckoutPage ? (
                                        <LozengeButton
                                            ref={cartButtonRef}
                                            data-cy={TEST_ID.NAV_ITEM_CART}
                                        >
                                            {cartButtonContents}
                                        </LozengeButton>
                                    ) : (
                                        <LozengeA
                                            href={`#${CART_CONTAINER_ID}`}
                                        >
                                            {cartButtonContents}
                                        </LozengeA>
                                    )}
                                </Popover.Trigger>
                                <Popover.Portal>
                                    <CartGridWrapper
                                        align="end"
                                        sideOffset={NAVBAR_VERTICAL_MARGIN}
                                        $isBuyOrCheckoutPage={
                                            isBuyOrCheckoutPage
                                        }
                                    >
                                        <CartContentWrapper
                                            $isMenuOpen={
                                                Boolean(isBuyOrCheckoutPage) &&
                                                isMenuOpen
                                            }
                                        >
                                            <CartSummary
                                                hideCheckoutButton={
                                                    hideCartCheckoutButton
                                                }
                                                isBuyPage={isBuyPage}
                                            />
                                        </CartContentWrapper>
                                    </CartGridWrapper>
                                </Popover.Portal>
                            </Popover.Root>
                        </NavigationMenu.Item>
                    )}
                    <NavigationMenu.Item>
                        <DropdownMenu.Root
                            open={isMenuOpen}
                            onOpenChange={setIsMenuOpen}
                            modal={false}
                        >
                            <DropdownMenu.Trigger asChild>
                                <MenuButton
                                    data-cy={TEST_ID.NAV_ITEM_MAIN_MENU}
                                    title={
                                        isMounted
                                            ? undefined
                                            : 'Loading, please wait'
                                    }
                                >
                                    {isMounted ? null : <LoadingIcon />}
                                    <Icon
                                        type={IconType.HAMBURGER}
                                        style={
                                            isMounted
                                                ? undefined
                                                : { opacity: 0 }
                                        }
                                    />
                                </MenuButton>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Portal>
                                <DropdownMenuGridWrapper
                                    align="end"
                                    sideOffset={NAVBAR_VERTICAL_MARGIN}
                                >
                                    <DropdownMenuContent>
                                        {mainMenuLinks.map((menuLink, idx) => {
                                            if (menuLink === SEPARATOR) {
                                                return (
                                                    <DropdownMenuSeparator
                                                        key={`sep${idx}`}
                                                    />
                                                );
                                            }
                                            return (
                                                <DropdownMenu.Item
                                                    asChild
                                                    key={menuLink.url}
                                                >
                                                    {isExternalUrl(
                                                        menuLink.url,
                                                        location,
                                                    ) ? (
                                                        <SubNavA
                                                            href={menuLink.url}
                                                            data-cy={
                                                                menuLink.e2eId
                                                            }
                                                        >
                                                            {menuLink.label}
                                                        </SubNavA>
                                                    ) : (
                                                        <SubNavLink
                                                            to={menuLink.url}
                                                            data-cy={
                                                                menuLink.e2eId
                                                            }
                                                        >
                                                            {menuLink.label}
                                                        </SubNavLink>
                                                    )}
                                                </DropdownMenu.Item>
                                            );
                                        })}
                                        {showAdminOptions ? (
                                            <>
                                                <DropdownMenu.Item asChild>
                                                    <SubNavButton
                                                        onClick={(): void => {
                                                            setIsAdminColorPickerShown(
                                                                !isAdminColorPickerShown,
                                                            );
                                                        }}
                                                    >
                                                        Colour picker
                                                    </SubNavButton>
                                                </DropdownMenu.Item>
                                                <DropdownMenuSeparator />
                                            </>
                                        ) : null}
                                        {SOCIAL_LINK_DEFS.map(
                                            (socialLink, idx) => {
                                                return (
                                                    <DropdownMenu.Item
                                                        asChild
                                                        key={socialLink.url}
                                                    >
                                                        <SocialLink
                                                            href={
                                                                socialLink.url
                                                            }
                                                            title={
                                                                socialLink.title
                                                            }
                                                            data-first={
                                                                idx === 0
                                                            }
                                                        >
                                                            <SocialIcon
                                                                type={
                                                                    socialLink.iconType
                                                                }
                                                            />
                                                        </SocialLink>
                                                    </DropdownMenu.Item>
                                                );
                                            },
                                        )}
                                    </DropdownMenuContent>
                                </DropdownMenuGridWrapper>
                            </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                    </NavigationMenu.Item>
                </NavList>
                <NavViewportPosition>
                    <SubNavViewport />
                </NavViewportPosition>
            </RightMenuRoot>
        </>
    );
}

export default React.memo(Navbar);
