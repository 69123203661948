import React from 'react';
import FontFace from './FontFace';
import type { FontFamily, FontFamilyGroup } from './PageContext';
import type { Font } from './FontFace';
import { createStaticAssetUrl } from '../utils/urlHelper';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import notUndefined from '../utils/notUndefined';

type UnionFontFamily = FontFamilyGroup['fontFamilies'][number] | FontFamily;
type UnionFontStyle = UnionFontFamily['fontStyles'][number];

function GlobalRemoteFonts({
    fontFamily,
    fontFamilyGroup,
}: {
    fontFamily?: FontFamily;
    fontFamilyGroup?: FontFamilyGroup;
}): React.ReactElement | null {
    if (fontFamily || fontFamilyGroup) {
        const fontFamilies: UnionFontFamily[] = fontFamilyGroup?.fontFamilies
            ? fontFamilyGroup.fontFamilies
            : [];
        if (fontFamily) {
            fontFamilies.push(fontFamily);
        }
        const fontStyles = fontFamilies.reduce(
            (carry: UnionFontStyle[], fontFamily): UnionFontStyle[] =>
                carry.concat(fontFamily.fontStyles),
            [],
        );

        const remoteFonts = fontStyles
            .map((fontStyle): Font | undefined => {
                if (!fontStyle.woff2) {
                    return;
                }
                return {
                    fontFamily: getCssFontFamilyNameFromId(fontStyle.id),
                    fontWeight: fontStyle.weight,
                    fontStyle: 'normal',
                    woff2: createStaticAssetUrl(fontStyle.woff2),
                };
            })
            .filter(notUndefined);

        if (remoteFonts.length) {
            return <FontFace fonts={remoteFonts} preventFallbackFont />;
        }
    }

    return null;
}

export default React.memo(GlobalRemoteFonts);
