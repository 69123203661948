import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';

const Container = styled.div<{
    isPositionFixed: boolean;
}>`
    position: ${({ isPositionFixed }): string =>
        isPositionFixed ? 'fixed' : 'relative'};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

const Bar = styled(animated.div)<{
    $colorForeground: string | null | undefined;
    $colorBackground: string | null | undefined;
}>`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(
        311deg,
        ${({ $colorForeground }): string =>
            $colorForeground ? $colorForeground : 'var(--foregroundColorMix7)'},
        ${({ $colorBackground }): string =>
            $colorBackground ? $colorBackground : 'var(--backgroundColor)'}
    );
`;

const STEP_DELAY = 1500;

function ProgressBar({
    loading,
    isPositionFixed = true,
    colorForeground,
    colorBackground,
}: {
    loading: boolean;
    isPositionFixed?: boolean;
    colorForeground?: string | null;
    colorBackground?: string | null;
}): React.ReactElement {
    // Force foreground colour-based re-render by using state
    const [fgCol, setFgCol] = React.useState<string>();
    const [fakeProgress, setFakeProgress] = React.useState<number>(50);

    React.useEffect(() => {
        if (fgCol !== colorForeground && colorForeground) {
            setFgCol(colorForeground);
        }
    }, [colorForeground]);

    // Start incrementing the progress bar on an interval.
    React.useEffect((): (() => void) => {
        const timeout = setTimeout(
            (): void =>
                setFakeProgress((state): number =>
                    // Increment the state if it's not yet at 90%.
                    state < 90 ? state + 10 : state,
                ),
            STEP_DELAY,
        );

        return (): void => clearTimeout(timeout);
    }, [fakeProgress]);

    const progressBarSpring = useSpring({
        // Start offscreen ...
        from: { transform: 'translate3d(-100%,0,0)', opacity: 0 },
        to: loading
            ? {
                  // ... then slide-in bit-by-bit, never quite reaching the end ...
                  transform: `translate3d(-${-100 + fakeProgress}%,0,0)`,
                  opacity: 1,
              }
            : {
                  // ... then slide the rest of the way in (and fade-out) when 'complete'!
                  transform: `translate3d(0,0,0)`,
                  opacity: 0,
              },
    });

    return (
        <Container isPositionFixed={isPositionFixed}>
            <Bar
                style={progressBarSpring}
                $colorForeground={fgCol}
                $colorBackground={colorBackground}
            />
        </Container>
    );
}

export default React.memo(ProgressBar);
