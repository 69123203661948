import React from 'react';
import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { hiddenButFocusable } from '../utils/stylesMixins';
import useLandscapePortrait from '../hooks/useLandscapePortrait';
import getScaleToCoverViewport from '../utils/getScaleToCoverViewport';
import type { CampaignLayoutMode } from '../union-types/campaign';
import type { VimeoVideo, VimeoVideoFile } from '../union-types/vimeoVideo';
import { useGlobalState } from './GlobalRuntimeState';
import isExternalUrl from '../utils/isExternalUrl';
import { useLocation } from '@reach/router';

const Container = styled.div<{
    $colorBackground?: string | null;
    $withinPageMargins?: boolean;
}>`
    height: 100%;
    display: block;
    position: relative;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    background-color: ${({ $colorBackground }): string =>
        $colorBackground ? $colorBackground : 'transparent'};
`;

const linkStyles = css`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Link = styled.a`
    ${linkStyles};
`;

const InternalLink = styled(GatsbyLink)`
    ${linkStyles};
`;

const AccessibleLinkSpan = styled.span`
    ${hiddenButFocusable}
`;

const Video = styled.video<{
    width: number;
    height: number;
    showWithinGrid?: boolean;
    marginOverlapPercentage?: number | null;
}>`
    position: absolute;

    ${({
        showWithinGrid,
        width,
        height,
        marginOverlapPercentage,
    }): FlattenSimpleInterpolation => {
        return showWithinGrid
            ? css`
                  bottom: 0;
                  margin: 0
                      ${marginOverlapPercentage
                          ? `calc(var(--gridMarginGap) - ${
                                marginOverlapPercentage / 2
                            }%)`
                          : 'var(--gridMarginGap)'};
              `
            : css`
                  top: 50%;
                  left: 50%;
                  margin-left: ${((-1 * width) / 2).toString()}px;
                  margin-top: ${((-1 * height) / 2).toString()}px;
              `;
    }};

    width: ${({ width }): string => `${width.toString()}px`};
    height: ${({ height }): string => `${height.toString()}px`};
`;

export interface VideoMeta {
    video: VimeoVideo;
    width?: number;
    height?: number;
    layoutMode?: CampaignLayoutMode;
    marginOverlap?: number;
}

export type TwoVideoMeta = {
    colorLoaderBackground: string | null;
    colorLoaderForeground: string | null;
    landscape: VideoMeta;
    portrait?: VideoMeta;
};

function VideoFullBleed({
    landscapeVideo,
    portraitVideo,
    url,
    linkText,
    colorBackground,
    lazyLoad,
    withinPageMargins,
}: {
    landscapeVideo: VideoMeta;
    portraitVideo?: VideoMeta;
    url?: string;
    linkText?: string;
    colorBackground?: string | null;
    lazyLoad?: boolean;
    withinPageMargins?: boolean;
}): React.ReactElement | null {
    const location = useLocation();
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [viewportHeight] = useGlobalState('viewportHeight');
    const [viewportWidthWithoutMargins] = useGlobalState(
        'viewportWidthWithoutMargins',
    );

    const mode = useLandscapePortrait();
    const [videoCanPlay, setVideoCanPlay] = React.useState<boolean>(false);
    const [inViewContainerRef, isInView] = useInView({
        triggerOnce: true,
        rootMargin: `80% 0px`,
    });

    // Don't render this without proper viewport sizing
    if (!viewportWidth || !viewportHeight || !viewportWidthWithoutMargins) {
        return null;
    }

    const landscapeVideoFiles = landscapeVideo.video.files as VimeoVideoFile[];
    const portraitVideoFiles =
        (portraitVideo?.video?.files as VimeoVideoFile[]) || [];

    const isLandscape =
        (mode === 'LANDSCAPE' || portraitVideoFiles.length === 0) &&
        landscapeVideoFiles.length;
    const isPortrait = !isLandscape && portraitVideoFiles.length;

    const showWithinGrid =
        portraitVideo && isPortrait
            ? portraitVideo.layoutMode === 'WITHIN_GRID'
            : landscapeVideo.layoutMode === 'WITHIN_GRID';
    const marginOverlapPercentage = showWithinGrid
        ? portraitVideo && isPortrait
            ? portraitVideo.marginOverlap
            : landscapeVideo.marginOverlap
        : withinPageMargins
        ? 0
        : undefined;

    const maxWidth =
        showWithinGrid || withinPageMargins
            ? viewportWidthWithoutMargins
            : viewportWidth;

    // Get ideal video for this viewport
    const videoFile: VimeoVideoFile | undefined = isLandscape
        ? landscapeVideoFiles.reduce(
              (previousValue, currentValue): VimeoVideoFile => {
                  if (
                      previousValue.width < maxWidth &&
                      (currentValue.width >= maxWidth ||
                          currentValue.width <= maxWidth)
                  ) {
                      return currentValue;
                  }
                  return previousValue;
              },
          )
        : portraitVideoFiles.length
        ? portraitVideoFiles.reduce(
              (previousValue, currentValue): VimeoVideoFile => {
                  if (
                      previousValue.height < viewportHeight &&
                      (currentValue.height >= viewportHeight ||
                          currentValue.height <= viewportHeight)
                  ) {
                      return currentValue;
                  }
                  return previousValue;
              },
          )
        : undefined;

    if (!videoFile) {
        return null;
    }

    const videoDimensions = getScaleToCoverViewport(
        videoFile.width / videoFile.height,
        maxWidth,
        viewportHeight,
        showWithinGrid ? maxWidth : undefined,
        showWithinGrid || withinPageMargins
            ? marginOverlapPercentage
            : undefined,
    );

    if (!videoDimensions) {
        return null;
    }

    return (
        <Container
            $colorBackground={colorBackground}
            $withinPageMargins={withinPageMargins}
            ref={lazyLoad ? inViewContainerRef : undefined}
        >
            {!lazyLoad || isInView ? (
                <Video
                    key={`${videoFile?.src}-${mode}`} // Ensures video reloads when aspect changes
                    autoPlay
                    playsInline
                    loop
                    muted
                    preload="auto"
                    title={linkText || ''}
                    width={videoDimensions[0]}
                    height={videoDimensions[1]}
                    onCanPlay={(): void =>
                        !videoCanPlay ? setVideoCanPlay(true) : undefined
                    }
                    showWithinGrid={showWithinGrid}
                    marginOverlapPercentage={
                        showWithinGrid ? marginOverlapPercentage : undefined
                    }
                >
                    <source src={videoFile?.src} type={videoFile?.type} />
                </Video>
            ) : null}
            {url ? (
                isExternalUrl(url, location) ? (
                    <Link href={url}>
                        <AccessibleLinkSpan>
                            {linkText || null}
                        </AccessibleLinkSpan>
                    </Link>
                ) : (
                    <InternalLink to={url}>
                        <AccessibleLinkSpan>
                            {linkText || null}
                        </AccessibleLinkSpan>
                    </InternalLink>
                )
            ) : null}
        </Container>
    );
}

export default React.memo(VideoFullBleed);
