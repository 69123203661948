import React from 'react';
import styled from 'styled-components';
import notNull from '../utils/notNull';
import { BlogSpacer, BlogSpacerLineHeight } from './BlogPostStyles';
import { BlogBodyVideo } from './BlogPostPartVideo';
import { BlogBodyTextWithFootNotes } from './BlogPostPartString';
import { BlogBodyHeading } from './BlogPostPartHeading';
import BlogPostHeader from './BlogPostHeader';
import { BlogBodyQuote } from './BlogPostPartQuote';
import { StreamFieldImageSection } from './StreamFieldImageSection';
import { BlogBodyQa } from './BlogPostPartQAndA';
import FeaturedFonts from './FeaturedFonts';
import { BodyFootnoteFooter } from './BlogPostPartFooter';
import { BlogRelatedPosts } from './BlogRelatedPosts';
import { insertFootnotesInBody, splitFootnotes } from '../utils/blogFootnotes';
import { invertColorVariables } from '../utils/stylesMixins';
import processWagtailRichText from '../utils/processWagtailRichText';
import useConfig from '../hooks/useConfig';
import type {
    BlogPostBody,
    BlogContentWithFootNotesBlock,
    BlogImageSectionBlock,
    BlogFontFamilyGroup,
} from '../union-types/blogPost';
import { navigate } from 'gatsby';
import { create404NotFoundUrl } from '../utils/urlHelper';
import isWhitelisted from '../utils/isWhitelisted';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import isBrowser from '../utils/isBrowser';
import usePreviewOrPageQueryBlogPost from '../hooks/usePreviewOrPageQueryBlogPost';
import useNavColorFromIntersection from '../hooks/useNavColorFromIntersection';

// Body blocks that represent textual contents
const TYPENAMES_BODY_TEXT = [
    'BlogContentWithFootnotes',
    'QuoteBlock',
    'CMS_SSR_QuoteBlock',
    'QuestionAndAnswerBlock',
    'CMS_SSR_QuestionAndAnswerBlock',
];

// Body blocks that represent images
const TYPENAMES_IMAGE_ROW = ['CMS_SSR_ImageSectionBlock', 'ImageSectionBlock'];

export interface BlogContentWithFootnotesProps {
    __typename: 'BlogContentWithFootnotes';
    footnotesList: string[];
    numberOfPreviousFootnotes: number;
    block: BlogContentWithFootNotesBlock;
}

const Body = styled.div`
    @media screen {
        ${invertColorVariables};
    }
    color: var(--foregroundColor);
    background-color: var(--backgroundColor);
`;

function BlogPost(): React.ReactElement | null {
    const config = useConfig();
    const blogPost = usePreviewOrPageQueryBlogPost();
    const isUserTestingSite = useIsUserTestingSite();
    const [createNavColorRef] = useNavColorFromIntersection();

    const [bodyWithFootnotes, footerFootnotes] = React.useMemo(() => {
        let numberOfPreviousFootnotes = 0;
        const footerFootnotes: string[] = [];

        const body = blogPost.body
            ? (blogPost.body as BlogPostBody[])
            : undefined;

        const bodyWithFootnotes = body?.filter(notNull).map((bodyItem) => {
            // We don't want to process when it's not a `ContentWithFootNotesBlock`
            if (
                bodyItem.__typename !== 'ContentWithFootNotesBlock' &&
                bodyItem.__typename !== 'CMS_SSR_ContentWithFootNotesBlock'
            ) {
                return bodyItem;
            }

            // Insert proper footnotes
            const footnoteBody = insertFootnotesInBody(
                processWagtailRichText(
                    bodyItem.textContent,
                    config.customOpentypeHtmlTagNames,
                ),
                numberOfPreviousFootnotes,
            );

            // Extract list of footnotes
            const footnotesList = bodyItem.footNotes
                ? splitFootnotes(
                      processWagtailRichText(
                          bodyItem.footNotes,
                          config.customOpentypeHtmlTagNames,
                      ),
                      numberOfPreviousFootnotes,
                  )
                : [];
            footerFootnotes.push(...footnotesList);

            // Add footnote data to this item
            const newBodyItem: BlogContentWithFootnotesProps = {
                __typename: 'BlogContentWithFootnotes',
                block: {
                    ...bodyItem,
                    textContent: footnoteBody.html,
                },
                footnotesList,
                numberOfPreviousFootnotes,
            };

            numberOfPreviousFootnotes = footnoteBody.footnoteCount;

            return newBodyItem;
        });

        return [bodyWithFootnotes, footerFootnotes];
    }, [blogPost.body, config.customOpentypeHtmlTagNames]);

    // Limit the blog posts for the user testing site,
    // so that we're not exposing any pre-release fonts.
    if (isBrowser() && isUserTestingSite) {
        const cantBeShown: boolean | undefined =
            blogPost.fontFamilyGroups?.some(
                (fontFamilyGroup: BlogFontFamilyGroup) => {
                    return !isWhitelisted(
                        config.userTestingFontFamilyGroupWhitelist,
                        fontFamilyGroup.id,
                    );
                },
            );
        if (cantBeShown) {
            navigate(create404NotFoundUrl());
            return null;
        }
    }

    return (
        <>
            <BlogPostHeader />

            <Body
                ref={createNavColorRef({
                    background: 'var(--foregroundColor)',
                    foreground: 'var(--backgroundColor)',
                })}
            >
                <BlogSpacer />

                {bodyWithFootnotes &&
                    bodyWithFootnotes.map(
                        (bodyItem, index, arr): React.ReactElement | null => {
                            let item = null;
                            const previousBodyItem = arr[index - 1];
                            const nextBodyItem = arr[index + 1];

                            const previousTypename =
                                previousBodyItem && previousBodyItem.__typename;
                            const nextTypename =
                                nextBodyItem && nextBodyItem.__typename;

                            const nextBlockIsSameType =
                                nextTypename === bodyItem.__typename;

                            // Whether the previous bodyItem is a BlogImageSectionBlock...
                            const precedingImageSectionBlock:
                                | BlogImageSectionBlock
                                | undefined = TYPENAMES_IMAGE_ROW.includes(
                                previousTypename,
                            )
                                ? (previousBodyItem as BlogImageSectionBlock)
                                : undefined;

                            // Whether the next bodyItem is a BlogImageSectionBlock...
                            const succeedingImageSectionBlock:
                                | BlogImageSectionBlock
                                | undefined = TYPENAMES_IMAGE_ROW.includes(
                                nextTypename,
                            )
                                ? (nextBodyItem as BlogImageSectionBlock)
                                : undefined;

                            switch (bodyItem.__typename) {
                                case 'VideoBlock':
                                case 'CMS_SSR_VideoBlock':
                                    item = (
                                        <React.Fragment
                                            key={`blog-item-${index}`}
                                        >
                                            <BlogBodyVideo {...bodyItem} />
                                            {nextTypename ? (
                                                <BlogSpacerLineHeight $double />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                    break;
                                case 'BlogContentWithFootnotes':
                                    item = (
                                        <React.Fragment
                                            key={`blog-item-${index}`}
                                        >
                                            <BlogBodyTextWithFootNotes
                                                {...bodyItem}
                                            />
                                            {nextTypename ? (
                                                <BlogSpacerLineHeight
                                                    $double={
                                                        !TYPENAMES_BODY_TEXT.includes(
                                                            nextTypename,
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                    break;
                                case 'HeadingBlock':
                                case 'CMS_SSR_HeadingBlock':
                                    item = (
                                        <React.Fragment
                                            key={`blog-item-${index}`}
                                        >
                                            <BlogBodyHeading {...bodyItem} />
                                            {nextTypename ? (
                                                <BlogSpacerLineHeight $double />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                    break;
                                case 'QuoteBlock':
                                case 'CMS_SSR_QuoteBlock':
                                    item = (
                                        <React.Fragment
                                            key={`blog-item-${index}`}
                                        >
                                            <BlogBodyQuote block={bodyItem} />
                                            {nextTypename ? (
                                                <BlogSpacerLineHeight
                                                    $double={
                                                        !TYPENAMES_BODY_TEXT.includes(
                                                            nextTypename,
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                    break;
                                case 'ImageSectionBlock':
                                case 'CMS_SSR_ImageSectionBlock':
                                    item = (
                                        <React.Fragment
                                            key={`blog-item-${index}`}
                                        >
                                            <StreamFieldImageSection
                                                block={bodyItem}
                                                precedingImageSection={
                                                    precedingImageSectionBlock
                                                }
                                                succeedingImageSection={
                                                    succeedingImageSectionBlock
                                                }
                                                lazyLoad={index > 0}
                                            />
                                            {nextTypename &&
                                            !nextBlockIsSameType ? (
                                                <BlogSpacerLineHeight $double />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                    break;
                                case 'QuestionAndAnswerBlock':
                                case 'CMS_SSR_QuestionAndAnswerBlock':
                                    item = (
                                        <React.Fragment
                                            key={`blog-item-${index}`}
                                        >
                                            <BlogBodyQa {...bodyItem} />
                                            {nextTypename ? (
                                                <BlogSpacerLineHeight
                                                    $double={
                                                        !TYPENAMES_BODY_TEXT.includes(
                                                            nextTypename,
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                    break;
                                default:
                                    if (
                                        !bodyItem ||
                                        Object.keys(bodyItem).length === 0
                                    ) {
                                        item = null;
                                    } else {
                                        throw Error(
                                            `Unknown blog body type with keys "${Object.keys(
                                                bodyItem,
                                            )}". Full data: ${JSON.stringify(
                                                bodyItem,
                                            )}`,
                                        );
                                    }
                            }

                            return item;
                        },
                    )}
                <BlogSpacer />
                {blogPost.fontFamilyGroups?.length ? (
                    <FeaturedFonts
                        fontFamilyGroups={blogPost.fontFamilyGroups}
                        isInverted
                    />
                ) : null}
            </Body>

            {footerFootnotes && footerFootnotes.length > 0 && (
                <BodyFootnoteFooter footnotesList={footerFootnotes} />
            )}

            <BlogRelatedPosts />
        </>
    );
}

export default BlogPost;
