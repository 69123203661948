import React from 'react';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import FontFamilyGroupsList from './FontFamilyGroupsList';
import { MARGIN_SMALL, VIEWPORT } from '../settings/Global';
import type { BlogFontFamilyGroup } from '../union-types/blogPost';
import { invertColorVariables } from '../utils/stylesMixins';
import { HeadingMedium } from './Heading';
import { useStaticFontMetricOffsets } from './PageContext';

export const Container = styled.div<{
    isInverted?: boolean;
    slimline?: boolean;
}>`
    @media screen {
        ${({ isInverted }): FlattenSimpleInterpolation | null => {
            return isInverted ? invertColorVariables : null;
        }};
    }

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-template-areas:
        '. . a a a a a a a a a a'
        'b b b b b b b b b b b b';

    position: relative;

    background: var(--backgroundColor);

    margin: 0;

    ${({ slimline }): FlattenSimpleInterpolation =>
        slimline
            ? css`
                  margin: ${MARGIN_SMALL}px var(--gridMarginGap) 0
                      var(--gridMarginGap); ;
              `
            : css`
                  grid-row-gap: var(--spacing5);
                  padding: var(--spacing7) var(--gridMarginGap) var(--spacing8)
                      var(--gridMarginGap);
              `}

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas:
            'a a a a a a'
            'b b b b b b';
    }
`;

const Heading = styled(HeadingMedium)`
    grid-area: a;
    @media print {
        --foregroundColor: var(--primaryColorRef);
    }
    padding: 0;
`;

const Wrap = styled.div`
    grid-area: b;
`;

function FeaturedFonts({
    fontFamilyGroups,
    fontFamilyIdFilter,
    isInverted,
    slimline,
}: {
    fontFamilyGroups: BlogFontFamilyGroup[];
    fontFamilyIdFilter?: string[];
    isInverted?: boolean;
    slimline?: boolean;
}): React.ReactElement | null {
    const staticFontMetrics = useStaticFontMetricOffsets();
    return (
        <Container isInverted={isInverted} slimline={slimline}>
            {!slimline ? (
                <Heading $metricOffsets={staticFontMetrics} as="h2">
                    Featured fonts
                </Heading>
            ) : null}
            <Wrap>
                <FontFamilyGroupsList
                    groups={fontFamilyGroups}
                    fontFamilyIdFilter={fontFamilyIdFilter}
                    invertColors={isInverted}
                />
            </Wrap>
        </Container>
    );
}

export default FeaturedFonts;
