import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
    LicenceType,
    TieredLicence,
    Tier,
    useActiveTieredLicensingsForLicenceType,
} from '../hooks/useActiveLicenceTypes';
import { Select, SelectItem } from './Select';
import notUnlimited from '../utils/notUnlimited';
import useCartQuery from '../hooks/useCartQuery';

const Container = styled.div<{
    $invertSelectColor?: boolean;
}>`
    font-feature-settings: 'tnum';
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-end;
    text-align: right;

    --lozengeColor: var(--foregroundColor);

    ${({ $invertSelectColor }): FlattenSimpleInterpolation | null =>
        $invertSelectColor
            ? css`
                  --lozengeBackgroundColor: var(--backgroundColor);
                  --lozengeHoverColor: var(--backgroundColor);
                  --lozengeBackgroundHoverColor: var(--foregroundColor);
              `
            : null};
`;

const TierTypeSelectWrapper = styled.div`
    margin-left: 0.25em;
`;

export default function LicenceQuantitySelector({
    licenceType,
    onChange,
    invertSelectColor,
    invertContentColors,
}: {
    licenceType: LicenceType;
    onChange: (tier: Tier) => void;
    invertSelectColor?: boolean;
    invertContentColors?: boolean;
}): React.ReactElement {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const tieredLicences = useActiveTieredLicensingsForLicenceType(licenceType);
    const [selectedTieredLicence, setSelectedTieredLicence] =
        React.useState<TieredLicence>(tieredLicences[0]);

    const selectedTier =
        cart?.licenceTiers.find(
            (cartTier) => cartTier.tier.licenceType.id === licenceType.id,
        )?.tier || selectedTieredLicence.tiers[0];

    React.useEffect(() => {
        if (selectedTieredLicence.id !== selectedTier.tieredLicence.id) {
            const newState = tieredLicences.find(
                (tieredLicence) =>
                    tieredLicence.id === selectedTier.tieredLicence.id,
            );
            if (newState) {
                setSelectedTieredLicence(newState);
            }
        }
    }, [selectedTier, selectedTieredLicence, tieredLicences]);

    return (
        <Container $invertSelectColor={invertSelectColor}>
            <Select
                value={selectedTier.id}
                onValueChange={(value): void => {
                    const newTier = selectedTieredLicence.tiers.find(
                        (tier) => tier.id === value,
                    );
                    if (!newTier) {
                        throw Error(`Invalid tier for ${value}`);
                    }
                    onChange(newTier);
                }}
                invertContentColors={invertContentColors}
            >
                {selectedTieredLicence.tiers
                    // Exclude Unlimited tiers, they can only be administered via the CMS.
                    .filter(notUnlimited)
                    .map(
                        (tier): React.ReactElement => (
                            <SelectItem key={tier.id} value={tier.id}>
                                {tieredLicences.length > 1
                                    ? tier.amount
                                    : tier.title}
                            </SelectItem>
                        ),
                    )}
            </Select>
            {
                // If there are multiple tier types (e.g. like "Audience size" and "Budget" for Broadcast)
                // then we will present two select lists: 1 for tier type, 1 for tier. The tiers will update
                // when the tier type changes.
                tieredLicences.length > 1 && (
                    <TierTypeSelectWrapper>
                        <Select
                            value={selectedTieredLicence.id}
                            invertContentColors={invertContentColors}
                            onValueChange={(value): void => {
                                const newTieredLicence = tieredLicences.find(
                                    (tieredLicence) =>
                                        tieredLicence.id === value,
                                );
                                if (
                                    newTieredLicence &&
                                    newTieredLicence.id !==
                                        selectedTieredLicence.id
                                ) {
                                    setSelectedTieredLicence(newTieredLicence);
                                    // Select corresponding tier with same multiplier or same amount, if it exists (otherwise just the first tier)
                                    const newTier =
                                        newTieredLicence.tiers.find(
                                            (tier) =>
                                                tier.multiplier ===
                                                selectedTier.multiplier,
                                        ) ||
                                        newTieredLicence.tiers.find(
                                            (tier) =>
                                                tier.amount ===
                                                selectedTier.amount,
                                        ) ||
                                        newTieredLicence.tiers[0];
                                    onChange(newTier);
                                }
                            }}
                        >
                            {tieredLicences.map((tieredLicence) => {
                                return (
                                    <SelectItem
                                        key={tieredLicence.id}
                                        value={tieredLicence.id}
                                    >
                                        {tieredLicence.unitStrPlural}
                                    </SelectItem>
                                );
                            })}
                        </Select>
                    </TierTypeSelectWrapper>
                )
            }
        </Container>
    );
}
