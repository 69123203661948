import createTitle, { createTitleSet } from '../utils/createTitle';
import type {
    BlogPost,
    FontFamilyGroup,
    CustomFont,
    InUsePost,
    FontFamily,
} from '../components/PageContext';
import { createImgixUrl } from '../utils/urlHelper';
import printAmpersandSeries from '../utils/printAmpersandSeries';
import notNull from '../utils/notNull';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import notPreRelease from '../utils/notPreRelease';

// https://ogp.me/#types
enum OgType {
    WEBSITE = 'website',
    ARTICLE = 'article',
}

// https://ogp.me/#type_profile
interface OgProfile {
    firstName: string;
    lastName: string;
}

// https://ogp.me/#type_article
interface OgArticle {
    publishedTime: string | undefined;
    modifiedTime: string | undefined;
    expirationTime: string | undefined;
    author: OgProfile;
    tags: string[];
}

export interface Metadata {
    title: string;
    ogTitle: string;
    description?: string;
    image?: string;
    ogType: OgType;
    ogArticle?: OgArticle;
}

export const defaultTitle = createTitle();

// NOTE: This image is manually-uploaded to S3 from the src/assets/images folder
export const defaultImage = createImgixUrl({
    src: 'manual/klim-share.png',
    imgixParams: {
        auto: 'format,compress',
        cs: 'srgb',
        q: 90,
    },
});

const defaults = {
    ogType: OgType.WEBSITE,
};

function createSocialImageUrl(path: string): string | undefined {
    if (!path) {
        return;
    }
    return `${createImgixUrl({
        src: path,
        imgixParams: {
            fit: 'crop',
            crop: 'edges',
            ar: '1.91:1',
            auto: 'format,compress',
            cs: 'srgb',
            q: 90,
        },
    })}`;
}

export function createDefaultMetadata(pageName?: string): Metadata {
    return {
        ...defaults,
        ...createTitleSet(pageName),
    };
}

export const homepageMetadata: Metadata = {
    ...defaults,
    ...createTitleSet(),
    description:
        'Klim Type Foundry is an independent typeface design studio led by Kris Sowersby ' +
        'and is based in Wellington, New Zealand. We sell a wide range of fonts online and ' +
        'undertake custom typographic work for international clients.',
};

export function createBuyPageMetadata(
    fontFamilyGroup: FontFamilyGroup,
): Metadata {
    const itemForSeoImage =
        fontFamilyGroup.isCollection || fontFamilyGroup.seoImage
            ? fontFamilyGroup
            : [...fontFamilyGroup.fontFamilies]
                  .filter(notPreRelease)
                  .sort(sortFontsByTypographicRanking)
                  .shift();

    return {
        ...defaults,
        ...createTitleSet(`Buy ${fontFamilyGroup.name}`),
        description: fontFamilyGroup.seoDescription,
        image: itemForSeoImage?.seoImage
            ? createSocialImageUrl(itemForSeoImage.seoImage)
            : undefined,
    };
}

export function createInUseIndexMetadata({
    inUsePostCategory,
    fontFamilyName,
    fontFamilyGroupName,
}: {
    inUsePostCategory?: string;
    fontFamilyName?: string;
    fontFamilyGroupName?: string;
}): Metadata {
    const descriptor = fontFamilyName
        ? fontFamilyName
        : fontFamilyGroupName
        ? fontFamilyGroupName
        : `Klim fonts`;
    return {
        ...defaults,
        ...createTitleSet([
            'Fonts in use',
            inUsePostCategory,
            fontFamilyName,
            fontFamilyGroupName,
        ]),
        description: `See ${descriptor} in use by the world’s best designers.`,
    };
}

export function createBlogIndexMetadata({
    blogCategoryName,
}: {
    blogCategoryName?: string;
}): Metadata {
    return {
        ...defaults,
        ...createTitleSet(['Blog', blogCategoryName]),
        description:
            'Learn about Klim’s font design process, watch lectures and read interviews with Kris Sowersby.',
    };
}

export function createBlogPostPageMetadata(blogPost: BlogPost): Metadata {
    // Split author name
    const authorNameParts = blogPost?.author?.split(' ') || [];
    const lastName = authorNameParts.pop() || '';
    const firstName = authorNameParts.join(' ');

    const tags = blogPost.categories
        .map((category): string | null => category.name)
        .filter(notNull);

    return {
        ...defaults,
        ...createTitleSet(blogPost.seoTitle.trim() || blogPost.title),
        description: blogPost.searchDescription || blogPost.blurb || undefined,
        image: blogPost.feedImage
            ? createSocialImageUrl(blogPost.feedImage.file)
            : blogPost.blurbImage
            ? createSocialImageUrl(blogPost.blurbImage.file)
            : undefined,
        ogType: OgType.ARTICLE,
        ogArticle: {
            publishedTime: blogPost.postDate
                ? blogPost.postDate.toString()
                : undefined,
            modifiedTime: blogPost.lastPublishedAt
                ? blogPost.lastPublishedAt.toString()
                : undefined,
            expirationTime: blogPost.expireAt
                ? blogPost.expireAt.toString()
                : undefined,
            author: {
                firstName: firstName,
                lastName: lastName,
            },
            tags: tags,
        },
    };
}

export function createCustomFontsIndexMetadata(): Metadata {
    return {
        ...defaults,
        ...createTitleSet('Custom fonts'),
        description:
            'See Klim’s award-winning custom fonts for international clients like PayPal, The Financial Times and National Geographic.',
    };
}

export function createCustomFontsPostPageMetadata(
    customFontsPostPage: CustomFont,
): Metadata {
    return {
        ...defaults,
        ...createTitleSet(
            customFontsPostPage.seoTitle.trim() ||
                `${customFontsPostPage.name} custom fonts`,
        ),
        description: customFontsPostPage.seoDescription || undefined,
        image: customFontsPostPage.seoImage
            ? createSocialImageUrl(customFontsPostPage.seoImage)
            : undefined,
    };
}

export function createInUsePostPageMetadata(
    inUsePostPage: InUsePost,
): Metadata {
    const fontFamilies =
        inUsePostPage.fontFamilies &&
        printAmpersandSeries(
            inUsePostPage.fontFamilies
                .filter(notNull)
                .sort(sortFontsByTypographicRanking)
                .map(({ name }): string => name)
                .sort(),
        ).join('');
    const designers =
        inUsePostPage.designerLinks &&
        printAmpersandSeries(
            inUsePostPage.designerLinks
                .map((link): string | null =>
                    link && link.__typename === 'CMS_SSR_DesignerLink'
                        ? link.name
                        : null,
                )
                .sort(),
        ).join('');
    return {
        ...defaults,
        ...createTitleSet(['Fonts in use', inUsePostPage.title]),
        description: `${fontFamilies} in use for ${inUsePostPage.title} by ${designers}`,
        image: inUsePostPage.feedImage
            ? createSocialImageUrl(inUsePostPage.feedImage.file)
            : createSocialImageUrl(inUsePostPage.blurbImage.file),
    };
}

export function createTestFontsMetadata(): Metadata {
    return {
        ...defaults,
        ...createTitleSet('Test fonts'),
        description: 'Free download of Klim’s award-winning font catalogue.',
    };
}

export function createRetailFontsIndexMetadata(): Metadata {
    return {
        ...defaults,
        ...createTitleSet('Retail fonts'),
        description: 'See, test and buy Klim’s award-winning font catalogue.',
    };
}

export function createFontFamilyMetadata(fontFamily: FontFamily): Metadata {
    return {
        ...defaults,
        ...createTitleSet(
            fontFamily.seoTitle.trim() || `${fontFamily.name} fonts`,
        ),
        description: fontFamily.seoDescription || undefined,
        image: fontFamily.seoImage
            ? createSocialImageUrl(fontFamily.seoImage)
            : undefined,
    };
}

export function createFontCollectionMetadata(
    fontFamilyGroup: FontFamilyGroup,
): Metadata {
    return {
        ...defaults,
        ...createTitleSet(
            fontFamilyGroup.seoTitle.trim() ||
                `${fontFamilyGroup.name} Collection`,
        ),
        description: fontFamilyGroup.seoDescription || undefined,
        image: fontFamilyGroup.seoImage
            ? createSocialImageUrl(fontFamilyGroup.seoImage)
            : undefined,
    };
}

export function createContactMetadata(): Metadata {
    return {
        ...defaults,
        ...createTitleSet('Contact'),
        description: 'Klim’s contact and support details.',
    };
}

export function createFaqMetadata(): Metadata {
    return {
        ...defaults,
        ...createTitleSet('FAQs'),
        description: 'Klim’s frequently asked questions.',
    };
}

export function createFontLicencesMetadata(): Metadata {
    return {
        ...defaults,
        ...createTitleSet('Font licences'),
        description: 'Read Klim’s Desktop, Web and App font licences.',
    };
}
