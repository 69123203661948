import React from 'react';
import styled from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import Icon, { IconType } from './Icon';
import hideVisually from 'polished/lib/mixins/hideVisually';
import { inputStyles, placeholderStyles } from '../utils/formStyles';

const Container = styled.div`
    position: relative;
    --iconWidth: 1em;
    --iconPadding: var(--spacing3);
`;

const Select = styled.select<{ isError?: boolean }>`
    ${inputStyles.base};
    padding-right: calc(var(--iconPadding) + var(--iconWidth));

    ${({ isError }): FlattenSimpleInterpolation | null =>
        isError ? inputStyles.errorMod : null};

    /*
     * required+invalid is a styling hackj used to generate placeholder-like
     * styling.
     */
    &:invalid {
        ${placeholderStyles.base};
    }
    &:focus:invalid {
        ${placeholderStyles.focusMod}
    }
`;

const HiddenLabel = styled.span`
    ${hideVisually};
`;

const IconWrapper = styled.span`
    position: absolute;
    top: 0;
    right: var(--iconPadding);
    width: var(--iconWidth);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--foregroundColor);
    pointer-events: none;
`;

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: React.ReactNode;
    isError?: boolean;
    hideIcon?: boolean;
}

function FieldSelect(
    { label, children, isError, ...rest }: Props,
    ref: React.Ref<HTMLSelectElement>,
): React.ReactElement {
    return (
        <Container>
            <HiddenLabel>{label}</HiddenLabel>
            <Select ref={ref} {...rest} required isError={isError}>
                <option value="" hidden>
                    {label}
                </option>
                {children}
            </Select>
            <IconWrapper>
                <Icon type={IconType.CHEVRON_DOWN} />
            </IconWrapper>
        </Container>
    );
}

export default React.forwardRef(FieldSelect);
