import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { VIEWPORT, ZINDEX } from '../settings/Global';
import { invertColorVariables } from '../utils/stylesMixins';
import { InlineButton } from './Button';
import isBrowser from '../utils/isBrowser';
import { useGlobalState } from './GlobalRuntimeState';
import useOnClickAway from '../hooks/useOnClickAway';
import { TEST_ID } from '../settings/E2e';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const GridContainer = styled.div<{ isUberAlles?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    z-index: ${({ isUberAlles }): number =>
        isUberAlles ? ZINDEX.MODAL_UBER_ALLES : ZINDEX.MODAL};
    padding: 0 var(--gridMarginGap);
`;

const ModalContainer = styled.div<{ isUberAlles?: boolean }>`
    position: relative;
    pointer-events: none;

    ${({ isUberAlles }): FlattenSimpleInterpolation =>
        isUberAlles
            ? css`
                  grid-column: 4 / span 5;

                  @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
                      grid-column: 4 / span 6;
                  }

                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      grid-column: 1 / span 6;
                  }
              `
            : css`
                  grid-column: 3 / span 5;

                  @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
                      grid-column: 2 / span 6;
                  }

                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      grid-column: 1 / span 6;
                  }
              `};

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ModalBox = styled.div`
    ${invertColorVariables};
    color: var(--foregroundColor);
    background-color: var(--backgroundColor);
    padding: var(--spacing5);
    text-align: center;
    border-radius: ${LOZENGE_BORDER_RADIUS}px;

    pointer-events: auto;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding: var(--spacing3);
    }
`;

const Buttons = styled.div`
    text-align: center;
`;

const Button = styled(InlineButton)<{ minWidth: number | undefined }>`
    width: auto;
    margin: 0 var(--spacing2);
    min-width: ${({ minWidth }): string =>
        minWidth ? `${minWidth}px` : 'auto'};
    padding: var(--spacing1) var(--spacing4);

    margin-top: var(--spacing4);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-top: var(--spacing3);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-top: var(--spacing2);
    }
`;

export default function Modal(): React.ReactElement | null {
    const [modalState, setModalState] = useGlobalState('modal');
    const [siteOverlayState, setSiteOverlayState] =
        useGlobalState('siteOverlay');
    const primaryButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const closeButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const [maxButtonWidth, setMaxButtonWidth] = React.useState<number>();

    const closeModal = (): void => {
        setModalState({});
        setSiteOverlayState({});
    };

    React.useEffect(() => {
        if (
            modalState.isVisible &&
            (!siteOverlayState.isVisible || !siteOverlayState.isForcedVisible)
        ) {
            setSiteOverlayState({
                isVisible: true,
                isForcedVisible: true,
                isUberAlles: modalState.isUberAlles,
            });
            // Focus the primary if available, otherwise focus Close
            if (primaryButtonRef.current) {
                primaryButtonRef.current?.focus();
            } else {
                closeButtonRef.current?.focus();
            }
        }
    }, [
        modalState.isVisible,
        setSiteOverlayState,
        siteOverlayState.isForcedVisible,
        siteOverlayState.isVisible,
    ]);

    React.useEffect(() => {
        if (
            !maxButtonWidth &&
            closeButtonRef.current &&
            primaryButtonRef.current
        ) {
            setMaxButtonWidth(
                Math.max(
                    closeButtonRef.current.offsetWidth,
                    primaryButtonRef.current.offsetWidth,
                ),
            );
        }
    }, [closeButtonRef.current, primaryButtonRef.current]);

    React.useEffect((): (() => void) | void => {
        if (isBrowser()) {
            const onKeyPress = (event: KeyboardEvent): void => {
                if (
                    event.key === 'Escape' ||
                    event.key === 'Esc' ||
                    event.keyCode === 27
                ) {
                    event.preventDefault();
                    closeModal();
                }
            };
            window.addEventListener('keydown', onKeyPress);
            return (): void =>
                window.removeEventListener('keydown', onKeyPress);
        }
    }, [closeModal]);

    const hasPrimaryButton = Boolean(
        modalState.primaryButtonCallback && modalState.primaryButtonText,
    );

    const modalRef = React.useRef<HTMLDivElement | null>(null);
    useOnClickAway([modalRef], (e): void => {
        closeModal();
        // Prevent cart from closing when modal closes
        e?.stopPropagation();
    });

    return (
        <AlertDialog.Root open={modalState.isVisible}>
            {!modalState.isVisible ? null : (
                <AlertDialog.Portal>
                    <AlertDialog.Overlay />
                    <AlertDialog.Content asChild>
                        <GridContainer isUberAlles={modalState.isUberAlles}>
                            <ModalContainer
                                isUberAlles={modalState.isUberAlles}
                                ref={modalRef}
                                data-cy={TEST_ID.MODAL_CONTAINER}
                            >
                                <ModalBox role="dialog" aria-modal>
                                    {modalState.message}
                                    <Buttons>
                                        {hasPrimaryButton ? (
                                            <AlertDialog.Action asChild>
                                                <Button
                                                    onClick={
                                                        modalState.primaryButtonCallback
                                                    }
                                                    ref={primaryButtonRef}
                                                    minWidth={maxButtonWidth}
                                                >
                                                    {
                                                        modalState.primaryButtonText
                                                    }
                                                </Button>
                                            </AlertDialog.Action>
                                        ) : null}
                                        <AlertDialog.Cancel asChild>
                                            <Button
                                                onClick={closeModal}
                                                ref={closeButtonRef}
                                                minWidth={maxButtonWidth}
                                            >
                                                {modalState.closeButtonText ||
                                                    'Close'}
                                            </Button>
                                        </AlertDialog.Cancel>
                                    </Buttons>
                                </ModalBox>
                            </ModalContainer>
                        </GridContainer>
                    </AlertDialog.Content>
                </AlertDialog.Portal>
            )}
        </AlertDialog.Root>
    );
}
