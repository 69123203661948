import { v4 as uuid } from 'uuid';
import type {
    Cart,
    FontStylesByFamilyId,
    Discount,
    CartTier,
} from '../hooks/useCartQuery';
import type { Config } from '../hooks/useConfig';
import {
    DiscountType,
    FontProductInput,
    FontProductTypeChoice,
    LineItemType,
} from '../gql/api-public';
import type { FontFamilyGroup } from '../components/PageContext';

import getStyleDiscountPercentageFromFontStyleCount from './getStyleDiscountPercentageFromFontStyleCount';
import { LICENCE_TYPE_ID_BASE } from '../settings/Global';
import type { Tier } from '../hooks/useActiveLicenceTypes';
import {
    baseCartTier,
    baseLicenceTier,
    baseLicenceType,
    baseTieredLicence,
} from './cartTestHelpers';

type FontFamily = FontFamilyGroup['fontFamilies'][number];
type FontStyle = FontFamily['fontStyles'][number];
export type UnionFont = FontFamilyGroup | FontFamily | FontStyle;

export interface FontProductAddInput extends FontProductInput {
    // When adding a product we require a few values for the optimistic UI
    fontDescription: string;
    fontPrice: number;
    renderFontStyleId: string;
    fontSizeMultiplier: number;
    typographicRanking: number | null;
    fontStyleIdsByFamilyId: FontStylesByFamilyId[];
}

interface FontStyleWithPackageDiscount {
    id: string;
    packageDiscountPercentage: number;
}

interface AllFontStylesMap {
    [familyId: string]: FontStyleWithPackageDiscount[];
}

interface TotalForLicenceType {
    [licenceTypeId: string]: number;
}

/**
 * Sets package discounts on the cart according to the passed cart.items.
 *
 * Parallel logic exists in the back end, in klimcart.Cart.apply_package_discounts
 * @param cart
 * @param config
 * @param combinedLicenceMultiplier
 * @param amountSubtotal
 */
function getOptimisticDiscounts(
    cart: Cart,
    config: Config,
    combinedLicenceMultiplier: number,
    amountSubtotal: number,
): Discount[] {
    // Remove old PACKAGE discounts
    const optimisticDiscounts = cart.discounts.filter(
        (discount) => discount.type !== DiscountType.PACKAGE,
    );

    // Make a map of all font styles in the cart, grouped by their font family
    const allFontStylesMap: AllFontStylesMap = {};
    cart.items
        .filter((item) => item.font !== null)
        .forEach((item) => {
            const font = item.font;
            if (!font || !font.fontStyleIdsByFamilyId.length) {
                return;
            }
            // Check if there's a package discount percentage for this font type
            const packageDiscountPercentage =
                config.packageDiscountPercentages.find(
                    (entry) =>
                        (entry.fontProductType as string) ===
                            (font.fontProductType as string) &&
                        entry.discount > 0,
                )?.discount || 0;

            font.fontStyleIdsByFamilyId.forEach((stylesWithFamily) => {
                if (
                    typeof allFontStylesMap[stylesWithFamily.fontFamilyId] ===
                    'undefined'
                ) {
                    allFontStylesMap[stylesWithFamily.fontFamilyId] = [];
                }

                stylesWithFamily.fontStyleIds
                    .filter(
                        (fontStyleId) =>
                            !allFontStylesMap[
                                stylesWithFamily.fontFamilyId
                            ].some(
                                (styleWithPackageDiscount) =>
                                    styleWithPackageDiscount.id === fontStyleId,
                            ),
                    )
                    .forEach((fontStyleId) => {
                        allFontStylesMap[stylesWithFamily.fontFamilyId].push({
                            id: fontStyleId,
                            packageDiscountPercentage:
                                packageDiscountPercentage,
                        });
                    });
            });
        });

    // Calculate bulk style discount amount based on optimistic items,
    // and get the total price per licence type.
    const totalForLicenceType: TotalForLicenceType = {};
    let bulkStyleDiscountAmount = 0;
    Object.keys(allFontStylesMap).forEach((fontFamilyId) => {
        const styleCount = allFontStylesMap[fontFamilyId].length;

        const bulkStyleDiscountPercentage =
            getStyleDiscountPercentageFromFontStyleCount(
                styleCount,
                config.styleDiscountPercentages,
            );

        // Remember a few things for the cross licence calculation that follows
        allFontStylesMap[fontFamilyId].forEach(
            (fontStyleWithPackageDiscount) => {
                const packageDiscountPercentage =
                    fontStyleWithPackageDiscount.packageDiscountPercentage;

                let stylePrice = config.unitPriceFontStyle;

                // First the bulk style discount
                let discountAmount = 0;
                if (bulkStyleDiscountPercentage) {
                    discountAmount += stylePrice * bulkStyleDiscountPercentage;
                }
                // Then calculate the package discount as a percentage of the bulk-discounted price
                if (packageDiscountPercentage) {
                    discountAmount +=
                        Math.round(
                            (stylePrice - discountAmount) *
                                packageDiscountPercentage *
                                100,
                        ) / 100;
                }
                if (discountAmount) {
                    stylePrice -= discountAmount;
                    bulkStyleDiscountAmount +=
                        Math.round(
                            discountAmount * combinedLicenceMultiplier * 100,
                        ) / 100;
                }

                // Add the prices for all licence types, to calculate cross-licence discounts later.
                if (cart.licenceTiers.length > 1) {
                    cart.licenceTiers.forEach((cartTier) => {
                        if (
                            typeof totalForLicenceType[
                                cartTier.tier.licenceType.id
                            ] === 'undefined'
                        ) {
                            totalForLicenceType[
                                cartTier.tier.licenceType.id
                            ] = 0;
                        }
                        totalForLicenceType[cartTier.tier.licenceType.id] +=
                            stylePrice * cartTier.tier.multiplier;
                    });
                }
            },
        );
    });

    // Calculate cross licence discount amount
    let crossLicenceDiscountAmount = 0;
    if (cart.licenceTiers.length > 1) {
        const pricesToDiscount = Object.values(totalForLicenceType).sort(
            // Descending sort
            (a, b) => b - a,
        );
        const amountToBeDiscounted = pricesToDiscount.reduce(
            (previousValue, currentValue, index) =>
                previousValue + (index > 0 ? currentValue : 0),
            0,
        );
        if (amountToBeDiscounted) {
            crossLicenceDiscountAmount +=
                Math.round(
                    amountToBeDiscounted *
                        config.crossLicenceDiscountPercentage *
                        100,
                ) / 100;
        }
    }

    // Add discount
    if (bulkStyleDiscountAmount || crossLicenceDiscountAmount) {
        let packageDiscountAmount =
            bulkStyleDiscountAmount + crossLicenceDiscountAmount;
        // Ensure that the discount is a rounded percentage of the subtotal, for display purposes...
        // It doesn't look great to show e.g. `47.54% package discount`, we want to show `48% package discount`.
        if (packageDiscountAmount > 0 && amountSubtotal > 0) {
            const discountPercentage =
                Math.round((packageDiscountAmount / amountSubtotal) * 100) /
                100;
            packageDiscountAmount = amountSubtotal * discountPercentage;
        }
        optimisticDiscounts.push({
            __typename: 'DiscountInterface',
            id: uuid(),
            type: DiscountType.PACKAGE,
            amount: packageDiscountAmount,
            percentage: 0,
            description: null,
        });
    }

    return optimisticDiscounts;
}

/**
 * Applies server-side cart total/discount logic to provide optimistic response.
 */
export default function getOptimisticCart({
    cart,
    config,
    fontIdsToRemove,
    fontsToAdd,
    tierIdToRemove,
    tierToAdd,
}: {
    cart: Cart;
    config: Config;
    fontIdsToRemove?: string[];
    fontsToAdd?: UnionFont[];
    tierIdToRemove?: string;
    tierToAdd?: Tier;
}): Cart {
    // Set up the base optimistic cart and filter out the fonts/tiers we're removing
    const optimisticCart: Cart = {
        ...cart,
        hasSimpleLicensing: true,
        items: cart.items.filter(
            (item) =>
                !item.font ||
                !fontIdsToRemove?.length ||
                !fontIdsToRemove.includes(item.font.fontId),
        ),
        licenceTiers: cart.licenceTiers.filter(
            (cartTier) => tierIdToRemove !== cartTier.tier.id,
        ),
    };

    // Add tier or update tier for licence type?
    if (tierToAdd) {
        const tierPropsToAdd = {
            id: tierToAdd.id,
            title: tierToAdd.title,
            titleAbbrev: tierToAdd.titleAbbrev,
            amount: tierToAdd.amount,
            multiplier: tierToAdd.multiplier,
            tieredLicence: {
                ...baseTieredLicence,
                id: tierToAdd.tieredLicence.id,
            },
        };
        // Iterate and try to update a tier for the matching licence type
        let foundTierForLicenceType = false;
        optimisticCart.licenceTiers = optimisticCart.licenceTiers.map(
            (cartTier): CartTier => {
                // Replace any tier of the same licence type
                if (cartTier.tier.licenceType.id === tierToAdd.licenceType.id) {
                    foundTierForLicenceType = true;
                    return {
                        ...cartTier,
                        tier: {
                            ...cartTier.tier,
                            ...tierPropsToAdd,
                        },
                    };
                }
                return cartTier;
            },
        );
        // If we haven't updated a tier and need to add one...
        if (!foundTierForLicenceType) {
            optimisticCart.licenceTiers.push({
                ...baseCartTier,
                id: uuid(),
                tier: {
                    ...tierToAdd,
                    ...baseLicenceTier,
                    ...tierPropsToAdd,
                    licenceType: {
                        ...baseLicenceType,
                        id: tierToAdd.licenceType.id,
                    },
                },
            });
        }
    }

    // Add all the licence multipliers together, to calculate updated prices when licence tiers change
    const combinedLicenceMultiplier = optimisticCart.licenceTiers.reduce(
        (previousValue, currentValue) => {
            return previousValue + currentValue.tier.multiplier;
        },
        0,
    );

    // Add up the full prices of all fonts, to get subtotal
    let allItemsTotal = 0;
    let allFontItemsTotal = 0;

    // Adding fonts?
    if (fontsToAdd) {
        optimisticCart.items = optimisticCart.items.concat(
            fontsToAdd.map((fontToAdd) => {
                // Add an optimistic Item
                return {
                    __typename: 'LineItemInterface',
                    id: uuid(),
                    description: null,
                    fontDescription: fontToAdd.fontDescription,
                    price: -1, // This will be set further down
                    unitPrice: fontToAdd.unitPrice,
                    type: LineItemType.RETAIL,
                    upgradedOrderNumbers: [],
                    fontLicenceType: {
                        __typename: 'LicenceTypeInterface',
                        id: LICENCE_TYPE_ID_BASE, // This doesn't actually have any consequence
                    },
                    font: {
                        __typename: 'FontProductInterface',
                        fontId: fontToAdd.id,
                        fontProductType:
                            fontToAdd.fontProductType as string as FontProductTypeChoice,
                        renderFontStyleId: fontToAdd.renderFontStyleId,
                        fontStyleIdsByFamilyId:
                            fontToAdd.fontStyleIdsByFamilyId.map((item) => {
                                return {
                                    ...item,
                                    // Change typename...
                                    __typename: 'ProductFontIds',
                                };
                            }),
                        fontSizeMultiplier: fontToAdd.fontSizeMultiplier,
                        typographicRanking: fontToAdd.typographicRanking,
                    },
                };
            }),
        );
    }

    // Update the price of all font items to reflect any updated licensing
    optimisticCart.items = optimisticCart.items.map((item) => {
        if (!item.font) {
            allItemsTotal += item.price;
            return item;
        }
        const price = item.unitPrice * combinedLicenceMultiplier;
        allFontItemsTotal += price;
        allItemsTotal += price;
        return {
            ...item,
            price,
        };
    });

    const amountSubtotal = Math.round(allItemsTotal * 100) / 100;
    const amountSubtotalForFontsWithLicenceMultiplier =
        Math.round(allFontItemsTotal * 100) / 100;

    // Update discounts (and line item prices)
    const optimisticDiscounts = getOptimisticDiscounts(
        optimisticCart,
        config,
        combinedLicenceMultiplier,
        amountSubtotal,
    );
    const discountTotal = optimisticDiscounts.reduce(
        (previousValue, currentValue) => {
            return previousValue + (currentValue.amount || 0);
        },
        0,
    );

    // Calculate totals
    const amountSubtotalConverted =
        optimisticCart.usesCurrencyConversion &&
        optimisticCart.nativeCurrency != optimisticCart.currency
            ? Math.round(
                  (amountSubtotal - discountTotal) *
                      optimisticCart.exchangeRate *
                      100,
              ) / 100
            : amountSubtotal - discountTotal;

    const amountTax = optimisticCart.taxRate
        ? Math.round(amountSubtotalConverted * optimisticCart.taxRate * 100) /
          100
        : 0;

    const amountTotal =
        Math.round((amountSubtotalConverted + amountTax) * 100) / 100;

    return {
        ...optimisticCart,
        discounts: optimisticDiscounts,
        amountSubtotal,
        amountSubtotalForFontsWithLicenceMultiplier,
        amountSubtotalConverted,
        amountTax,
        amountTotal,
    };
}
