import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import Button from './Button';
import { HeadingExtraLarge } from './Heading';
import FieldText from './FieldText';
import { Paragraph } from './Paragraph';
import FieldInputGroup from './FieldInputGroup';
import { VIEWPORT } from '../settings/Global';
import notNull from '../utils/notNull';
import { sentryException } from '../utils/sentry';
import { requestLoginCodeMutation } from '../utils/runtimeQueries';
import type {
    RequestLoginCodeMutationMutation,
    RequestLoginCodeMutationMutationVariables,
} from '../gql/api-public';
import { useErrorOverlayState } from './GlobalRuntimeState';

const Container = styled.div``;

const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-row-gap: var(--spacing4);

    ${HeadingExtraLarge} {
        margin-top: var(--spacing1);
        grid-column: 3 / span 6;
    }

    ${Paragraph} {
        grid-column: 3 / span 6;
    }

    ${Button} {
        grid-column: 3 / span 2;
    }

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        ${HeadingExtraLarge} {
            grid-column: 3 / span 6;
        }

        ${Paragraph} {
            grid-column: 3 / span 6;
        }

        ${Button} {
            grid-column: 3 / span 3;
        }
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        ${HeadingExtraLarge} {
            margin-top: var(--spacing5);
            grid-column: 1 / span 6;
        }

        ${Paragraph} {
            grid-column: 1 / span 6;
        }

        ${Button} {
            grid-column: 1 / span 6;
        }
    }
`;

const Fields = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
        grid-column: 1 / span 6;
    }
`;

interface FormContentProps {
    heading: string;
    paragraph: React.ReactNode;
}

function FormContent({
    heading,
    paragraph,
}: FormContentProps): React.ReactElement {
    return (
        <>
            <HeadingExtraLarge>{heading}</HeadingExtraLarge>
            <Paragraph>{paragraph}</Paragraph>
        </>
    );
}

interface FormValues {
    username: string;
}

const defaultValues = {
    username: '',
};

function AccountLoginForm({
    wasInvalid,
    next,
}: {
    wasInvalid?: boolean;
    next: string;
}): React.ReactElement {
    const [, setShowErrorOverlay] = useErrorOverlayState();
    const [doRequestLoginCode] = useMutation<
        RequestLoginCodeMutationMutation,
        RequestLoginCodeMutationMutationVariables
    >(requestLoginCodeMutation);

    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    const [submittedUsername, setSubmittedUsername] =
        React.useState<string>('you');

    const { register, handleSubmit, setError, formState } = useForm<FormValues>(
        {
            defaultValues,
        },
    );

    const onSubmit = async ({ username }: FormValues): Promise<void> => {
        try {
            const result = await doRequestLoginCode({
                variables: {
                    input: {
                        username,
                        next,
                    },
                },
            });

            const errors =
                result?.data?.requestLoginCode?.errors?.filter(notNull) || [];

            // handle API errors
            if (errors.length > 0) {
                errors.forEach((error): void => {
                    setError(error.field as keyof FormValues, {
                        type: 'generic',
                        message: error.messages.pop(),
                    });
                });
            } else {
                setIsSubmitted(true);
                setSubmittedUsername(username);
                // On local development you can check the docker container console for
                // your login code!
            }
        } catch (e) {
            sentryException(e);
            setShowErrorOverlay({ isShown: true });
        }
    };

    return (
        <Container>
            {isSubmitted ? (
                <InnerWrapper>
                    <FormContent
                        heading={`An email is on its way to ${submittedUsername}`}
                        paragraph="It contains a link for instant access to your
                 account."
                    />
                </InnerWrapper>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InnerWrapper>
                        {wasInvalid ? (
                            <FormContent
                                heading="Invalid sign-in link"
                                paragraph={
                                    <>
                                        Your sign-in link has expired.
                                        <br />
                                        Please re-enter your email to request a
                                        new link.
                                    </>
                                }
                            />
                        ) : (
                            <FormContent
                                heading="Sign in to your account"
                                paragraph={`Enter your email address and we’ll
                                    send you an instant access link.`}
                            />
                        )}

                        <Fields>
                            <FieldInputGroup
                                input={
                                    <FieldText
                                        label="Email address"
                                        isError={!!formState.errors.username}
                                        {...register('username', {
                                            required: 'Enter an email address',
                                        })}
                                    />
                                }
                                error={formState.errors.username}
                            />
                        </Fields>

                        <Button
                            type="submit"
                            disabled={formState.isSubmitting || undefined}
                        >
                            {formState.isSubmitting
                                ? 'Sending…'
                                : wasInvalid
                                ? 'Request new link'
                                : 'Send link'}
                        </Button>
                    </InnerWrapper>
                </form>
            )}
        </Container>
    );
}

export default React.memo(AccountLoginForm);
