import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import * as urlHelper from '../utils/urlHelper';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import {
    BLOG_IMAGE_ASPECT_RATIO,
    LINE_HEIGHT,
    MARGIN_SMALL,
} from '../settings/Global';
import { ParagraphStyles } from './Paragraph';
import processWagtailRichText from '../utils/processWagtailRichText';
import useConfig from '../hooks/useConfig';
import type {
    BlogPostStub,
    // BlogPostStubCategory,
} from '../union-types/blogPostStub';
import { TEST_ID } from '../settings/E2e';

const Card = styled.div`
    position: relative;

    &:hover {
        --imageOpacity: var(--imageHoverOpacity);
    }
`;

const Link = styled(GatsbyLink)`
    color: var(--foregroundColor);
    display: inline;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
`;

const CardBody = styled.div`
    margin: 2px 0 -${MARGIN_SMALL - 6}px 0;
    ${ParagraphStyles};
    line-height: ${LINE_HEIGHT.BODY_6};
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    p {
        display: inline;
    }

    color: var(--foregroundColorMix6);
    ${/* sc-selector */ Card}:hover & {
        color: var(--foregroundColorMix4);
    }
`;

// const CardTags = styled.aside`
//     color: var(--foregroundColorMix6);
//     ${/* sc-selector */ Card}:hover & {
//         color: var(--foregroundColorMix4);
//     }
// `;

interface BlogCardProps {
    blogPostStub: BlogPostStub;
    className?: string;
    lazyLoad?: boolean;
}

function BlogCard({
    blogPostStub,
    className,
    lazyLoad = true,
}: BlogCardProps): React.ReactElement {
    const config = useConfig();
    const { blurb, blurbImage, title, slug } = blogPostStub;
    const blurbImageFile = blurbImage && blurbImage.file;
    // const categories = blogPostStub.categories
    //     ? (blogPostStub.categories as BlogPostStubCategory[])
    //     : undefined;

    return (
        <Card data-cy={TEST_ID.BLOG_CARD} className={className}>
            {blurbImageFile && (
                <ImageWithPlaceholder
                    src={blurbImageFile}
                    aspectRatio={
                        BLOG_IMAGE_ASPECT_RATIO.WIDTH /
                        BLOG_IMAGE_ASPECT_RATIO.HEIGHT
                    }
                    lazyLoad={lazyLoad}
                    fetchPriority={!lazyLoad ? 'high' : 'low'}
                />
            )}
            <CardBody>
                <Link
                    data-cy={TEST_ID.BLOG_CARD_LINK}
                    to={urlHelper.createBlogPostUrl(slug || '')}
                >
                    {title}
                </Link>
                {` `}
                <span
                    dangerouslySetInnerHTML={{
                        __html: blurb
                            ? processWagtailRichText(
                                  blurb,
                                  config.customOpentypeHtmlTagNames,
                              )
                            : '',
                    }}
                />
            </CardBody>
            {/*<CardTags data-cy={TEST_ID.BLOG_CARD_TAGS}>*/}
            {/*    {categories*/}
            {/*        ? categories*/}
            {/*              .map((category): string | null => category.name)*/}
            {/*              .filter(notNull)*/}
            {/*              .map(*/}
            {/*                  (category): React.ReactElement => (*/}
            {/*                      <React.Fragment key={category}>*/}
            {/*                          {category}*/}
            {/*                          <br />*/}
            {/*                      </React.Fragment>*/}
            {/*                  ),*/}
            {/*              )*/}
            {/*        : null}*/}
            {/*</CardTags>*/}
        </Card>
    );
}

export default BlogCard;
