import React from 'react';
import styled from 'styled-components';
import { HeadingExtraLarge, HeadingSmall } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { A as InlineUnderlinedLink } from '../components/InlineUnderlinedLink';
import { VIEWPORT } from '../settings/Global';
import { createStripeUrl, createSupportEmailUrl } from '../utils/urlHelper';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    display: grid;
    grid-template-columns: 100%;
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-row-gap: var(--spacing3);
    margin-bottom: var(--spacing7);
`;

const Column = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 6;
    }
`;

const Title = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-bottom: var(--spacing5);
    }
`;

const ParagraphHeading = styled(HeadingSmall)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed5);
    }
`;

const Content = styled(Paragraph)`
    line-height: var(--lineHeightBody4);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

function RefundPaymentPolicyPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Container>
            <Row>
                <Column>
                    <Title $metricOffsets={staticFontMetrics} as="h1">
                        Payments & refunds
                    </Title>
                </Column>
                <Column>
                    <ParagraphHeading
                        as="h2"
                        $metricOffsets={staticFontMetrics}
                    >
                        Payment information
                    </ParagraphHeading>
                </Column>
                <Column>
                    <Content>
                        For any credit card transactions performed on this site,
                        the name that will appear on your statement will be
                        “Klim Type Foundry”. “Klim Type Foundry” is the trading
                        name of The Letterheads Limited, a registered New
                        Zealand Business. Our Approved Place of Business is 23-B
                        Totara Road, Miramar, Wellington, New Zealand.
                    </Content>
                </Column>
                <Column>
                    <Content>
                        We accept Visa, MasterCard and American Express credit
                        cards. We do not accept PayPal or Bitcoin.
                    </Content>
                </Column>
                <Column>
                    <Content>
                        The Klim Type Foundry uses{' '}
                        <InlineUnderlinedLink href={createStripeUrl()}>
                            Stripe
                        </InlineUnderlinedLink>{' '}
                        to securely process credit card data.
                    </Content>
                </Column>
                <Column>
                    <Content>
                        For any international credit card payments on our site
                        you may be charged additional transaction fees by your
                        card issuer. Klim has no control over these fees. We can
                        prepare an invoice for international bank transfer.
                        Please contact{' '}
                        <InlineUnderlinedLink
                            href={createSupportEmailUrl(true)}
                        >
                            {createSupportEmailUrl()}
                        </InlineUnderlinedLink>{' '}
                        to discuss. For any international payments made by
                        invoice and bank transfer you may also be charged
                        international payment fees by your bank for the
                        transaction. You must cover the cost of these fees. You
                        are not permitted to deduct this fee amount from the
                        Klim invoice total.
                    </Content>
                </Column>
            </Row>
            <Row>
                <Column>
                    <ParagraphHeading
                        $metricOffsets={staticFontMetrics}
                        as="h2"
                    >
                        Refunds
                    </ParagraphHeading>
                </Column>
                <Column>
                    <Content>
                        All sales of font software are final. Sorry, but we can
                        neither refund money nor swap incorrect font licence
                        purchases. There is no way for us to verify that you no
                        longer hold copies of the font software. If the font
                        software is defective it shall be replaced by the same
                        software if accompanied by a purchase receipt and we are
                        notified within 10 days of purchase.
                    </Content>
                </Column>
            </Row>
        </Container>
    );
}

export default React.memo(RefundPaymentPolicyPage);
