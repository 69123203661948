import React from 'react';
import styled from 'styled-components';
import CartSummaryInventory from './CartSummaryInventory';
import CartSummarySubtotals from './CartSummarySubtotals';
import { HeadingMedium } from './Heading';
import { VIEWPORT } from '../settings/Global';
import CartShareSection from './CartShareSection';
import { CART_CONTAINER_ID } from '../settings/Global';
import useCartQuery from '../hooks/useCartQuery';
import useIsCartEmpty from '../hooks/useIsCartEmpty';
import CartSummaryEmpty from './CartSummaryEmpty';

const Container = styled.div`
    scroll-margin-top: var(--navbarHeightCollapsed);
    padding: var(--cartPaddingTop) var(--gridMarginGap) var(--cartPaddingBottom)
        var(--gridMarginGap);
    background-color: var(--overlayBackgroundColor);
    --lozengeBackgroundColor: var(--menuBackgroundColor);

    /* hack to break out of margins */
    margin: 0 calc(-1 * var(--gridMarginGap));

    @media not all and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const Heading = styled(HeadingMedium)`
    margin-bottom: var(--cartPaddingTop);
    @media not all and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const InventorySection = styled.div`
    margin-bottom: var(--spacing7);
`;

const SubtotalSection = styled.div`
    margin-bottom: var(--spacing2);
`;

const ButtonSection = styled.div`
    display: grid;
    grid-template-columns: 100%;
`;

export default function CartSummaryMobile({
    buttonElement,
    isBuyPage,
}: {
    buttonElement: React.ReactElement;
    isBuyPage: boolean | undefined;
}): React.ReactElement {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const cartIsEmpty = useIsCartEmpty(cart) || !cart?.amountTotal;
    return (
        <div>
            <Heading>Cart</Heading>
            <Container id={CART_CONTAINER_ID}>
                {cartIsEmpty && !isBuyPage ? (
                    <CartSummaryEmpty />
                ) : (
                    <InventorySection>
                        <CartSummaryInventory isMobile />
                    </InventorySection>
                )}

                {cartIsEmpty ? null : (
                    <>
                        <SubtotalSection>
                            <CartSummarySubtotals />
                        </SubtotalSection>
                        <CartShareSection />
                        <ButtonSection>{buttonElement}</ButtonSection>
                    </>
                )}
            </Container>
        </div>
    );
}
