import React from 'react';
import debounce from 'lodash/debounce';
import { useApolloClient, useMutation, FetchResult } from '@apollo/client';
import type {
    CartUpdateMutationMutation,
    CartUpdateMutationMutationVariables,
    FontProductInput,
} from '../gql/api-public';
import { cartUpdateMutation } from '../utils/runtimeQueries';
import type { Cart } from './useCartQuery';
import type { Config } from './useConfig';
import type { Tier } from './useActiveLicenceTypes';
import getOptimisticCart from '../utils/getOptimisticCart';
import type { UnionFont } from '../utils/getOptimisticCart';
import notUndefined from '../utils/notUndefined';

interface CartUpdateWrapperInput {
    cart: Cart;
    config: Config;
    fontIdsToRemove?: string[];
    fontsToAdd?: UnionFont[];
    tierIdToRemove?: string;
    tierToAdd?: Tier;
}

type useDebouncedCartUpdateMutationReturn = (
    input: CartUpdateWrapperInput,
) => Promise<FetchResult<CartUpdateMutationMutation> | undefined>;

// Any subsequent cart updates that happen within this timeframe will be debounced
export const CART_UPDATE_DEBOUNCE_TIME = 1500;

/**
 * API calls to update cart items/licences are debounced as they are:
 * - Reasonably heavy on the API.
 * - Can cause Postgres deadlocks when they happen concurrently for the same cart.
 *
 * Since we send the entire cart state in terms of items/licences each time,
 * only sending the last query (i.e. debouncing) is fine in this case.
 *
 * To ensure a snappy UI we will write to the Apollo Client cache on each update.
 */
function useDebouncedCartUpdateMutation(): useDebouncedCartUpdateMutationReturn {
    const [doCartUpdate] = useMutation<
        CartUpdateMutationMutation,
        CartUpdateMutationMutationVariables
    >(cartUpdateMutation);
    const client = useApolloClient();

    const debouncedCartUpdate = React.useMemo(
        () =>
            debounce(doCartUpdate, CART_UPDATE_DEBOUNCE_TIME, {
                // Send one immediately
                leading: true,
            }),
        [doCartUpdate],
    );

    return async ({
        cart,
        config,
        fontIdsToRemove,
        fontsToAdd,
        tierIdToRemove,
        tierToAdd,
    }: CartUpdateWrapperInput): Promise<
        FetchResult<CartUpdateMutationMutation> | undefined
    > => {
        const optimisticCart = getOptimisticCart({
            cart,
            config,
            fontIdsToRemove,
            fontsToAdd,
            tierIdToRemove,
            tierToAdd,
        });

        const queryVariables = {
            licenceTiers: optimisticCart.licenceTiers.map((cartTier) => {
                return {
                    tierId: cartTier.tier.id,
                    licenceTypeId: cartTier.tier.licenceType.id,
                };
            }),
            fontProducts: optimisticCart.items
                .map((item): FontProductInput | undefined => {
                    if (!item.font) {
                        return;
                    }
                    return {
                        fontId: item.font.fontId,
                        fontProductType: item.font.fontProductType,
                    };
                })
                .filter(notUndefined),
        };

        const queryData: CartUpdateMutationMutation = {
            cartUpdate: {
                __typename: 'CartUpdateMutation',
                cart: optimisticCart,
            },
        };

        // Write to cache for instant UI update.
        // An alternative to `optimisticResponse`, as the mutations are debounced.
        client.writeQuery({
            query: cartUpdateMutation,
            variables: queryVariables,
            data: queryData,
            overwrite: true,
        });

        return debouncedCartUpdate({
            variables: queryVariables,
            ignoreResults: true,
            // Don't write to cache again, we've done that already above.
            // Furthermore, writing to cache here causes a performance
            // issue, where `writeQuery` doesn't immediately update cache
            // when requests are in flight, making the UI seem sluggish.
            // This does mean that the optimistic cart response is taken
            // for truth. However, any inconsistencies with the server-side
            // cart will be corrected during checkout, so this isn't believed
            // to be crucial.
            fetchPolicy: 'no-cache',
        });
    };
}

// Because we want the debounced function to only exist once and be used by different components in
// the hierarchy we will provide it via context.
const Context = React.createContext<
    undefined | useDebouncedCartUpdateMutationReturn
>(undefined);

export function Provider({
    children,
}: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
    const doDebouncedCartUpdate = useDebouncedCartUpdateMutation();
    return (
        <Context.Provider value={doDebouncedCartUpdate}>
            {children}
        </Context.Provider>
    );
}

export function useDebouncedCartUpdateMutationContext(): useDebouncedCartUpdateMutationReturn {
    const context = React.useContext(Context);
    if (!context) {
        throw new Error(
            'useDebouncedCartUpdateMutationContext used outside of context Provider',
        );
    }
    return context;
}
