import React from 'react';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { useTransition, animated } from '@react-spring/web';
import _Button from './Button';
import { HeadingExtraLarge } from './Heading';
import { Paragraph } from './Paragraph';
import { ZINDEX, VIEWPORT } from '../settings/Global';
import { createHomeUrl, createSupportEmailUrl } from '../utils/urlHelper';
import { A } from './InlineUnderlinedLink';
import { useGlobalState } from './GlobalRuntimeState';

export enum ErrorOverlayType {
    DEFAULT,
    MAINTENANCE_MODE,
    SHARED_CART_NOT_FOUND,
    SHARED_CART_INVALID_CONTENTS,
    DOWNLOAD_QUOTE,
}

const Container = styled(animated.div)`
    z-index: ${ZINDEX.ERROR_OVERLAY};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);

    display: flex;
    align-items: center;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-auto-rows: min-content;
    grid-auto-columns: 1fr;
    width: 100%;
    padding-left: var(--gridMarginGap);
    padding-right: var(--gridMarginGap);
`;

const Content = styled.div`
    grid-column: 3 / span 8;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing4);
    margin-bottom: var(--spacing6);

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const Button = styled(_Button)`
    width: 100%;
    grid-column: 3 / span 3;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / -1;
    }
`;

function ErrorOverlay(): React.ReactElement | null {
    const [errorOverlayState] = useGlobalState('errorOverlay');
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        containerRef.current.focus();
    }, [containerRef]);

    const { title, message, buttonText } = React.useMemo(() => {
        switch (errorOverlayState.type) {
            case ErrorOverlayType.SHARED_CART_NOT_FOUND:
                return {
                    title: `Shared cart not found.`,
                    message: <>We couldn’t load this cart.</>,
                };
            case ErrorOverlayType.SHARED_CART_INVALID_CONTENTS:
                return {
                    title: `Shared cart not valid.`,
                    message: (
                        <>
                            We couldn’t load this cart, as it contained products
                            that are no longer available for purchase.
                        </>
                    ),
                };
            case ErrorOverlayType.MAINTENANCE_MODE:
                return {
                    title: (
                        <>
                            We’re working on the site.
                            <br />
                            Please try purchasing later.
                        </>
                    ),
                    message: (
                        <>
                            If you continue to experience issues contact{' '}
                            <A href={createSupportEmailUrl(true)}>
                                {createSupportEmailUrl()}
                            </A>
                            .
                        </>
                    ),
                };
            case ErrorOverlayType.DOWNLOAD_QUOTE:
                return {
                    title: <>Downloading quote…</>,
                    message: <>Your quote will download soon.</>,
                    buttonText: undefined,
                };
            default:
                return {
                    buttonText: 'Refresh page',
                };
        }
    }, [errorOverlayState.type]);

    const transitions = useTransition(errorOverlayState.isShown, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    if (!errorOverlayState.isShown) {
        return null;
    }

    const buttonClickHandler = (): void => {
        if (errorOverlayState.type === ErrorOverlayType.MAINTENANCE_MODE) {
            window.location.assign(createHomeUrl());
            return;
        }
        window.location.reload();
    };

    return transitions(
        (renderProps): React.ReactElement => (
            <AlertDialog.Root open>
                <AlertDialog.Portal>
                    <AlertDialog.Overlay />
                    <AlertDialog.Content asChild>
                        <Container style={renderProps} ref={containerRef}>
                            <Grid>
                                <Content>
                                    <AlertDialog.Title asChild>
                                        <HeadingExtraLarge>
                                            {title || (
                                                <>
                                                    Something has gone wrong.
                                                    <br />
                                                    No need to panic though.
                                                </>
                                            )}
                                        </HeadingExtraLarge>
                                    </AlertDialog.Title>
                                    <AlertDialog.Description asChild>
                                        <Paragraph>
                                            {message ||
                                                `To get back on track try reloading the page or check back soon.`}
                                        </Paragraph>
                                    </AlertDialog.Description>
                                </Content>
                                {!errorOverlayState.hideButton && (
                                    <AlertDialog.Action asChild>
                                        <Button
                                            type="button"
                                            onClick={buttonClickHandler}
                                        >
                                            {buttonText || 'Return home'}
                                        </Button>
                                    </AlertDialog.Action>
                                )}
                            </Grid>
                        </Container>
                    </AlertDialog.Content>
                </AlertDialog.Portal>
            </AlertDialog.Root>
        ),
    );
}

export default React.memo(ErrorOverlay);
