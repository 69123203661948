import React from 'react';
import styled, { css } from 'styled-components';
import {
    bodyStyles,
    hyperlinkStyle,
    BlogSpacerLineHeight,
    blogAndInUseWrapStyles,
} from './BlogPostStyles';
import { VIEWPORT } from '../settings/Global';
import processWagtailRichText from '../utils/processWagtailRichText';
import useConfig from '../hooks/useConfig';
import type { BlogQaBlock } from '../union-types/blogPost';
import { hyphensAuto } from '../utils/stylesMixins';

const QuestionContainer = styled.div`
    ${blogAndInUseWrapStyles};

    grid-template-areas: '. . a a a a a a . . . .';

    @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
        grid-template-areas: '. . a a a a a . . . . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-areas: '. a a a a a a a a a . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const AnswerContainer = styled.div`
    ${blogAndInUseWrapStyles};

    grid-template-areas: '. . . a a a a a . . . .';

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-areas: '. . a a a a a a a a .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: '. a a a a a';
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const htmlStyles = css`
    ${bodyStyles}
    a {
        ${hyperlinkStyle}
    }
`;

const Question = styled.div`
    grid-area: a;

    ${htmlStyles};

    font-family: var(--fontFamilySoehne);
    font-weight: bold;

    ${hyphensAuto};
`;

const Answer = styled.div`
    grid-area: a;

    ${htmlStyles};

    font-weight: normal;

    ${hyphensAuto};
`;

export function BlogBodyQa({
    question,
    answer,
}: BlogQaBlock): React.ReactElement {
    const config = useConfig();
    return (
        <>
            <QuestionContainer>
                <Question
                    dangerouslySetInnerHTML={{
                        __html: processWagtailRichText(
                            question,
                            config.customOpentypeHtmlTagNames,
                        ),
                    }}
                />
            </QuestionContainer>
            <BlogSpacerLineHeight />
            <AnswerContainer>
                <Answer
                    dangerouslySetInnerHTML={{
                        __html: processWagtailRichText(
                            answer,
                            config.customOpentypeHtmlTagNames,
                        ),
                    }}
                />
            </AnswerContainer>
        </>
    );
}
