import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';

export const GridContainer = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-template-rows: 1fr auto;
`;

export const GridSubContainer = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 100%;
`;

export const GridLeftColumn = styled.div`
    grid-column: 3 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 2;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

export const GridMainColumn = styled.div`
    grid-column: 5 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / -1;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;
