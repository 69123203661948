import React from 'react';
import mix from 'polished/lib/color/mix';
import styled, {
    css,
    DefaultTheme,
    FlattenSimpleInterpolation,
    useTheme,
} from 'styled-components';
import {
    LINE_HEIGHT,
    MARGIN_SMALL,
    VIEWPORT,
    ZINDEX,
} from '../settings/Global';
import { Link } from './Primitives';
import type {
    FontFamilyGroup as GenericFontFamilyGroup,
    FontFamily as GenericFontFamily,
} from '../hooks/useAllFonts';
import FontsMenuSvgLink from './FontsMenuSvgLink';
import notPreRelease from '../utils/notPreRelease';
import {
    createBuyUrl,
    createCollectionUrl,
    createFontFamilyUrl,
} from '../utils/urlHelper';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import type {
    BlogFontFamily,
    BlogFontFamilyGroup,
} from '../union-types/blogPost';
import type {
    HomepageFontFamily,
    HomepageFontFamilyGroup,
    HomepageCampaignFontFamilyGroup,
} from '../union-types/homepage';
import { TEST_ID } from '../settings/E2e';
import { preventPrintPageBreak } from '../utils/stylesMixins';
import { SimpleColorScheme } from './ColorSchemeContext';
import { getMixedColor } from './CSSVariableDefinitions';
import getColorThemeFromBaseColors from '../utils/getColorThemeFromBaseColors';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const ACTIVE_CLASSNAME = 'active';

export type FontFamilyGroup =
    | GenericFontFamilyGroup
    | BlogFontFamilyGroup
    | HomepageFontFamilyGroup
    | HomepageCampaignFontFamilyGroup;
export type FontFamily =
    | GenericFontFamily
    | BlogFontFamily
    | HomepageFontFamily;

export const Container = styled.div<{
    $colorScheme: SimpleColorScheme;
    $mixColor0_5: string;
    $backgroundColor: string;
}>`
    position: relative;
    display: grid;
    grid-row-gap: ${MARGIN_SMALL}px;
    grid-template-columns: 100%;
    margin-top: auto;
    height: 100%;
    min-height: var(--fontBlockHeight);
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        min-height: auto;
    }

    border-radius: ${LOZENGE_BORDER_RADIUS}px;

    opacity: 0.85;

    ${({
        $colorScheme,
        $mixColor0_5,
        $backgroundColor,
    }): FlattenSimpleInterpolation => css`
        --linkColor: ${$mixColor0_5};
        --linkHoverColor: ${$colorScheme.foregroundColor};
        --svgColor: ${$colorScheme.foregroundColor};
        --svgHoverColor: var(--svgColor);
        background-color: ${$backgroundColor};

        &:hover {
            opacity: 1;
            background-color: ${$colorScheme.backgroundColor};
        }
    `}
`;

export const FullLink = styled(Link)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: ${ZINDEX.ZERO};
`;

export const Inner = styled.div`
    z-index: ${ZINDEX.ONE};
    pointer-events: none;

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    align-items: end;
    padding: var(--fontBlockVerticalPadding) var(--fontBlockHorizontalPadding);
`;

const BaseColumn = styled.div`
    color: var(--linkColor);
    pointer-events: auto;

    line-height: ${LINE_HEIGHT.BODY_1};
    /*
    Adding bespoke negative margin here so that the font sits right on the baseline (compared to the SVG).
    This is basically removes the line height from below the text.
    */
    margin-bottom: -3px;

    a {
        color: var(--linkColor);

        &:hover,
        &:focus,
        &:active,
        &.${ACTIVE_CLASSNAME} {
            color: var(--linkHoverColor);
        }
    }
`;

export const SvgColumn = styled.div`
    ${preventPrintPageBreak};

    grid-column: 1 / span 6;
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 4;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
    }
`;

export const FamilyColumn = styled(BaseColumn)`
    grid-column: 7 / span 4;
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 5 / span 2;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: none;
    }
`;

export const BuyColumn = styled(BaseColumn)`
    text-align: right;
    grid-column: 11 / -1;
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const BuyLink = styled(Link)`
    display: block;
`;

const FamilyNameSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`;

export function useFontListItemColors({
    colorScheme,
    invertColors,
}: {
    colorScheme: SimpleColorScheme;
    invertColors?: boolean;
}): {
    expandedColorScheme: DefaultTheme;
    mixColor0_5: string;
    backgroundColor: string;
} {
    const theme = useTheme();
    const expandedColorScheme = getColorThemeFromBaseColors(colorScheme, true);
    const mixColorIsSameAsBackground =
        (invertColors ? theme.foregroundColor : theme.backgroundColor) ===
        expandedColorScheme.backgroundColor;
    const backgroundColor = mixColorIsSameAsBackground
        ? mix(
              0.92,
              expandedColorScheme.backgroundColor,
              expandedColorScheme.foregroundColor,
          )
        : expandedColorScheme.backgroundColor;
    return {
        mixColor0_5: getMixedColor(0.5, expandedColorScheme),
        backgroundColor,
        expandedColorScheme,
    };
}

function FontFamilyGroupsListItem({
    fontFamilyGroup,
    fontFamilyIdFilter,
    colorScheme,
    invertColors,
}: {
    fontFamilyGroup: FontFamilyGroup;
    colorScheme: SimpleColorScheme;
    // We can pass an array of font family IDs to limit those that will show.
    fontFamilyIdFilter?: string[];
    invertColors?: boolean;
}): React.ReactElement | null {
    const { expandedColorScheme, mixColor0_5, backgroundColor } =
        useFontListItemColors({ colorScheme, invertColors });
    const [hoveredFamilyId, setHoveredFamilyId] = React.useState<
        string | undefined
    >();
    const { fontFamilies, url, urlIsCollection } = React.useMemo(() => {
        const fontFamilies = (fontFamilyGroup.fontFamilies as FontFamily[])
            .filter(notPreRelease)
            .filter(
                (ff) =>
                    !fontFamilyIdFilter ||
                    !fontFamilyIdFilter.length ||
                    fontFamilyIdFilter.includes(ff.id),
            )
            .sort(sortFontsByTypographicRanking);
        const url = fontFamilyGroup.isCollection
            ? createCollectionUrl(fontFamilyGroup.slug)
            : createFontFamilyUrl(fontFamilies[0].slug);
        return {
            fontFamilies,
            url,
            urlIsCollection: fontFamilyGroup.isCollection,
        };
    }, [
        fontFamilyGroup.fontFamilies,
        fontFamilyGroup.isCollection,
        fontFamilyGroup.slug,
        fontFamilyIdFilter,
    ]);
    if (!fontFamilyGroup.svgNavigation) {
        return null;
    }

    const showFontFamilyNames =
        fontFamilyGroup.isCollection && fontFamilies.length > 1;

    return (
        <Container
            $colorScheme={expandedColorScheme}
            $mixColor0_5={mixColor0_5}
            $backgroundColor={backgroundColor}
        >
            <FullLink to={url} />
            <Inner>
                <SvgColumn>
                    <FontsMenuSvgLink
                        svg={fontFamilyGroup.svgNavigation}
                        title={fontFamilyGroup.name}
                        to={url}
                        testId={
                            urlIsCollection
                                ? TEST_ID.FONT_MENU_COLLECTION
                                : undefined
                        }
                    />
                </SvgColumn>
                <FamilyColumn>
                    {showFontFamilyNames
                        ? fontFamilies.map((ff) => (
                              <Link
                                  key={ff.id}
                                  to={createFontFamilyUrl(ff.slug)}
                                  className={
                                      hoveredFamilyId === ff.id
                                          ? ACTIVE_CLASSNAME
                                          : undefined
                                  }
                                  data-cy={TEST_ID.FONT_MENU_FAMILY}
                              >
                                  <FamilyNameSpan>{ff.name}</FamilyNameSpan>
                              </Link>
                          ))
                        : null}
                </FamilyColumn>
                <BuyColumn>
                    {fontFamilies.map((ff) => {
                        const buyLinkState: KlimLinkState = {
                            fromFontFamilyId: ff.id,
                        };
                        return (
                            <BuyLink
                                key={ff.id}
                                to={createBuyUrl(fontFamilyGroup.slug)}
                                state={buyLinkState}
                                onMouseEnter={
                                    showFontFamilyNames
                                        ? (): void => {
                                              setHoveredFamilyId(ff.id);
                                          }
                                        : undefined
                                }
                                onMouseLeave={
                                    showFontFamilyNames
                                        ? (): void => {
                                              setHoveredFamilyId(undefined);
                                          }
                                        : undefined
                                }
                            >
                                Buy
                            </BuyLink>
                        );
                    })}
                </BuyColumn>
            </Inner>
        </Container>
    );
}

export default React.memo(FontFamilyGroupsListItem);
