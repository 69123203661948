import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { useGlyphInspectorDispatch } from './GlyphInspectorContext';
import type { Glyph } from 'opentype.js';
import { setGlyph } from './GlyphInspector.actions';
import {
    GLYPH_INSPECTOR_BREAKPOINT,
    ZINDEX,
    MOBILE_GLYPH_BUTTON_WIDTH,
} from '../settings/Global';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const Button = styled.button<{
    active: boolean;
    borderRadius?: {
        topLeft?: boolean;
        topRight?: boolean;
        bottomLeft?: boolean;
        bottomRight?: boolean;
    };
}>`
    position: relative;
    z-index: ${({ active }): string | number =>
        active ? ZINDEX.GLYPH_INSPECTOR_HOVER : 'auto'};
    display: block;
    outline: ${({ active }): string =>
        active
            ? '1px solid var(--foregroundColor)'
            : '1px solid var(--borderColor)'};

    ${({ borderRadius }): FlattenSimpleInterpolation =>
        css`
            border-bottom-left-radius: ${borderRadius?.bottomLeft
                ? LOZENGE_BORDER_RADIUS
                : 0}px;
            border-top-left-radius: ${borderRadius?.topLeft
                ? LOZENGE_BORDER_RADIUS
                : 0}px;
            border-bottom-right-radius: ${borderRadius?.bottomRight
                ? LOZENGE_BORDER_RADIUS
                : 0}px;
            border-top-right-radius: ${borderRadius?.topRight
                ? LOZENGE_BORDER_RADIUS
                : 0}px;
        `}

    --fillColor: ${({ active }): string =>
        active ? 'var(--backgroundColor)' : 'var(--foregroundColor)'};

    background-color: ${({ active }): string =>
        active ? 'var(--foregroundColor)' : 'transparent'};

    &:hover,
    &:focus,
    &:active {
        z-index: ${ZINDEX.GLYPH_INSPECTOR_HOVER};
        background-color: ${({ active }): string =>
            active ? 'var(--foregroundColor)' : 'var(--foregroundColorMix8)'};
        outline: 1px solid var(--foregroundColor);
    }

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        width: ${MOBILE_GLYPH_BUTTON_WIDTH}px;
        height: ${MOBILE_GLYPH_BUTTON_WIDTH}px;
    }
`;

const Svg = styled.svg`
    position: absolute;
    --padding: 20%;
    top: var(--padding);
    left: var(--padding);
    width: calc(100% - (2 * var(--padding)));
    height: calc(100% - (2 * var(--padding)));
    cursor: pointer;
    fill: var(--fillColor);
    overflow: visible;
`;

const VIEWBOX_SIZE = 100;
const SVG_PRECISION = 2; // decimal places

function getXOffsetEm({
    advanceWidth,
    unitsPerEm,
}: {
    advanceWidth: number;
    unitsPerEm: number;
}): number {
    const xPaddingUnits = unitsPerEm - advanceWidth;
    const xPaddingEm = xPaddingUnits / unitsPerEm;
    return xPaddingEm / 2;
}

function getYOffsetEm({
    capHeight,
    unitsPerEm,
}: {
    capHeight: number;
    unitsPerEm: number;
}): number {
    const yPaddingUnits = unitsPerEm - capHeight;
    const yPaddingEm = yPaddingUnits / unitsPerEm;
    return yPaddingEm / 2;
}

function GlyphInspectorGlyphListItem({
    glyph,
    unitsPerEm,
    capHeight,
    active,
    borderRadius,
}: {
    glyph: Glyph;
    unitsPerEm: number;
    capHeight: number;
    active: boolean;
    borderRadius?: {
        topLeft?: boolean;
        topRight?: boolean;
        bottomLeft?: boolean;
        bottomRight?: boolean;
    };
}): React.ReactElement | null {
    const { advanceWidth } = glyph;
    const xOffsetEm = getXOffsetEm({
        advanceWidth: advanceWidth || 1,
        unitsPerEm,
    });
    const yOffsetEm = getYOffsetEm({ capHeight, unitsPerEm });

    const svgPath = glyph
        .getPath(
            xOffsetEm * VIEWBOX_SIZE,
            VIEWBOX_SIZE - yOffsetEm * VIEWBOX_SIZE,
            VIEWBOX_SIZE,
        )
        .toSVG(SVG_PRECISION);

    const glyphInspectorDispatch = useGlyphInspectorDispatch();

    const selectGlyph = React.useCallback((): void => {
        if (glyph) {
            glyphInspectorDispatch(setGlyph(glyph));
        }
    }, [glyphInspectorDispatch, glyph]);

    return glyph ? (
        <Button
            active={active}
            onClick={selectGlyph}
            data-debug-title={`${glyph.name}${
                glyph.unicode ? ` (${glyph.unicode})` : null
            }`}
            borderRadius={borderRadius}
        >
            <Svg
                dangerouslySetInnerHTML={{ __html: svgPath }}
                viewBox="0 0 100 100"
            />
        </Button>
    ) : null;
}

export default React.memo(GlyphInspectorGlyphListItem);
