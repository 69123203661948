import React from 'react';
import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { VIEWPORT } from '../settings/Global';
import useAwards from '../hooks/useAwards';
import type { Award } from '../hooks/useAwards';
import type { AwardFragment } from '../gql/api-ssr';
import { Paragraph, ParagraphStyles } from '../components/Paragraph';
import { styles as LinkStyles } from '../components/InlineUnderlinedLink';
import { HeadingExtraLarge } from '../components/Heading';
import useConfig from '../hooks/useConfig';
import processWagtailRichText from '../utils/processWagtailRichText';
import printAmpersandSeries from '../utils/printAmpersandSeries';
import sortByName from '../utils/sortByName';
import {
    createCollectionUrl,
    createCustomFontUrl,
    createFontFamilyUrl,
} from '../utils/urlHelper';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Link = styled(GatsbyLink)`
    &:hover,
    &:focus,
    &:active {
        color: var(--foregroundColorMix4);
    }
`;

const Footnote = styled(Paragraph)`
    color: var(--foregroundColorMix4);
`;

const Asterisk = styled.span`
    color: var(--foregroundColorMix4);
`;

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
        grid-row-gap: var(--spacing3);
    }
`;

const Column = styled.div`
    &:nth-child(1) {
        grid-column: 3 / -1;

        ${ParagraphStyles};

        a {
            ${LinkStyles};
        }

        b,
        strong {
            font-weight: bold;
        }

        i,
        em {
            font-style: italic;
        }
    }

    &:nth-child(2) {
        grid-column: 3 / span 5;
        padding: 0 0 var(--spacing8) 0;
    }

    &:nth-child(3) {
        grid-column: 1 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(1),
        &:nth-child(2) {
            grid-column: 1 / -1;
            padding: 0;
        }

        &:nth-child(2) {
            padding: 0 0 var(--spacing7) 0;
        }
    }
`;

const Heading = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing3);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed7);
    }
`;

const AwardRow = styled.div<{ firstOfYear?: boolean }>`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    ${({ firstOfYear }): FlattenSimpleInterpolation | null =>
        firstOfYear
            ? css`
                  border-top: 1px solid var(--foregroundColorMix4);
              `
            : null};
`;

const AwardYear = styled.div`
    font-feature-settings: 'tnum';
    grid-column: 1 / span 2;
    font-weight: bold;
    padding: var(--spacing2) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1;
        padding: var(--spacing1) 0;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: none;
    }
`;

const AwardYearMobile = styled.div`
    display: none;
    font-feature-settings: 'tnum';
    grid-column: 1 / -1;
    font-weight: bold;
    padding: var(--spacing3) 0 var(--spacing1) 0;
    &:first-child {
        padding-top: 0;
    }
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: block;
    }
`;

const AwardWrap = styled.div<{ firstOfYear?: boolean }>`
    grid-column: 3 / -1;
    padding: var(--spacing2) 0;

    display: grid;
    grid-column-gap: var(--gridColumnGap);
    grid-template-columns: repeat(
        calc(var(--gridColumnCount) - 2),
        minmax(0, 1fr)
    );
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 2 / -1;
        padding: var(--spacing1) 0;

        grid-template-columns: repeat(
            calc(var(--gridColumnCount) - 1),
            minmax(0, 1fr)
        );
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }

    ${({ firstOfYear }): FlattenSimpleInterpolation | null =>
        !firstOfYear
            ? css`
                  border-top: 1px solid var(--borderColor);
              `
            : null};
`;

const AwardName = styled.div`
    grid-column: 1 / span 5;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const AwardFor = styled.div`
    grid-column: 6 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
        color: var(--foregroundColorMix3);
    }
`;

/**
 * Sort by year (DESC), then sortOrder (ASC).
 * @param a
 * @param b
 */
export function awardsSortFn(a: AwardFragment, b: AwardFragment): number {
    return b.year - a.year || a.sortOrder - b.sortOrder;
}

type NameWithSortOrder = {
    name: string;
    sortOrder: number;
    url?: string;
};

const getReceivedForFontNames = (award: Award): React.ReactNode[] => {
    const fontFamilyGroups = award.fontFamilyGroups
        .filter(({ isCollection }): boolean => isCollection)
        .map(
            (fontFamilyGroup): NameWithSortOrder => ({
                name: `${fontFamilyGroup.name} Collection`,
                url: createCollectionUrl(fontFamilyGroup.slug),
                sortOrder: fontFamilyGroup.typographicRanking || 0,
            }),
        );
    const fontFamiliesFromGroups = award.fontFamilyGroups
        .filter(({ isCollection }): boolean => !isCollection)
        .map((fontFamilyGroup) =>
            fontFamilyGroup.fontFamilies.map(
                (fontFamily): NameWithSortOrder => ({
                    name: fontFamily.name,
                    url: !fontFamily.isPreRelease
                        ? createFontFamilyUrl(fontFamily.slug)
                        : undefined,
                    sortOrder: fontFamily.typographicRanking || 0,
                }),
            ),
        )
        .flat();
    const fontFamilies = award.fontFamilies.map(
        (fontFamily): NameWithSortOrder => ({
            name: fontFamily.name,
            url: !fontFamily.isPreRelease
                ? createFontFamilyUrl(fontFamily.slug)
                : undefined,
            sortOrder: fontFamily.typographicRanking || 0,
        }),
    );
    const customFonts = award.customFonts.map(
        (customFont): NameWithSortOrder => ({
            name: customFont.name,
            url: !customFont.isPreRelease
                ? createCustomFontUrl(customFont.slug || '')
                : undefined,
            sortOrder: 9007199254740991, // Custom fonts come last
        }),
    );
    return [
        ...fontFamilyGroups,
        ...fontFamiliesFromGroups,
        ...fontFamilies,
        ...customFonts,
    ]
        .sort((a, b) => a.sortOrder - b.sortOrder || sortByName(a, b))
        .map((item, index) =>
            item.url ? (
                <Link key={`aw-${index}`} to={item.url}>
                    {item.name}
                </Link>
            ) : (
                item.name
            ),
        );
};

function AwardsPage(): React.ReactElement {
    const config = useConfig();
    const awards = useAwards();
    const staticFontMetrics = useStaticFontMetricOffsets();

    const Awards = React.useMemo(() => {
        let previousYear: number;
        return awards.sort(awardsSortFn).map((award) => {
            const firstOfYear = previousYear !== award.year;
            previousYear = award.year;
            const receivedFor = award.receivedFor
                ? award.receivedFor
                : printAmpersandSeries(getReceivedForFontNames(award), true);
            return (
                <React.Fragment key={award.id}>
                    {firstOfYear ? (
                        <AwardYearMobile>{award.year}</AwardYearMobile>
                    ) : null}
                    <AwardRow firstOfYear={firstOfYear}>
                        <AwardYear>{firstOfYear ? award.year : null}</AwardYear>
                        <AwardWrap firstOfYear={firstOfYear}>
                            <AwardName
                                dangerouslySetInnerHTML={{
                                    __html: processWagtailRichText(
                                        award.name,
                                        config.customOpentypeHtmlTagNames,
                                    ),
                                }}
                            />
                            <AwardFor>
                                {receivedFor}
                                {award.essentialContribution ? (
                                    <Asterisk>*</Asterisk>
                                ) : null}
                            </AwardFor>
                        </AwardWrap>
                    </AwardRow>
                </React.Fragment>
            );
        });
    }, [config.customOpentypeHtmlTagNames, awards]);
    return (
        <Container>
            <Row>
                <Column>
                    <Heading $metricOffsets={staticFontMetrics} as="h1">
                        Awards and accolades
                    </Heading>
                </Column>
                <Column>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: config?.awardPageIntro || '',
                        }}
                    />
                    <p>&nbsp;</p>
                    <Footnote>
                        * Projects where our contribution was significant.
                    </Footnote>
                </Column>
                <Column>{Awards}</Column>
            </Row>
        </Container>
    );
}

export default React.memo(AwardsPage);
